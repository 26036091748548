/* THIS FILE IS GENERATED FILE. If you want to update, please run pnpm generate. */
/* eslint-disable */
export var l10n = {
    ja: {
        aboutClosingTime: '締め時間について',
        aboutDiamondReport: '過去のダイヤの獲得・交換・換金履歴が閲覧できます。',
        aboutEveryPrizeDetailIsHere: '各プライズの詳細はこちら',
        aboutMyLive: 'この枠について',
        aboutOrganizersAndPlans: '事務所とサポートプランについて',
        aboutSelfApplyLSingerJudge: '配信開始の審査申し込み',
        aboutThisSupportPlan: 'このサポートプランについて',
        account: 'アカウント',
        accountApplicationRequestList: '口座審査依頼一覧',
        accumulatedContinuousRewardDiamond: '継続報酬予約権',
        accumulatedContinuousRewardDiamondBeforeCollect: '継続報酬予約権 (合計)',
        accumulatedDiamond: '積み立てたダイヤ',
        accumulatedDiamondBeforeCollect: '積み立てダイヤ (獲得前)',
        achievedRequirement1toGetAccumulatedDiamond: '条件1: 当該旬含む直近6旬で100曲分以上ダイヤ獲得権を消費していること (クリア済)',
        achievedRequirement2toGetAccumulatedDiamond: '条件2: 今旬25曲分ダイヤ獲得権を消費していること (クリア済)',
        achievedRequirement3toGetAccumulatedDiamond: '条件3: 該当旬の間に1K推しバッジを3人以上が獲得していること (クリア済)',
        achievedRequirementNg: '❌',
        achievedRequirementOk: '🆗',
        achieveFavePointsInEarlyTerm: function (favePoint) { return "".concat(favePoint, "\u306E\u63A8\u3057Pt\u3092\u65EC\u306E\u521D\u671F\u306B\u9054\u6210"); },
        achievement: '達成',
        achievementReward: '達成報酬',
        acquiredDiamond: '獲得したダイヤ',
        acquiredEvening: '夕方分を獲得しました',
        acquiredFaveBadge: '推しバッジを獲得した',
        acquiredFavePoint: '推しPtを獲得した',
        acquiredHonorByFaveBonus: '獲得した推しボーナス称号',
        acquiredNight: '夜分を獲得しました',
        acquiredNoon: '昼分を獲得しました',
        acquiredOrganizationDiamond: '獲得した事務所ダイヤ',
        acquiredTodayAndEvening: '本日分と夕方分を獲得しました',
        acquiredTodayAndMorgning: '本日分と朝分を獲得しました',
        acquiredTodayAndNight: '本日分と夜分を獲得しました',
        acquiredTodayAndNoon: '本日分と昼分を獲得しました',
        acquireGospelCoinsForMembership: function (coin, days) { return "\u798F\u97F3\u3092".concat(coin, "\u30B3\u30A4\u30F3\u5206 x ").concat(days, "\u65E5\u5206\u7372\u5F97"); },
        acquireLiverSpecificCoinForMembership: function (coin, month) { return "\u67A0\u5185\u9650\u5B9A\u30B3\u30A4\u30F3\u3092".concat(coin, " x ").concat(month, "\u30F6\u6708\u5206\u7372\u5F97"); },
        acquireMembershipBadge: 'メンバーシップバッジの獲得',
        acquisition: '獲得',
        add: '追加',
        addAccount: 'アカウントを追加',
        addedArtists: '追加したアーティスト',
        addedGroupLsinger: 'グループに追加するLシンガー',
        addReportIDDescription: '通報処理の場合は通報IDを追加してください',
        addSongToFavorite: 'レパートリーに追加',
        addSongToFavoriteAsListener: '推し曲に追加',
        addSongToOwnFavorite: '自分のレパートリーに追加',
        addUserMemo: 'ユーザメモ・違反対応メモの追加',
        adjustmentYoutubeViewingDelay: 'ズレ調整',
        administrator: '運営',
        adminNotification: '運営のお知らせ',
        afterTermAndDayOfTerm: function (month, term, dayOfTerm) { return "".concat(month, "\u6708").concat(term, " DAY").concat(dayOfTerm, "\u4EE5\u964D"); },
        age10s: '10代',
        age20s: '20代',
        age30s: '30代',
        age40s: '40代',
        ageOver50s: '50代~',
        alarm: '警告',
        alarmAdult: function (target, span) { return "\u3010\u8B66\u544A\u3011\u3042\u306A\u305F\u306E".concat(target, "\u306B\u3064\u3044\u3066\u3001\u308F\u3044\u305B\u3064\u306A\u5185\u5BB9\u3092\u78BA\u8A8D\u3057\u307E\u3057\u305F\u306E\u3067\u3001").concat(span, "\u306E\u9593\u3001\u6A5F\u80FD\u5236\u9650\u3092\u884C\u3044\u307E\u3059\u3002\u4ECA\u5F8C\u3082\u9055\u53CD\u3092\u7E70\u308A\u8FD4\u3059\u5834\u5408\u306F\u5F37\u5236\u9000\u4F1A\u306A\u3069\u306E\u63AA\u7F6E\u3092\u884C\u3046\u5834\u5408\u304C\u3042\u308A\u307E\u3059\u3002"); },
        alarmAgeFalsification: function (target, span) { return "\u3010\u8B66\u544A\u3011\u3042\u306A\u305F\u306E".concat(target, "\u306B\u3064\u3044\u3066\u3001\u767B\u9332\u3057\u3066\u3044\u308B\u5E74\u9F62\u3068\u5B9F\u969B\u306E\u5E74\u9F62\u304C\u76F8\u9055\u3057\u3066\u3044\u308B\u53EF\u80FD\u6027\u304C\u3042\u308B\u5185\u5BB9\u3092\u78BA\u8A8D\u3057\u307E\u3057\u305F\u306E\u3067\u3001").concat(span, "\u306E\u9593\u3001\u6A5F\u80FD\u5236\u9650\u3092\u884C\u3044\u307E\u3059\u3002\u4ECA\u5F8C\u3082\u9055\u53CD\u3092\u7E70\u308A\u8FD4\u3059\u5834\u5408\u306F\u5F37\u5236\u9000\u4F1A\u306A\u3069\u306E\u63AA\u7F6E\u3092\u884C\u3046\u5834\u5408\u304C\u3042\u308A\u307E\u3059\u3002"); },
        alarmCommercialPurpose: function (target, span) { return "\u3010\u8B66\u544A\u3011\u3042\u306A\u305F\u306E".concat(target, "\u306B\u3064\u3044\u3066\u3001\u55B6\u5229\u76EE\u7684\u306E\u5185\u5BB9\u3092\u78BA\u8A8D\u3057\u307E\u3057\u305F\u306E\u3067\u3001").concat(span, "\u306E\u9593\u3001\u6A5F\u80FD\u5236\u9650\u3092\u884C\u3044\u307E\u3059\u3002\u4ECA\u5F8C\u3082\u9055\u53CD\u3092\u7E70\u308A\u8FD4\u3059\u5834\u5408\u306F\u5F37\u5236\u9000\u4F1A\u306A\u3069\u306E\u63AA\u7F6E\u3092\u884C\u3046\u5834\u5408\u304C\u3042\u308A\u307E\u3059\u3002"); },
        alarmCopyRight: function (target, span) { return "\u3010\u8B66\u544A\u3011\u3042\u306A\u305F\u306E".concat(target, "\u306B\u3064\u3044\u3066\u3001\u4ED6\u8005\u306E\u6A29\u5229\u3092\u4FB5\u5BB3\u3059\u308B\u5185\u5BB9\u3092\u78BA\u8A8D\u3057\u307E\u3057\u305F\u306E\u3067\u3001").concat(span, "\u306E\u9593\u3001\u6A5F\u80FD\u5236\u9650\u3092\u884C\u3044\u307E\u3059\u3002\u4ECA\u5F8C\u3082\u9055\u53CD\u3092\u7E70\u308A\u8FD4\u3059\u5834\u5408\u306F\u5F37\u5236\u9000\u4F1A\u306A\u3069\u306E\u63AA\u7F6E\u3092\u884C\u3046\u5834\u5408\u304C\u3042\u308A\u307E\u3059\u3002"); },
        alarmDangerousAction: function (target, span) { return "\u3010\u8B66\u544A\u3011\u3042\u306A\u305F\u306E".concat(target, "\u306B\u3064\u3044\u3066\u3001\u5371\u967A\u884C\u70BA\u3092\u78BA\u8A8D\u3057\u307E\u3057\u305F\u306E\u3067\u3001").concat(span, "\u306E\u9593\u3001\u6A5F\u80FD\u5236\u9650\u3092\u884C\u3044\u307E\u3059\u3002\u4ECA\u5F8C\u3082\u9055\u53CD\u3092\u7E70\u308A\u8FD4\u3059\u5834\u5408\u306F\u5F37\u5236\u9000\u4F1A\u306A\u3069\u306E\u63AA\u7F6E\u3092\u884C\u3046\u5834\u5408\u304C\u3042\u308A\u307E\u3059\u3002"); },
        alarmDating: function (target, span) { return "\u3010\u8B66\u544A\u3011\u3042\u306A\u305F\u306E".concat(target, "\u306B\u3064\u3044\u3066\u3001\u500B\u4EBA\u60C5\u5831\u306E\u63D0\u793A\u307E\u305F\u306F\u51FA\u4F1A\u3044\u76EE\u7684\u3067\u306E\u5229\u7528\u3092\u78BA\u8A8D\u3057\u307E\u3057\u305F\u306E\u3067\u3001").concat(span, "\u306E\u9593\u3001\u6A5F\u80FD\u5236\u9650\u3092\u884C\u3044\u307E\u3059\u3002\u4ECA\u5F8C\u3082\u9055\u53CD\u3092\u7E70\u308A\u8FD4\u3059\u5834\u5408\u306F\u5F37\u5236\u9000\u4F1A\u306A\u3069\u306E\u63AA\u7F6E\u3092\u884C\u3046\u5834\u5408\u304C\u3042\u308A\u307E\u3059\u3002"); },
        alarmDefamation: function (target, span) { return "\u3010\u8B66\u544A\u3011\u3042\u306A\u305F\u306E".concat(target, "\u306B\u3064\u3044\u3066\u3001\u4ED6\u4EBA\u3092\u8AB9\u8B17\uFF65\u4E2D\u50B7\u30FB\u4FAE\u8FB1\u3059\u308B\u5185\u5BB9\u3001\u4ED6\u4EBA\u306E\u540D\u8A89\u30FB\u4FE1\u7528\u3092\u50B7\u3064\u3051\u308B\u5185\u5BB9\u307E\u305F\u306F\u8352\u3089\u3057\u884C\u70BA\u3001\u307E\u305F\u306F\u305D\u306E\u53EF\u80FD\u6027\u304C\u3042\u308B\u3082\u306E\u3092\u78BA\u8A8D\u3057\u307E\u3057\u305F\u306E\u3067\u3001").concat(span, "\u306E\u9593\u3001\u6A5F\u80FD\u5236\u9650\u3092\u884C\u3044\u307E\u3059\u3002\u4ECA\u5F8C\u3082\u9055\u53CD\u3092\u7E70\u308A\u8FD4\u3059\u5834\u5408\u306F\u5F37\u5236\u9000\u4F1A\u306A\u3069\u306E\u63AA\u7F6E\u3092\u884C\u3046\u5834\u5408\u304C\u3042\u308A\u307E\u3059\u3002"); },
        alarmDriving: function (target, span) { return "\u3010\u8B66\u544A\u3011\u3042\u306A\u305F\u306E".concat(target, "\u306B\u3064\u3044\u3066\u3001\u8ECA\u4E21\u5185\u304B\u3089\u306E\u914D\u4FE1\u3092\u78BA\u8A8D\u3057\u307E\u3057\u305F\u306E\u3067\u3001").concat(span, "\u306E\u9593\u3001\u6A5F\u80FD\u5236\u9650\u3092\u884C\u3044\u307E\u3059\u3002\u4ECA\u5F8C\u3082\u9055\u53CD\u3092\u7E70\u308A\u8FD4\u3059\u5834\u5408\u306F\u5F37\u5236\u9000\u4F1A\u306A\u3069\u306E\u63AA\u7F6E\u3092\u884C\u3046\u5834\u5408\u304C\u3042\u308A\u307E\u3059\u3002"); },
        alarmIllegalKaraoke: function (target, span) { return "\u3010\u8B66\u544A\u3011\u3042\u306A\u305F\u306E".concat(target, "\u306B\u3064\u3044\u3066\u3001\u30AB\u30E9\u30AA\u30B1\u97F3\u6E90\u3092BGM\u3068\u3057\u3066\u4F7F\u7528\u3059\u308B\u884C\u70BA\u3092\u78BA\u8A8D\u3057\u307E\u3057\u305F\u306E\u3067\u3001").concat(span, "\u306E\u9593\u3001\u6A5F\u80FD\u5236\u9650\u3092\u884C\u3044\u307E\u3059\u3002\u4ECA\u5F8C\u3082\u9055\u53CD\u3092\u7E70\u308A\u8FD4\u3059\u5834\u5408\u306F\u5F37\u5236\u9000\u4F1A\u306A\u3069\u306E\u63AA\u7F6E\u3092\u884C\u3046\u5834\u5408\u304C\u3042\u308A\u307E\u3059\u3002"); },
        alarmLiveStreamingGuideline: function (target, span) { return "\u3010\u8B66\u544A\u3011\u3042\u306A\u305F\u306E".concat(target, "\u306B\u3064\u3044\u3066\u3001\u30E9\u30A4\u30D6\u30B9\u30C8\u30EA\u30FC\u30DF\u30F3\u30B0\u914D\u4FE1\u30AC\u30A4\u30C9\u30E9\u30A4\u30F3\u306B\u9055\u53CD\u3059\u308B\u884C\u70BA\u3092\u78BA\u8A8D\u3057\u307E\u3057\u305F\u306E\u3067\u3001").concat(span, "\u306E\u9593\u3001\u6A5F\u80FD\u5236\u9650\u3092\u884C\u3044\u307E\u3059\u3002\u4ECA\u5F8C\u3082\u9055\u53CD\u3092\u7E70\u308A\u8FD4\u3059\u5834\u5408\u306F\u5F37\u5236\u9000\u4F1A\u306A\u3069\u306E\u63AA\u7F6E\u3092\u884C\u3046\u5834\u5408\u304C\u3042\u308A\u307E\u3059\u3002"); },
        alarmMoral: function (target, span) { return "\u3010\u8B66\u544A\u3011\u3042\u306A\u305F\u306E".concat(target, "\u306B\u3064\u3044\u3066\u3001\u516C\u5E8F\u826F\u4FD7\u306B\u53CD\u3059\u308B\u5185\u5BB9\u3092\u78BA\u8A8D\u3057\u307E\u3057\u305F\u306E\u3067\u3001").concat(span, "\u306E\u9593\u3001\u6A5F\u80FD\u5236\u9650\u3092\u884C\u3044\u307E\u3059\u3002\u4ECA\u5F8C\u3082\u9055\u53CD\u3092\u7E70\u308A\u8FD4\u3059\u5834\u5408\u306F\u5F37\u5236\u9000\u4F1A\u306A\u3069\u306E\u63AA\u7F6E\u3092\u884C\u3046\u5834\u5408\u304C\u3042\u308A\u307E\u3059\u3002"); },
        alarmSameTime: function (target, span) { return "\u3010\u8B66\u544A\u3011\u3042\u306A\u305F\u306E".concat(target, "\u306B\u3064\u3044\u3066\u3001\u540C\u4E00\u5185\u5BB9\u30FB\u540C\u6642\u914D\u4FE1\u3092\u78BA\u8A8D\u3057\u307E\u3057\u305F\u306E\u3067\u3001").concat(span, "\u306E\u9593\u3001\u6A5F\u80FD\u5236\u9650\u3092\u884C\u3044\u307E\u3059\u3002\u4ECA\u5F8C\u3082\u9055\u53CD\u3092\u7E70\u308A\u8FD4\u3059\u5834\u5408\u306F\u5F37\u5236\u9000\u4F1A\u306A\u3069\u306E\u63AA\u7F6E\u3092\u884C\u3046\u5834\u5408\u304C\u3042\u308A\u307E\u3059\u3002"); },
        alarmSilence: function (target, span) { return "\u3010\u8B66\u544A\u3011\u3042\u306A\u305F\u306E".concat(target, "\u306B\u3064\u3044\u3066\u3001\u30B3\u30DF\u30E5\u30CB\u30B1\u30FC\u30B7\u30E7\u30F3\u304C\u6210\u7ACB\u3057\u3066\u3044\u306A\u3044\u3068\u5224\u65AD\u3055\u308C\u305F\u914D\u4FE1 (\u653E\u7F6E\u30FB\u5BDD\u843D\u3061\u306A\u3069) \u3092\u78BA\u8A8D\u3057\u307E\u3057\u305F\u306E\u3067\u3001").concat(span, "\u306E\u9593\u3001\u6A5F\u80FD\u5236\u9650\u3092\u884C\u3044\u307E\u3059\u3002\u4ECA\u5F8C\u3082\u9055\u53CD\u3092\u7E70\u308A\u8FD4\u3059\u5834\u5408\u306F\u5F37\u5236\u9000\u4F1A\u306A\u3069\u306E\u63AA\u7F6E\u3092\u884C\u3046\u5834\u5408\u304C\u3042\u308A\u307E\u3059\u3002"); },
        alarmTermsOfUse: function (target, span) { return "\u3010\u8B66\u544A\u3011\u3042\u306A\u305F\u306E".concat(target, "\u306B\u3064\u3044\u3066\u3001\u4F1A\u54E1\u898F\u7D04\u7B49\u306B\u9055\u53CD\u3059\u308B\u884C\u70BA\u3092\u78BA\u8A8D\u3057\u307E\u3057\u305F\u306E\u3067\u3001").concat(span, "\u306E\u9593\u3001\u6A5F\u80FD\u5236\u9650\u3092\u884C\u3044\u307E\u3059\u3002\u4ECA\u5F8C\u3082\u9055\u53CD\u3092\u7E70\u308A\u8FD4\u3059\u5834\u5408\u306F\u5F37\u5236\u9000\u4F1A\u306A\u3069\u306E\u63AA\u7F6E\u3092\u884C\u3046\u5834\u5408\u304C\u3042\u308A\u307E\u3059\u3002"); },
        alarmUnder20: function (target, span) { return "\u3010\u8B66\u544A\u3011\u3042\u306A\u305F\u306E".concat(target, "\u306B\u3064\u3044\u3066\u3001 20\u6B73\u672A\u6E80\u306E\u98F2\u9152\u304A\u3088\u3073\u55AB\u7159\u3092\u78BA\u8A8D\u3057\u307E\u3057\u305F\u306E\u3067\u3001").concat(span, "\u306E\u9593\u3001\u6A5F\u80FD\u5236\u9650\u3092\u884C\u3044\u307E\u3059\u3002\u4ECA\u5F8C\u3082\u9055\u53CD\u3092\u7E70\u308A\u8FD4\u3059\u5834\u5408\u306F\u5F37\u5236\u9000\u4F1A\u306A\u3069\u306E\u63AA\u7F6E\u3092\u884C\u3046\u5834\u5408\u304C\u3042\u308A\u307E\u3059\u3002"); },
        alertQuitOrganization: '【事務所支払い】プランのサポートプランを辞める場合は、毎月1日の12:00から23:59までの間に辞める手続きを行う必要があります。',
        alertTitleSuggestSinging: function (songName, singerName) { return "".concat(songName, " / ").concat(singerName, " \u3092\u300C\u3053\u306E\u66F2\u3069\u3046\uFF1F\u300D\u3068\u63D0\u6848\u3057\u307E\u3059\u304B\uFF1F"); },
        all: 'すべて',
        alreadyBlockedByModerator: '代理ブロック済み',
        alreadyPaymentCompleted: 'すでに支払いが完了しています',
        alreadyRequestedToAddNewSong: '追加リクエスト済みです',
        alreadyTerminateModerator: '既にモデレーターを解除済みです',
        amountOfGospelRecoveryPerDay: '福音回復量/日',
        androidImportantNotificationChannelName: '重要なお知らせ',
        announcementForOrganizer: '事務所向けのお知らせ',
        anonymousAndHidden: '匿名(非公開)',
        anonymousEntry: '匿名エントリー',
        anonymousEntryDone: '匿名エントリー済',
        anotherLSingerLatestSungDateAndTime: 'このLシンガーが最後に歌った日時',
        applicationBecomeLiver: 'Lシンガーサポートプラン加入申請',
        applicationBecomeStaff: 'スタッフ化申請',
        applicationChangePlan: '変更申請',
        applicationFilter: 'フィルタリングできるよ(仮)',
        applicationInformation: 'アプリ情報',
        applicationJoinPlan: 'プラン加入申請',
        applyBecomeStaff: '事務所スタッフ化申請をする',
        applyingBecomeStaff: 'スタッフ申請中',
        applyingJoinPlan: '加入申請中のプラン',
        applySelfLSingerJudge: '審査申し込み',
        applyVoiceOnlyLiver: '声だけLシンガー設定にする',
        approve: '承認',
        appStore: 'App Store',
        authCanceled: '認証がキャンセルされました',
        autoDeleteAfter24Hours: '24時間後に自動削除',
        back: '戻る',
        backLoginPage: 'ログイン画面に戻る',
        backRadioModeMenuText: '声だけ配信モードに戻す',
        ban: 'BAN',
        banFaceImages: '転生検知用BANユーザ画像DB',
        bankAccountAntisocialForceCheckURLCompanyName: '法人名で記事検索',
        bankAccountAntisocialForceCheckURLList: '反社チェック記事一覧',
        bankAccountAntisocialForceCheckURLPersonalName: '個人名で記事検索',
        bankAccountAntisocialForceCheckURLRepresentative: '代表名で記事検索',
        bankAccountBankName: '金融機関名',
        bankAccountBeneficiaryName: '口座名義人 (カナ)',
        bankAccountBirthday: '生年月日',
        bankAccountBranchCode: '支店コード',
        bankAccountBranchName: '支店名',
        bankAccountChecking: '当座',
        bankAccountCompanyAddress: '法人住所',
        bankAccountCompanyName: '法人名',
        bankAccountCompanyPostalCode: '法人郵便番号',
        bankAccountCompanyPostalCodePlaceholder: '法人郵便番号(ハイフン無し)',
        bankAccountCompanyRepresentative: '代表者名',
        bankAccountConfirmRemoval: function (accountName) { return "".concat(accountName, " \u306E\u53E3\u5EA7\u60C5\u5831\u3092\u524A\u9664\u3057\u307E\u3059\u304C\u3001\u3088\u308D\u3057\u3044\u3067\u3059\u304B\uFF1F"); },
        bankAccountDescription1: '記載いただいた個人情報は、ダイヤの換金に係る振込のためのご本人確認、各種手続きにのみ利用いたします。',
        bankAccountDescription2: '振込先口座として指定できるのは、Lシンガー様ご本人名義の口座あるいはご本人が代表の法人口座に限ります。',
        bankAccountDescription3: '口座情報のインボイス登録番号を追加・修正する場合、口座情報を削除・再登録ください。',
        bankAccountDescription4: '※口座登録後、運営による審査が完了しましたら振込できます。審査は通常、1~3営業日かかります。',
        bankAccountDescriptionChooseBankName: '金融機関名を選択してください',
        bankAccountDescriptionChooseBranchName: '支店名を選択してください',
        bankAccountDescriptionChooseCapitalLetterBankName: '金融機関を頭文字から選択してください',
        bankAccountDescriptionChooseCapitalLetterBranchName: '支店名を頭文字から選択してください',
        bankAccountInformation: '口座情報',
        bankAccountInvoiceNumber: 'インボイス登録番号',
        bankAccountInvoiceNumberShort: '登録者名',
        bankAccountNumber: '口座番号',
        bankAccountOrdinary: '普通',
        bankAccountPersonalAddress: '住所',
        bankAccountPersonalKanaName: '氏名 (カナ)',
        bankAccountPersonalName: '氏名',
        bankAccountPersonalPostalCode: '郵便番号 (数字7桁)',
        bankAccountReview: '審査',
        bankAccountReviewStatus: '審査ステータス',
        bankAccountStatus: '審査状況',
        bankAccountStatusApproved: '審査完了',
        bankAccountStatusRejected: '審査NG',
        bankAccountStatusUnderReview: '審査中',
        bankAccountStatusUnreviewed: '未審査',
        bankAccountType: '口座種別',
        banLv1: 'LV1. 24時間',
        banLv2: 'LV2. 3日',
        banLv3: 'LV3. 7日',
        banLv4: 'LV4. 60日',
        banLv5: 'LV5. 180日',
        banLv6: 'LV6. 9999日',
        banLvAndSpan: 'BAN LV と 期間',
        banMenu: 'BANメニュー',
        banStatusWithDescription: function (banDescription) { return "BAN\u72B6\u614B: ".concat(banDescription); },
        baseReward: '基本報酬',
        basicInformation: '配信情報',
        basicInformationAndSongHistories: '配信情報（曲履歴）',
        beautyFilter: '美顔フィルター',
        beautyFilterBeautiful: 'キレイ',
        beautyFilterCool: 'クール',
        beautyFilterCustom: 'カスタム',
        beautyFilterCute: 'キュート',
        beautyFilterEye: 'でか目',
        beautyFilterLip: 'リップ',
        beautyFilterOff: 'OFF',
        beautyFilterSkin: '美肌',
        beautyFilterSlim: 'スリム',
        beautyFilterWhite: '美白',
        beautyFilterWide: '面長',
        becameSongFave: '歌推しになった・歌推しのKを更新した',
        beforeEvent: '開催前',
        beforeEventDescription: function (date) { return "\u73FE\u5728\u30D1\u30FC\u30EB\u676F\u306F\u958B\u50AC\u3055\u308C\u3066\u3044\u307E\u305B\u3093\n\u6B21\u56DE\u306E\u30D1\u30FC\u30EB\u676F\u306F".concat(date, "\u304B\u3089\u958B\u50AC\u3055\u308C\u307E\u3059"); },
        beforeEventDivision: 'この部門は開催前です',
        beforePearlCupStart: 'このパール杯は開催前です',
        beginnerDayCount: function (day) { return "".concat(day, "\u65E5"); },
        billingHistory: '課金履歴',
        bio: '自己紹介',
        birthday: '生年月日',
        block: 'ブロック',
        blocked: 'ブロック済み',
        blockedByModeratorAtYearMonthDay: function (yearMonthDay, moderator) { return "".concat(yearMonthDay, "\u306B").concat(moderator, "\u304C\u4EE3\u7406\u30D6\u30ED\u30C3\u30AF"); },
        blockedByWithdrawalUser: function (yearMonthDay) { return "".concat(yearMonthDay, "\u306B\u9000\u4F1A\u30E6\u30FC\u30B6\u30FC\u304C\u4EE3\u7406\u30D6\u30ED\u30C3\u30AF"); },
        blockedByYouAtYearMonthDay: function (yearMonthDay) { return "".concat(yearMonthDay, "\u306B\u3042\u306A\u305F\u304C\u30D6\u30ED\u30C3\u30AF"); },
        blockList: 'ブロックリスト',
        breakdown: '内訳',
        calculating: '計算中です',
        cancel: 'キャンセル',
        cancelApplicationBecomeStaff: '事務所スタッフ化申請を取り消す',
        cancelApplicationChangePlan: '変更申請を取り消す',
        cancelApplicationJoinPlan: '加入申請を取り消す',
        cancelEntry: 'エントリーを取り消し',
        cancelMembership: '解約',
        cancelMembershipSubscription: '次回更新の停止（解約）',
        cancelOrganizationSupportPlan: function (LsingerName, organizerName) { return "".concat(LsingerName, "\u304C\u3001").concat(organizerName, " \u306E\u30B5\u30DD\u30FC\u30C8\u30D7\u30E9\u30F3\u3092\u9000\u4F1A\u3057\u307E\u3057\u305F\u3002"); },
        cancelUnsubscribingMembership: '解約の取り消し',
        cannotVoteAnyMoreToday: '今日はこれ以上投票できません',
        captureDamApp: '店舗での精密採点AIの結果をDAMアプリで画面収録',
        CaptureImpossibleByBanned: 'リク・画面録画禁止中です',
        CaptureImpossibleByBeforeEntering: '入室前は画面録画はできません',
        CaptureImpossibleByShortOfFavePoint: '画面録画・スクショできる条件を満たしていません（前月・今月のどちらかで5K以上が必要）',
        captureJoysoundApp: '店舗での分析採点AI結果をJOYSOUNDアプリの画面収録',
        capturePocochaEventProfile: 'Pocochaの歌うまイベプロフの画面収録',
        cashOutMethodForLiver: '現金化の方法',
        cautionYouCantStartSing: '前回の曲が終了してからでないと曲を開始できません',
        challenge: '挑戦する',
        change: 'タイトルを変更する',
        changeAnyDiamondToMoneyDescriptionRequestPeriod: '[文言]ダイヤは種別ごとに週に一度まで換金可能です。',
        changeCustomBackground: '背景画像を変更',
        changedCoinFromDiamondPer2Month: '2ヶ月以内のダイヤコイン交換数',
        changeDiamond: '自らダイヤ換金',
        changeDiamondToMoneyAccountName: '口座名',
        changeDiamondToMoneyComplete: '申請が完了しました',
        changeDiamondToMoneyConcurAgreement: '私は上記事項を確認の上、同意します',
        changeDiamondToMoneyDescription1: '※ ColorSingでは利用規約違反の疑いがあるアカウントからの換金申請をお断り・没収させていただく場合がございます。予め御了承ください。',
        changeDiamondToMoneyDescription6: '※ 支払調書の発行はおこなっておりません。換金履歴より徴収額をご確認ください。',
        changeDiamondToMoneyDescriptionMin: '10000ダイヤ以上から申請可能です',
        changeDiamondToMoneyDescriptionRequestPeriod: 'ダイヤの換金は週に1度までです',
        changeDiamondToMoneyDiamondType: 'ダイヤ種別',
        changeDiamondToMoneyForAntiSocialAgreement1: 'ColorSing株式会社から支払を受ける当事者は、自らが、次の各号に掲げる者 (以下「反社会的勢力」と総称する。) に該当しないことを表明し、かつ将来にわたっても該当しないことを確約する。',
        changeDiamondToMoneyForAntiSocialAgreement2: '(1)暴力団\n(2)暴力団員\n(3)暴力団員でなくなった時から5年を経過しない者\n(4)暴力団準構成員\n(5)暴力団関係企業\n(6)総会屋\n(7)社会運動等標ぼうゴロ\n(8)特殊知能暴力集団\n(9)その他前各号に準ずる者',
        changeDiamondToMoneyInputCredit: '口座を入力してください',
        changeDiamondToMoneyInvoiceAgreement1: 'お支払額については下記方針となります。',
        changeDiamondToMoneyInvoiceAgreement2: '適格請求書発行事業者登録をされている場合：消費税を含めてお支払い',
        changeDiamondToMoneyInvoiceAgreement3: '適格請求書発行事業者登録をされていない場合：消費税を差し引いてお支払い',
        changeDiamondToMoneyInvoiceAgreement4: '※なお、インボイス制度につきましては主に国税庁の所管となりますので、制度に関する内容については国税庁のホームページや電話窓口および最寄りの税務署をご利用くださいますようお願いいたします。',
        changeDiamondToMoneyInvoiceAgreement5: 'https://nta.go.jp/taxes/shiraberu/zeimokubetsu/shohi/keigenzeiritsu/invoice_about.htm',
        changeDiamondToMoneyPayer: '支払い者名',
        changeDiamondToMoneyRejectReview: 'ダイヤ換金できません',
        changeDiamondToMoneyRequestDate: '申請日',
        changeDiamondToMoneyRequestMoneyAmount: '申請額',
        changeDiamondToMoneyRequestSubmit: '換金の申請をする',
        changeDiamondToMoneySelectAllMoney: 'すべてのダイヤを換金する',
        changeDiamondToMoneySelectInputMoney: '円分を換金する',
        changeDiamondToMoneySendMoneyAmount: '振込金額',
        changeDiamondToMoneySendPeriodDescription: '換金申請をされた日の翌日に、指定の口座へ振込いたします。通常3～7営業日内にお客様の口座へ振り込まれます。',
        changeDiamondToMoneyShowConsumptionTaxIfWithNumber: function (ConsumptionTax) { return "\u3046\u3061\u6D88\u8CBB\u7A0E\u984D ".concat(ConsumptionTax); },
        changeDiamondToMoneyShowConsumptionTaxIfWithoutNumber: function (ConsumptionTax) { return "\u6D88\u8CBB\u7A0E\u63A7\u9664\u984D ".concat(ConsumptionTax); },
        changeDiamondToMoneyShowSendingMoney: function (money) { return "".concat(money, "\u5206\u304C\u632F\u308A\u8FBC\u307E\u308C\u307E\u3059"); },
        changeDiamondToMoneySubmit: '換金する',
        changeDiamondToMoneyTaxAgreement1: 'ダイヤの換金は所得扱いになり、所得税の確定申告が必要となる場合があります。',
        changeDiamondToMoneyTaxAgreement2: '確定申告が必要なケース例',
        changeDiamondToMoneyTaxAgreement3: '1か所から給与の支払を受けている人で、給与所得及び退職所得以外の所得の金額の合計額が20万円を超える人',
        changeDiamondToMoneyTaxAgreement4: '国税庁ホームページ より引用',
        changeDiamondToMoneyTaxAgreement5: 'ご自身の所得の状況に応じて適切な税務申告と納付を行う必要がございます。具体的な方法や税務相談については、お近くの税務署や税理士にお問い合わせいただきますようお願い申し上げます。',
        changeDiamondToMoneyWaitReview: '現在審査中です。審査が終わるまでお待ちください。',
        changeDiamondToMoneyWithholdAmount: '源泉徴収額',
        changeOnAndOffWithTap: 'タップでオンオフが切り替わります',
        changeOrganizationOwner: 'オーナーを渡す',
        changeOrganizationOwnerCompleted: 'オーナー権限を移譲しました。',
        changeOrganizationOwnerConfirmation: 'オーナーは事務所あたり一人です。別のスタッフをオーナーにした場合、あなたはオーナー権限を失います。それでもオーナー権限を渡しますか？',
        changeOrganizationOwnerFailed: 'オーナー権限移譲に失敗しました。',
        changeOrganizationOwnerHasOrganizationDiamondError: '事務所ダイヤを所持しているのでオーナー権限を渡せません。事務所ダイヤ全て換金してからオーナー権限を渡してください。',
        changeOrganizerProfileImage: '事務所の写真を変更',
        changePlan: 'プラン変更',
        changeProfileImage: 'プロフィール写真を変更',
        changeRandomSong: '別の曲にする',
        changeStaffRole: 'ロールを変更する',
        changeTitle: '配信のタイトルを変更',
        changeUserFlagMenu: 'フラグ変更メニュー',
        checkboxDescriptionHiddenStaff: 'チェックを入れると事務所詳細画面にスタッフ一覧が表示されません。',
        checkedAtWithTime: function (checkedAt) { return "".concat(checkedAt, "\u306B\u30C1\u30A7\u30C3\u30AF\u6E08"); },
        checkLyrics: '歌詞を見る',
        clear: 'CLEAR',
        close: 'とじる',
        closingTime: '締め時間',
        closingTimeSpecific: function (hour) { return "".concat(hour, "\u6642\u7DE0\u3081"); },
        closingTimeStartApplyingDay: '適用開始DAY',
        coin: 'コイン',
        coinAcquisitionEfficiency: 'コイン獲得効率',
        coinCount: 'コイン数',
        coinInformation: '有償・無償のコイン情報',
        coinType: 'コイン種別',
        collectedAccumulatedDiamond: '回収した積み立てダイヤ',
        collectedDiamondDetails: '[文言]事務所へ徴収',
        colon: ':',
        comma: '、',
        comment: 'コメント',
        commentList: 'コメント一覧',
        commentsAreProhibited: 'コメント禁止中',
        commissionDiamond: 'サポート手数料分のダイヤ',
        communityManagement: 'コミュマネ',
        company: '運営会社',
        companyName: 'ColorSing株式会社',
        compensation: '補填',
        condition: '条件',
        confirm: '確認',
        confirmApplicationBecomeStaff: function (organizerName) { return "".concat(organizerName, " \u306B\u30B9\u30BF\u30C3\u30D5\u7533\u8ACB\u3057\u307E\u3059\u304B\uFF1F"); },
        confirmApplicationChangePlan: function (beforePlanName, afterPlanName) { return "".concat(beforePlanName, " \u304B\u3089 ").concat(afterPlanName, " \u306B\u30D7\u30E9\u30F3\u5909\u66F4\u7533\u8ACB\u3057\u307E\u3059\u304B\uFF1F"); },
        confirmApplicationJoinPlan: function (organizerName, planName) { return "".concat(organizerName, " \u306E ").concat(planName, " \u306B\u52A0\u5165\u7533\u8ACB\u3057\u307E\u3059\u304B\uFF1F\u7533\u8ACB\u4E88\u5B9A\u306E\u4E8B\u52D9\u6240\u3068\u4E8B\u524D\u306B\u8A71\u3057\u5408\u3044\u306E\u4E0A\u3067\u3054\u7533\u8ACB\u304F\u3060\u3055\u3044\u3002"); },
        confirmApplicationJoinPlanMessage: '事務所の事前承諾なしに加入申請した場合、会員規約違反となる場合があるため、ご遠慮ください。',
        confirmApprove: '承認しますか？',
        confirmBlock: function (name) { return "".concat(name, " \u3092\u30D6\u30ED\u30C3\u30AF\u3057\u307E\u3059\u304B\uFF1F "); },
        confirmButtonDeleteSupportPlun: '[文言]プランを削除する',
        confirmCancelApplicationBecomeStaff: 'スタッフ申請を取り消しますか？',
        confirmCancelApplicationChangePlan: '変更申請を取り消しますか？',
        confirmCancelApplicationJoinPlan: '加入申請を取り消しますか？',
        confirmCancelEdit: '編集内容を破棄しますか？',
        confirmChangeStaffRole: 'ロールを変更しますか？',
        confirmDialogDeleteSupportPlun: '[文言]プランを削除してもよろしいですか？',
        confirmDialogDenyDeleteSupportPlun: '[文言]このプランに加入中のLシンガーがいるため削除できません。',
        confirmDialogDenyOwnerAuthority: '[文言]事務所ダイヤを所持しているのでオーナー権限を渡せません。事務所ダイヤを全て換金してからオーナー権限を渡してください。',
        confirmDialogPassOwnerAuthority: 'オーナーは事務所あたり一人です。別のスタッフをオーナーにした場合、あなたはオーナー権限を失います。それでもオーナーを渡しますか？',
        confirmExecuteBelow: '以下の内容で実行しますか？',
        confirmFinishLive: '配信を終了しますか？',
        confirmKickLiverFromPlan: 'このLシンガーをプランから強制解除します。よろしいですか？',
        confirmKickStaff: 'このスタッフを事務所から外します。よろしいですか？',
        confirmLiftDeleteAccount: 'アカウントの退会 (削除) 処理の解除を行いますか？退会申請から30日間までに解除すると、再度同じアカウントで、ColorSingを遊ぶことができます。',
        confirmLogout: 'ログアウトしますか？',
        confirmNotWithdrawPlanOrganizationPayment: '辞める場合は、必ず該当事務所と双方合意の上で辞めるようにお手続きしてください。【事務所支払い】プランのサポートプランを辞める場合は、毎月1日の12:00から23:59までの間でしか手続きすることができません。',
        confirmOrganizationInformation: 'サポートプラン加入中の事務所情報を確認',
        confirmProhibitComments: function (name) { return "".concat(name, " \u306E\u30B3\u30E1\u30F3\u30C8\u3092\u7981\u6B62\u3057\u307E\u3059\u304B\uFF1F"); },
        confirmQuitStaff: '事務所のスタッフをやめると、この事務所管理画面は実行したタイミングから閲覧できなくなり、この画面は閉じます。それでもこの事務所をやめますか？',
        confirmRegisterOrganizer: '以下の情報で事務所を開設します。よろしければ「登録」をタップしてください。',
        confirmRejectApplication: '拒否しますか？',
        confirmSingFromStart: '最初から歌い直します。よろしいですか？',
        confirmStopSinging: '曲を終了します。よろしいですか？',
        confirmUnblock: function (name) { return "".concat(name, " \u306E\u30D6\u30ED\u30C3\u30AF\u3092\u89E3\u9664\u3057\u307E\u3059\u304B\uFF1F "); },
        confirmUnchangeableDiamond: 'このプランでは、事務所からの給与の支払いとなるため、Lシンガーは自らダイヤ交換をすることは出来ません。事務所からの事務所支払いに関して、ColorSingは責任を負いかねます。事務所との契約等をご確認の上、加入申請してください。このプランに加入申請しますか？',
        confirmUnfollow: function (name) { return "".concat(name, " \u306E\u30D5\u30A9\u30ED\u30FC\u3092\u89E3\u9664\u3057\u307E\u3059\u304B\uFF1F "); },
        confirmUnprohibitComments: function (name) { return "".concat(name, " \u306E\u30B3\u30E1\u30F3\u30C8\u7981\u6B62\u3092\u89E3\u9664\u3057\u307E\u3059\u304B\uFF1F"); },
        confirmVideoLiveOnlyThisTime: '声だけLシンガー設定のまま、今回だけ顔出し配信にすることができます。顔出し配信にしますか？',
        confirmWithdrawPlan: '加入しているプランを辞めますか？辞める場合は、必ずサポートプランに加入している事務所と双方合意の上で辞めるようにお手続きしてください。',
        confirmWithdrawPlanOrganizationPayment: '辞める場合は、必ず該当事務所と双方合意の上で辞めるようにお手続きしてください。【事務所支払い】プランのサポートプランを辞める場合は、毎月1日の12:00から23:59までの間に辞める手続きを行う必要があります。加入しているプランを辞めますか？',
        connected: '連携済み',
        connectedAccount: '連携済みのアカウント',
        connectedLINE: 'LINE連携しました',
        connectedSNS: '連携SNS',
        connectLINE: 'LINE認証・連携',
        connectTwitterOrTweetSetting: 'Twitter連携・ツイート設定',
        consentDeleteAccount: 'アカウントの削除 (退会) について上記事項に同意する',
        consumedAcquisitionRight: '消費した獲得権',
        consumptionTaxAmount: '消費税額',
        consumptionTaxDeductionAmount: '消費税控除額',
        contactAdmin: '管理者にお問い合わせください',
        content: '本文',
        contentsOfChangingMembershipPlan: 'プラン変更の内容',
        continuousRewardDiamond: '継続報酬ダイヤ',
        convenienceStorePayment: 'コンビニ決済',
        conversionAmountPearlsAndDiamondsToUsed: '利用するパールとダイヤの円換算額',
        copiedText: function (text) { return "".concat(text, "\u3092\u30B3\u30D4\u30FC\u3057\u307E\u3057\u305F"); },
        copyright: 'Copyright @ColorSing Co., Ltd All right rederved',
        countMoreTimes: function (count) { return "\u3042\u3068".concat(count, "\u56DE"); },
        countSheets: function (count) { return "".concat(count, "\u679A"); },
        create: '新規作成',
        createLsingerGroup: 'グループの作成',
        createLsingerGroupButtonLabel: 'グループを作成',
        createSupportPlan: 'サポートプランの作成',
        creditCardPayment: 'クレジットカード決済',
        currentAndNextFavePoint: function (current, next) { return "".concat(current, " / Next ").concat(next); },
        currentAndNextSongFave: function (current, next) { return "".concat(current, "\u4EBA / Next ").concat(next, "\u4EBA"); },
        currentlyCalculating: '現在計算中です',
        currentlyJoining: '現在加入中',
        currentlyOnePhraseEventRankingCalculating: function (month, day) { return "\u7D50\u679C\u306F".concat(month, "\u6708").concat(day, "\u65E500:00\u304B\u3089\u516C\u958B\u3055\u308C\u307E\u3059"); },
        currentRole: function (organizerRole) { return "\u73FE\u5728\u306E\u30ED\u30FC\u30EB: ".concat(organizerRole); },
        customBackground: 'カスタム背景',
        customBackgroundDialogDescription: '全画面でカスタム背景を設定できます。設定しますか？',
        customRegistration: 'カスタム登録',
        daily: '1日',
        dailyOfBanner: 'Daily',
        dashboard: 'ダッシュボード',
        dataDownloadOwnerPage: 'データダウンロード（オーナー用）',
        dataDownloadPage: 'データダウンロード',
        dateAndTime: '日時',
        dateDay: function (day) { return "".concat(day, "\u65E5"); },
        dateHour: function (hour) { return "".concat(hour, "\u6642\u9593"); },
        dateMinute: function (minute) { return "".concat(minute, "\u5206"); },
        dateOfExpiry: function (dateString) { return "\u6709\u52B9\u671F\u9650\uFF1A".concat(dateString); },
        dateOfExpiryStar: function (date) { return "\u6240\u6301: ".concat(date, " \u307E\u3067\u6709\u52B9"); },
        dateOfWithdrawApplication: '換金申請日',
        datepageDiscription: 'データをCSV形式でダウンロードすることができます。データは1日前より以前の日付のデータを取得できます。（本日分のデータは表示されません）',
        dateWeek: function (week) { return "".concat(week, "\u9031"); },
        dateYyyymmdd: function (year, month, day) { return "".concat(year, "\u5E74").concat(month, "\u6708").concat(day, "\u65E5"); },
        day: 'DAY',
        daysWithAtLeast5SuperLikesPerDay: 'スーパーいいねを1日5回以上した日数',
        debug: 'デバッグ',
        decide: '決定',
        decreaseAdjustmentYoutubeViewingDelay: '+',
        delete: '削除',
        deleteAccount: 'アカウント削除 (退会)',
        deleteAccountComplete: '退会が完了しました',
        deleteAfter24Hours: '24時間後に削除',
        deleteAtNextLive: '次回配信開始時に削除',
        deleteLsingerGroup: '[文言]',
        deleteOrganizationPlanHasApplicationError: 'このプランにLシンガーの加入申請があるため削除できません。',
        deleteOrganizationPlanHasLsingerError: 'このプランに加入中のLシンガーがいるため削除できません。',
        deleteOrganizationSupportPlan: 'プランを削除',
        deleteOrganizationSupportPlanCompleted: 'プランを削除しました。',
        deleteOrganizationSupportPlanConfirmation: 'プランを削除してもよろしいですか？',
        deleteOrganizationSupportPlanFailed: 'プランの削除に失敗しました。',
        deleteTweetSetting: 'ツイート削除設定',
        deleteWhenNextLive: '次回配信時に削除',
        demonGod: '魔神',
        depositAmount: '振込額',
        deprivationCoin: 'コインを剥奪する',
        deprivationDiamond: 'ダイヤを剥奪する',
        descriptionAboutClosingTime: 'ColorSingでは、毎日、締め時間に応じてリーグの昇格戦争いが行われています。締め時間は複数あるので、ご自身のライフスタイルに合わせて選択してください。',
        descriptionAboutOrganizerID: '事務所を識別するためのIDです。3文字以上で15文字以内で、半角英数字とアンダースコア (_) のみ使用できます。',
        descriptionAboutPfFavePoint: '全体推しPtとは、アプリ全体で獲得した３旬の合計の推しPtのことです。',
        descriptionAddFavoriteArtist: '好きなアーティストを追加できます。',
        descriptionAgreement1: '個人のSNSに許可なく投稿されることはありません。\n続けることにより、あなたは',
        descriptionAgreement2: 'を理解・同意したものとみなされます。',
        descriptionAllowCameraOrMic: '配信を行う際に必要になります',
        descriptionApplicationFilter: '左上のColorSingのロゴをタップすると、アプリの中をフィルタリングできます。声だけで楽しむ世界なども可能です。早速フィルターを試してみますか？',
        descriptionArtistSelection: '同じアーティストを追加したユーザと交流しやすくなり、最大12コインまで獲得できます。',
        descriptionBirthdayNecessity: 'ユーザーの皆様の安全を守るため、10代の皆様には、年齢を確認させていただく必要があります。ColorSing上のキャラクターとしての年齢ではなく、ご本人の年齢を元に選択してください。誕生日や年齢は非公開で、確認が必要なのは1度きりです。',
        descriptionCancelEdit: '編集内容が保存されていません。破棄してもよろしいですか？',
        descriptionChangeClosingTime: '締め時間変更がどうのこうの',
        descriptionConnectLINE: function (amount) { return "".concat(amount, "\u30B3\u30A4\u30F3\u7372\u5F97\u3057\u307E\u3057\u305F"); },
        descriptionCreateSupportPlan: '以下の情報でサポートプランを作成します。よろしければ「新規作成」をタップしてください。',
        descriptionDeleteAccount: 'アカウントを削除 (退会) し、30日間経過すると、所持コイン、所持ダイヤ等含む全てのお客様のデータは失効し、復旧することはできません。失効するデータおよび削除 (退会) 時の注意事項については、必ず以下をご確認下さい。',
        descriptionDeleteAccountApple: 'ColorSingアカウントとの連携を完全に削除されたい場合は、以下を参照の上、ご対応ください。',
        descriptionDeleteAccountComplete: 'アカウントの削除 (退会) が30日後に実行されます。それまでは、アカウントの削除の解除も可能です。',
        descriptionForLiversInMembership: 'メンバーシップに加入するには、メンバーシップパスのサブスク購入が必要です。誰のメンバーシップに加入するかを選択してください。',
        descriptionGenerationNecessity: 'ユーザーの皆様の安全を守るため、年齢を確認させていただく必要があります。ColorSing上のキャラクターとしての年齢ではなく、ご本人の年齢を元に選択してください。年代は非公開で、確認が必要なのは1度きりです。',
        descriptionLogin: 'に同意してSNSアカウントでログイン',
        descriptionMyTown: 'マイタウンとは、ゆかりのある土地・心の故郷・自分の街・好きな地域などの総称です。プロフィールに表示され、タイムラインやランキング等にも活用されます。',
        descriptionOfCoinExchangeFromCoin1: '1ダイヤ0.9コインで交換できます。なお、歌ダイヤのスタートボーナスキャンペーンで獲得したダイヤや事務所報酬として追加獲得したダイヤは、コイン交換できません',
        descriptionOfCoinExchangeFromCoin2: '※ 今交換可能なダイヤ数は、①今月を含む直近2ヶ月のダイヤ獲得数の20% - ダイヤコイン交換数、かつ、②50000コイン以内です。',
        descriptionOfJoiningMembership: 'メンバーシップパスをサブスク購入することでメンバーシップに加入でき、コメント欄やミニプロフにつくメンバーシップバッジや推しPt・枠の皆で安価でギフトを使用できる福音・枠内限定コイン等のさまざまな特典を受け取ることが出来ます。また、収益が Lシンガーさんに分配されるため、Lシンガーさんの応援にもつながります。\nパスは複数枚を購入可能で、購入数が増えるとこれらの特典が増えます。',
        descriptionOfStar: '※  スターは、種類毎・１つにつき増える有効期限と有効条件があります。購入と同時に有効期限は増えます。最後のweb課金から60日間のweb課金比率が有効条件を下回る場合、またはLINE未連携時は、無効になります。',
        descriptionOfStarLv1: '■ 1.5倍スターについて\n\n・1つ購入につき有効期限が30日間増\n\n・Web購入比率が50%以上のとき有効\n\n・LINE連携時に有効',
        descriptionOfStarLv2: '■ 2.5倍スターについて\n\n・1つ購入につき有効期限が45日間増\n\n・Web購入比率が60%以上のとき有効\n\n・LINE連携時に有効',
        descriptionOfStarLv3: '■ 3.5倍スターについて\n\n・1つ購入につき有効期限が60日間増\n\n・Web購入比率が70%以上のとき有効\n\n・LINE連携時に有効',
        descriptionOrganizerAndSupportPlan: '事務所とは、ColorSing上に登録された、Lシンガーが活動に専念できるようサポートしてくれる個人事務所や法人事務所のことで、サポートプランとは、それぞれの事務所が様々なオプションで提供しているLシンガーをサポートする制度のことです。\n\nこの画面上では、事務所とサポートプランを検索・加入申請ができます。\n加入申請が承認されると、事務所契約の有無に関わらず、オンライン完結でプランに加入できます。',
        descriptionOrganizerAndSupportPlanDanger: '事務所の事前承諾なしに加入申請した場合、会員規約違反となる場合があるため、ご遠慮ください。',
        descriptionRegisterMyTown: 'マイタウンを選択すると、自身のマイタウンと近いマイタウンユーザと交流しやすくなります。',
        descriptionRegisterMyTownWithCoin: 'マイタウンを選択すると、最大10コインまで獲得できるほか、自身のマイタウンと近いマイタウンユーザと交流しやすくなります。',
        descriptionRegisterOrganizer: '事務所の情報とサポートする内容を定めたサポートプランの情報を登録するだけで、オンライン完結で事務所を開設できます。既にご活躍中の法人でも、これからの個人でも、難しい手続きなしでかんたんに事務所を開設できます。Lシンガーがサポートプランに加入することで、事務所オーナーはLシンガーが獲得したダイヤ分に対して、「当社が別途指定するパーセンテージを乗じた数」のダイヤを追加報酬として獲得できます。',
        descriptionRegistrationProhibited: '過去の違反行為により、大変申し訳ございませんが、ColorSingでは、永久に登録を行うことはできません。',
        descriptionSearchByUserName: 'ユーザー名で検索してください',
        descriptionSettingCashOutMethod: 'Lシンガーにとっての現金化の方法を設定できます。「自らダイヤ交換」に設定すると事務所とLシンガーに自動でダイヤが割り振られます。「事務所支払い」に設定すると、ダイヤは全て事務所に割り振られるので、そこからの給与の支払いは事務所側の責任となります。\nまた、最初に設定した現金化の方法は変更できません。',
        descriptionSettingOrganizerCommissionRate: '設定した分だけLシンガーから手数料を得ることができます。\nまた、最初に設定した手数料は変更できません。',
        descriptionUnder18: '18歳未満の方は保護者または親権者の方の同意を得た場合のみ、登録ボタンを押してください',
        descriptionUnderMaintenance: '詳細は公式Twitterを参照ください',
        descriptionUpdateApp: 'ストアより最新版のアプリへのアップデートをお願いします',
        descriptionVoiceOnlyLiverSetting: '声だけLシンガー設定にすると、配信時にカメラが起動せず、プロフィールに声だけLシンガーマークが表示されます。',
        descriptionWarningBluetoothEarphone: 'Bluetoothイヤホンの場合、歌とBGMに大きくズレが生じます。マイク付きの有線イヤホンを接続してください',
        descriptionWebLogin: 'ログインには、アプリでLINE連携の設定が必要です。',
        destruction: '破棄',
        deviceList: '端末一覧',
        dialogConfirmKickLiverFromPlan: '必ず所属Lシンガーと双方合意した上で強制解除を行ってください。',
        dialogDescription1ToPurchaseWebCoin: '⚠️「将来の購入のために支払い情報を保存する」をオンにしてください',
        dialogDescription2ToPurchaseWebCoin: 'オフの場合は、次回に再入力を求められます',
        dialogDescriptionConfirmLSingerJudgeTwitter: '審査フォームが公開されており、他の人からあなたのTwitterアカウントが推薦されることで、審査に通過する可能性があります。\nTwitterと連携して、審査の状況を確認することができます。\nTwitterアカウントを持っていない場合、または自分で審査申し込みする場合は、以下の「審査申し込み」ボタンをタップしてください。',
        dialogDescriptionForFailedSecondReview: function (year, month, term) { return "\u518D\u5EA6\u4E8C\u6B21\u5BE9\u67FB\u306B\u6311\u6226 (\u914D\u4FE1) \u304C\u53EF\u80FD\u306B\u306A\u308B\u306E\u306F".concat(year, "\u5E74").concat(month, "\u6708").concat(term, "\u4EE5\u964D\u3067\u3059"); },
        dialogDescriptionForOnePhraseRecordingWithEarphone: '録音品質担保のため、有線イヤホンの接続が必須です。有線イヤホンを接続して再度お試しください。',
        dialogDescriptionLiveUnavailable: '配信可能な時間は18:00~24:00です',
        dialogDescriptionLiveUnavailable2: '配信可能な時間は11:00~25:00です',
        dialogDescriptionLiveUnavailable3: '配信可能な時間は09:00~26:00です',
        dialogDescriptionLiveUnavailable4: 'AM1:00~1:30は配信ができません',
        dialogDescriptionLiveUnsupportedOnAndroid: '今後のリリースをお待ちください',
        dialogDescriptionOfAnonymousEntryDone: function (nickname) { return "\u300C".concat(nickname, "\u300D\u3068\u3044\u3046\u30CB\u30C3\u30AF\u30CD\u30FC\u30E0\u3067\u30A8\u30F3\u30C8\u30EA\u30FC\u3057\u3066\u3044\u307E\u3059"); },
        dialogDescriptionSelfApplyLSingerJudge: 'Lシンガー審査申し込みフォームにて、審査に申し込みをすることができます。\nすでに申し込みをされていて、審査を通過した場合は、アプリ内の通知画面で結果をお知らせいたします。もうしばらくお待ちください。',
        dialogDescriptionToCancelMembershipSubscription: function (liver, month) { return "".concat(liver, "\u306E\u30E1\u30F3\u30D0\u30FC\u30B7\u30C3\u30D7\u30D1\u30B9").concat(month, "\u30F6\u6708\u306E\u6B21\u56DE\u66F4\u65B0\u3092\u505C\u6B62\uFF08\u89E3\u7D04\uFF09\u3057\u307E\u3059\u3002\u3088\u308D\u3057\u3044\u3067\u3059\u304B\uFF1F"); },
        dialogDescriptionToCancelUnsubscribingSubscription: function (liver, month) { return "".concat(liver, "\u306E\u30E1\u30F3\u30D0\u30FC\u30B7\u30C3\u30D7\u30D1\u30B9").concat(month, "\u30F6\u6708\u306E\u6B21\u56DE\u66F4\u65B0\u306E\u505C\u6B62\uFF08\u89E3\u7D04\uFF09\u3092\u53D6\u308A\u6D88\u3057\u307E\u3059\u3002\u3088\u308D\u3057\u3044\u3067\u3059\u304B\uFF1F"); },
        dialogDescriptionToConfirmFinishingOnePhraseRecordingBecauseNoEntry: '期間中は何回でも録音・エントリーしなおすことができます',
        dialogDescriptionToEntryOnePhraseEvent: '期間中は何回でも録音・エントリーしなおすことができます。なお、匿名エントリーでは、自分のプロフィールと紐づけることなく実力を試すことが可能です。',
        dialogDescriptionToForceSetGender: 'より推しが見つかりやすいようなタイムラインの構築に必要な要素の一つとなりますので、ご回答をお願いします',
        dialogDescriptionTsunaguInitial: 'クリエイター検索は、クラウドソーシングサービス「つなぐ」との共同運営の機能です。MIX師さん、絵師さん、動画師さんなどに有償で依頼ができます。\n\n※ ご利用にはつなぐのアカウント登録が必要です。詳細はFAQをご覧ください',
        dialogMessageForLivePermissionReview: '期間内にリーグ25以上にならなければ配信ができなくなります',
        dialogMessageForLivePermissionTrial: function (day) { return "\u914D\u4FE1\u306F\u3042\u3068".concat(day, "\u65E5\u9593\u304A\u305F\u3081\u3057\u914D\u4FE1\u306B\u306A\u308A\u307E\u3059\u3002\u305D\u306E\u5F8C\u65E5\u306B\u914D\u4FE1\u3059\u308B\u3068\u3001\u305D\u306E\u65E5\u304B\u3089\u6570\u3048\u30663\u65EC\u5F8C\u306E\u65EC\u672B\u304C\u4E8C\u6B21\u5BE9\u67FB\u306E\u671F\u9650\u304C\u8A2D\u5B9A\u3055\u308C\u3001\u30EA\u30FC\u30B025\u4EE5\u4E0A\u306B\u306A\u3089\u306A\u3051\u308C\u3070\u914D\u4FE1\u304C\u3067\u304D\u306A\u304F\u306A\u308A\u307E\u3059"); },
        dialogTitleConfirmLSingerJudgeTwitter: '現在、配信を行うためには審査が必要となります',
        dialogTitleConfirmToDeleteByName: function (name) { return "".concat(name, "\u3092\u524A\u9664\u3057\u307E\u3059\u304B\uFF1F"); },
        dialogTitleFollowingUserNotificationOff: function (name) { return "".concat(name, " \u306E\u901A\u77E5\u3092\u30AA\u30D5\u306B\u3057\u307E\u3059\u304B\uFF1F"); },
        dialogTitleForFailedSecondReview: '二次審査通過条件を満たしていないため、配信できません',
        dialogTitleForLivePermissionReview: function (month, term) { return "\u4E8C\u6B21\u5BE9\u67FB\u306E\u671F\u9650\u306F".concat(month, "\u6708").concat(term, "\u3067\u3059"); },
        dialogTitleForLivePermissionTrial: function (day) { return "\u4E8C\u6B21\u5BE9\u67FB\u306E\u671F\u9650\u304C\u8A2D\u5B9A\u3055\u308C\u308B\u524D\u306B\u3001".concat(day, "\u65E5\u9593\u304A\u305F\u3081\u3057\u914D\u4FE1\u304C\u3067\u304D\u307E\u3059"); },
        dialogTitleLeagueMeterHelp: function (point) { return "\u3042\u3068".concat(point, "\u3067\u6607\u683C\u306E\u7406\u7531"); },
        dialogTitleLiveUnavailable: '現在は配信できません',
        dialogTitleLiveUnsupportedOnAndroid: 'Androidの配信機能は現在提供しておりません',
        dialogTitleOfAnonymousEntryDone: function (name) { return "".concat(name, "\u3067\u533F\u540D\u30A8\u30F3\u30C8\u30EA\u30FC\u3057\u307E\u3057\u305F"); },
        dialogTitlePearlPrizeHelp: '獲得パールの詳細',
        dialogTitleRequestedToAddNewSong: '楽曲リクエストが完了しました',
        dialogTitleToCancelEntryOfOnePhraseEvent: function (name) { return "".concat(name, "\u306E\u30A8\u30F3\u30C8\u30EA\u30FC\u3092\u53D6\u308A\u6D88\u3057\u307E\u3059\u304B\uFF1F"); },
        dialogTitleToCancelVotingOnePhrase: '投票が完了していませんが、THE ONE PHRASEの投票を終了します。よろしいですか？',
        dialogTitleToChangeEntryFile: function (name, newName) { return "\u73FE\u5728".concat(name, "\u3067\u30A8\u30F3\u30C8\u30EA\u30FC\u4E2D\u3067\u3059\u3002\u4EE3\u308F\u308A\u306B").concat(newName, "\u3067\u30A8\u30F3\u30C8\u30EA\u30FC\u3057\u307E\u3059\u304B\uFF1F"); },
        dialogTitleToCompleteVotingOnePhrase: function (names, name) { return "".concat(names, "\u304B\u3089").concat(name, "\u3092\u4E00\u756A\u826F\u3044\u3082\u306E\u3068\u3057\u3066\u9078\u629E\u3057\u3066\u6295\u7968\u3092\u5B8C\u4E86\u3057\u307E\u3059\u3002 \u3088\u308D\u3057\u3044\u3067\u3059\u304B\uFF1F"); },
        dialogTitleToConfirmFinishingOnePhraseRecordingBecauseNoEntry: 'エントリーをしていませんが、THE ONE PHRASE録音を終了します。よろしいですか？',
        dialogTitleToConfirmOverPricedCoin: '⚠ アプリ内で累計1万コイン以上購入した場合、以後アプリでの購入はそれ以前と比べると25%の割高となります。よろしいですか？',
        dialogTitleToEntryOnePhraseEvent: function (name) { return "".concat(name, "\u3067\u30A8\u30F3\u30C8\u30EA\u30FC\u3057\u307E\u3059\u304B\uFF1F"); },
        dialogTitleToExceedVoting: '投票回数制限を超えています',
        dialogTitleToExpireToEntryOnePhraseTerm: 'エントリー期間が過ぎています',
        dialogTitleToExpireVotingTerm: '投票期間が過ぎています',
        dialogTitleToForceSetGender: '性別を選択してください',
        dialogTitleToPurchaseWebCoin: 'どちらで決済しますか？',
        dialogTitleToRequestSingSongNotAllowed: 'リクエストできる条件を満たしていません',
        dialogTitleToSelectListenerAsModerator: function (listener) { return "".concat(listener, "\u3092\u30E2\u30C7\u30EC\u30FC\u30BF\u30FC\u306B\u9078\u3073\u307E\u3059\u304B\uFF1F"); },
        dialogTitleToTerminateModerator: function (listener) { return "".concat(listener, "\u3092\u30E2\u30C7\u30EC\u30FC\u30BF\u30FC\u304B\u3089\u89E3\u9664\u3057\u307E\u3059\u304B\uFF1F"); },
        dialogTitleTsunaguInitial: 'この画面について',
        dialogTitleYoutubeViewing: 'Youtube同時視聴 (実験的β版)',
        dialogTitleYoutubeViewingSetting: 'YouTube視聴設定',
        dialogTitleYoutubeViewingStreamingSetting: 'YouTube再生設定',
        diamond: 'ダイヤ',
        diamondAcquisitionRight: 'ダイヤ獲得権',
        diamondChatting: '雑談ダイヤ',
        diamondGetRecord: '[文言]ダイヤ獲得履歴',
        diamondOfPossession: '所持ダイヤ',
        diamondReport: 'ダイヤ明細',
        diamondRiseAndFall: 'ダイヤ入出',
        diamondRiseAndFallType: '入出理由',
        diamondSinging: '歌ダイヤ',
        diamondsPossessionPayAvailable: '利用できる所持ダイヤ',
        diamondUnexchangeableToCoinAmount: 'コイン交換できないダイヤ (スタートダッシュ分) ',
        directNotification: '個別通知',
        disabledYoutubeViewingVideo: '映像はオフです',
        doBlockByModerator: '代理ブロック',
        doChange: '変更',
        doItLater: '今はやめておく',
        done: '完了',
        doNotShareCheckoutSessionURL: function (userName) { return "\u26A0\u3053\u3053\u3067\u8CFC\u5165\u3055\u308C\u305F\u30B3\u30A4\u30F3\u306F\u300E".concat(userName, "\u300F\u306B\u4ED8\u4E0E\u3055\u308C\u307E\u3059\u3002\u4ED6\u306E\u65B9\u3078\u3053\u306EURL\u306E\u5171\u6709\u306F\u304A\u63A7\u3048\u304F\u3060\u3055\u3044\u3002"); },
        doNotShowMyGender: '性別をプロフや一覧では表示しない',
        doVideoLive: '顔出し配信にする',
        downlordApp: 'アプリをダウンロード',
        dream: '夢',
        edit: '編集',
        editLsingerGroup: 'グループ編集',
        editOrganizerInformation: '事務所情報の編集',
        editProfile: 'プロフィール編集',
        editRepartrie: 'レパートリー編集',
        editSupportPlan: 'サポートプランの編集',
        electionModerator: function (displayName, year, month, day) { return "".concat(displayName, "\u306E\u30E2\u30C7\u30EC\u30FC\u30BF\u30FC\u306B\u9078\u3070\u308C\u307E\u3057\u305F (\u4EFB\u671F\uFF1A").concat(year, "\u5E74").concat(month, "\u6708").concat(day, "\u65E5\u307E\u3067)"); },
        emptyAccountIdInstagram: 'InstagramのアカウントIDが設定されていません',
        emptyAccountIdTikTok: 'TikTokのアカウントIDが設定されていません',
        emptyAccountIdTwitter: 'TwitterのアカウントIDが設定されていません',
        emptyBlocking: 'まだブロックしているユーザーがいません',
        emptyDataNotFound: 'データが見つかりませんでした',
        emptyDescriptionLiveUnavailable: '配信・視聴が可能な時間は18:00~24:00です',
        emptyDescriptionLiveUnavailable2: '配信・視聴が可能な時間は11:00~25:00です',
        emptyDescriptionLiveUnavailable3: '配信・視聴が可能な時間は09:00~26:00です',
        emptyDescriptionLiveUnavailable4: 'AM1:00~1:30は配信・視聴ができません',
        emptyFavorite: 'レパートリーはありません',
        emptyFollower: 'まだフォロワーがいません',
        emptyFollowing: 'まだフォローしているユーザーがいません',
        emptyGift: 'ギフトはありません',
        emptyHandleYouTube: 'YouTubeのハンドルが設定されていません',
        emptyHistory: '履歴はありません',
        emptyInformation: '情報はありません',
        emptyJoinablePlan: '新規受付中のプランはありません',
        emptyJoiningMemberships: '加入中のメンバーシップはありません',
        emptyLiveList: '現在配信はありません',
        emptyMembershipListeners: 'メンバーシップ加入者はいません',
        emptyModerator: 'まだモデレーターがいません',
        emptyNotification: '通知はありません',
        emptyPearlPrize: '該当者はいません',
        emptyRanking: 'ランキングはありません',
        emptyRecordedAudio: '録音はまだありません',
        emptyRequestedSong: '候補/リクはありません',
        emptySearchResult: '検索結果はありません',
        emptyStaffs: '表示できるスタッフはいません',
        emptySupportedLivers: '表示できるSupported Lシンガーはいません',
        emptySupportPlan: 'サポートプランはありません',
        emptyTitleLiveUnavailable: '現在、配信・視聴はできません',
        enableYoutubeViewingVideo: '映像の表示',
        enterLiveComment: 'コメントを入力',
        entry: 'エントリー',
        entryDone: 'エントリー済',
        equivalentYen: function (value) { return "\u00A5".concat(value, "\u76F8\u5F53"); },
        error: 'エラー',
        errorAboutOrganizerID: '[文言]事務所IDは、3文字以上15文字以内で、半角英数字とアンダースコア(_)で入力してください',
        errorAddSongToFavorite: function (errorMessage) { return "\u30EC\u30D1\u30FC\u30C8\u30EA\u30FC\u306B\u8FFD\u52A0\u3067\u304D\u307E\u305B\u3093\u3067\u3057\u305F\u3002".concat(errorMessage); },
        errorAllowCameraAndMic: 'カメラとマイクのアクセスを許可してください',
        errorAllowMic: 'マイクのアクセスを許可してください',
        errorAlreadyExists: 'このデータはすでに存在しています。',
        errorApplicationChangePlan: '変更申請できない状態です',
        errorBecomeOrganizerStaff: '事務所スタッフになれない状態です',
        errorBlock: function (name, errorMessage) { return "".concat(name, " \u3092\u30D6\u30ED\u30C3\u30AF\u3067\u304D\u307E\u305B\u3093\u3067\u3057\u305F\u3002").concat(errorMessage); },
        errorCancelled: 'キャンセルされました。',
        errorChangeDiamondToMoneyAlreadyRequest: function (month, day, money) { return "".concat(month, "\u6708").concat(day, "\u65E5\u306B").concat(money, "\u5186\u5206\u306E\u63DB\u91D1\u7533\u8ACB\u6E08\u307F\u3067\u3059"); },
        errorChangeDiamondToMoneyAozoraAPI: function (errorCode) { return "\u632F\u8FBC\u51E6\u7406\u6642\u306B\u30A8\u30E9\u30FC\u304C\u767A\u751F\u3057\u307E\u3057\u305F\u3002\u30A8\u30E9\u30FC\u30B3\u30FC\u30C9: ".concat(errorCode); },
        errorChangeDiamondToMoneyBAN: '機能制限中のユーザーはダイヤ換金できません',
        errorChangeDiamondToMoneyIllegalNumber: '金額を入力してください',
        errorChangeDiamondToMoneyIllegalRequest: '換金に必要な条件を満たしていません',
        errorChangeDiamondToMoneyInternalServer: 'サーバー内部でエラーが発生しました',
        errorChangeDiamondToMoneyMin: '10000ダイヤ以上から申請可能です。',
        errorChangeDiamondToMoneyNoBankAccount: '口座情報を登録してください',
        errorChangeDiamondToMoneyNotLineAccountRegistered: 'LINE連携されていません',
        errorChangeDiamondToMoneyNotPermitted: 'ダイヤ換金が可能なユーザーではありません',
        errorChangeDiamondToMoneyUnableToSend: '口座へ振込できませんでした。口座情報が正しいかご確認ください。',
        errorChangeDiamondToMoneyUnderCheckingByAdministrator: '口座情報の審査に通っていません',
        errorChangeDiamondToMoneyUnderValueOfPossession: 'ダイヤ所持数以下を入力してください',
        errorChangeDiamondToMoneyWithdraw: '退会済のユーザーはダイヤ換金できません',
        errorChangeLiveTitle: function (errorMessage) { return "\u914D\u4FE1\u306E\u30BF\u30A4\u30C8\u30EB\u3092\u5909\u66F4\u3067\u304D\u307E\u305B\u3093\u3067\u3057\u305F\u3002".concat(errorMessage); },
        errorChangeRolePermission: 'あなたにこのユーザのロール変更権限はありません',
        errorColumnNameIsTooLong: function (columnName) { return "".concat(columnName, " \u306E\u6587\u5B57\u6570\u304C\u591A\u3059\u304E\u307E\u3059"); },
        errorColumnNameIsTooLongOrTooShort: function (columnName) { return "".concat(columnName, " \u304C\u9577\u3059\u304E\u308B\u304B\u77ED\u3059\u304E\u307E\u3059"); },
        errorConnectLINE: '該当のLINEアカウントは他のColorSingアカウントに連携済みです。',
        errorDeadlineExceeded: '通信に失敗しました。ネットワークが不安定な可能性があります。接続状況をご確認ください。',
        errorDownloadGift: function (errorMessage) { return "\u30AE\u30D5\u30C8\u306E\u30C0\u30A6\u30F3\u30ED\u30FC\u30C9\u306B\u5931\u6557\u3057\u307E\u3057\u305F\u3002".concat(errorMessage); },
        errorDownloadSong: '楽曲のダウンロードが完了できませんでした。もう一度お試しください（通信環境によっては何度か失敗することがございます）',
        errorDuplicated: '処理が二重になっています',
        errorDuplicatedAction: '既に同様の処理を行っています',
        errorEditProfile: function (errorMessage) { return "\u30D7\u30ED\u30D5\u30A3\u30FC\u30EB\u3092\u4FDD\u5B58\u3067\u304D\u307E\u305B\u3093\u3067\u3057\u305F\u3002".concat(errorMessage); },
        errorEnterLive: '配信の読み込みに失敗しました',
        errorFollow: function (name, errorMessage) { return "".concat(name, " \u3092\u30D5\u30A9\u30ED\u30FC\u3067\u304D\u307E\u305B\u3093\u3067\u3057\u305F\u3002").concat(errorMessage); },
        errorGetList: '取得に失敗しました。',
        errorGifting: function (errorMessage) { return "\u30AE\u30D5\u30C8\u306E\u9001\u4FE1\u306B\u5931\u6557\u3057\u307E\u3057\u305F\u3002".concat(errorMessage); },
        errorInvalidArgument: 'リクエストが不正です。',
        errorInvalidImage: '画像が重すぎるか形式が異なります',
        errorInvalidValue: '不正な値です',
        errorJoinPlan: 'プランに加入できない状態です',
        errorLivePreparation: '配信準備に失敗しました',
        errorLiveTitle: 'タイトルは20文字以内にしてください',
        errorLoginDeniedByDeviceMultipleUserRestriction: '1つの端末でログインできるアカウントは1つだけです。すでにこの端末で作成済のアカウントでログインしてください。',
        errorMultiDeviceViewOrLive: '複数の端末で同時に視聴または配信することはできません',
        errorMultiSnsToOneDevice: 'この端末で連携しているApple/Google/SNSアカウントでログインしてください。\n端末に紐付けられるApple/Google/SNSアカウントは一つまでです。',
        errorNetwork: '通信エラーが発生しました。',
        errorNetworkUnstabled: 'ネットワークが不安定なため配信が終了する恐れがあります。接続状況をご確認ください。',
        errorNoReport: '通報が存在しません',
        errorNotFound: 'データが見つかりませんでした。',
        errorPermissionDenied: '権限がありません。',
        errorProhibitComments: function (name, errorMessage) { return "".concat(name, " \u306E\u30B3\u30E1\u30F3\u30C8\u3092\u7981\u6B62\u3067\u304D\u307E\u305B\u3093\u3067\u3057\u305F\u3002").concat(errorMessage); },
        errorPurchaseCoin: function (errorMessage) { return "\u30B3\u30A4\u30F3\u306E\u8CFC\u5165\u306B\u5931\u6557\u3057\u307E\u3057\u305F\u3002".concat(errorMessage); },
        errorPurchaseNotAllowed: '購入が許可されていません。アカウントの設定をご確認ください。',
        errorPurchaseStoreProblem: 'アプリストアで問題が発生しました。時間を置いて再度お試しください。',
        errorPurchaseSyncProblem: 'ColorSingサーバーでエラーが発生しました。時間を置いてアプリを再起動してください。',
        errorRemoveSongFromFavorite: function (errorMessage) { return "\u30EC\u30D1\u30FC\u30C8\u30EA\u30FC\u304B\u3089\u524A\u9664\u3067\u304D\u307E\u305B\u3093\u3067\u3057\u305F\u3002".concat(errorMessage); },
        errorReportLengthLimit: function (minCount, maxCount) { return "".concat(minCount, "\u5B57\u4EE5\u4E0A").concat(maxCount, "\u5B57\u4EE5\u5185\u3067\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044"); },
        errorReportMaximumSize: 'ファイルが大きすぎます',
        errorReportNoInput: function (field) { return "".concat(field, "\u3092\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044"); },
        errorRetryLater: '時間を置いて再度お試しください。',
        errorSetClosingTime: 'そのDAYの締め時間は設定できません。',
        errorShareOnTwitter: function (errorMessage) { return "Twitter\u306B\u30B7\u30A7\u30A2\u3067\u304D\u307E\u305B\u3093\u3067\u3057\u305F\u3002".concat(errorMessage); },
        errorThisAccountAlreadyExists: 'このアカウントは既に登録済み、または別のユーザーに紐づけられています',
        errorTooMuch: '追加した数が大きすぎます',
        errorUnauthenticated: 'ログインが必要です。',
        errorUnblock: function (name, errorMessage) { return "".concat(name, " \u306E\u30D6\u30ED\u30C3\u30AF\u3092\u89E3\u9664\u3067\u304D\u307E\u305B\u3093\u3067\u3057\u305F\u3002").concat(errorMessage); },
        errorUnder17: 'お客様のご年齢ではご利用いただけません',
        errorUnexpected: '予期せぬエラーが発生しました。',
        errorUnfollow: function (name, errorMessage) { return "".concat(name, " \u306E\u30D5\u30A9\u30ED\u30FC\u3092\u89E3\u9664\u3067\u304D\u307E\u305B\u3093\u3067\u3057\u305F\u3002").concat(errorMessage); },
        errorUnprohibitComments: function (name, errorMessage) { return "".concat(name, " \u306E\u30B3\u30E1\u30F3\u30C8\u7981\u6B62\u3092\u89E3\u9664\u3067\u304D\u307E\u305B\u3093\u3067\u3057\u305F\u3002").concat(errorMessage); },
        errorUpdatePinnedComment: function (errorMessage) { return "\u56FA\u5B9A\u30B3\u30E1\u30F3\u30C8\u306E\u8A2D\u5B9A\u306B\u5931\u6557\u3057\u307E\u3057\u305F\u3002".concat(errorMessage); },
        errorUserFollowNotificationOff: function (name) { return "".concat(name, " \u306E\u901A\u77E5\u3092\u30AA\u30D5\u306B\u3067\u304D\u307E\u305B\u3093\u3067\u3057\u305F\u3002\u3057\u3070\u3089\u304F\u7D4C\u3063\u3066\u304B\u3089\u518D\u5EA6\u304A\u8A66\u3057\u304F\u3060\u3055\u3044"); },
        errorUserFollowNotificationOn: function (name) { return "".concat(name, " \u306E\u901A\u77E5\u3092\u30AA\u30F3\u306B\u3067\u304D\u307E\u305B\u3093\u3067\u3057\u305F\u3002\u3057\u3070\u3089\u304F\u7D4C\u3063\u3066\u304B\u3089\u518D\u5EA6\u304A\u8A66\u3057\u304F\u3060\u3055\u3044"); },
        errorWaitCommenting: 'コメントを送信中です。しばらくお待ち下さい',
        errorWaitGifting: 'ギフトを送信中です。しばらくお待ち下さい',
        errorWebLogin: 'あなたのアカウントは、LINEの連携がされていません。ログインには、アプリでLINE連携の設定が必要です。',
        errorWebLogin2: 'アプリで登録・連携しているSNSアカウントではないため、ログインできませんでした。アプリで連携しているSNSアカウントをご確認の上、そのSNSアカウントにて再度ログインをお願いします。',
        eternalBanNotice: function (target) { return "\u3010\u91CD\u8981\u3011\u3042\u306A\u305F\u306E".concat(target, "\u306B\u3064\u3044\u3066\u3001\u898F\u7D04\u9055\u53CD\u3092\u78BA\u8A8D\u3057\u307E\u3057\u305F\u306E\u3067\u30DA\u30CA\u30EB\u30C6\u30A3\u63AA\u7F6E\u3092\u884C\u3044\u307E\u3057\u305F\u3002\u7121\u671F\u9650\u306E\u6A5F\u80FD\u5236\u9650\u3092\u884C\u3044\u307E\u3059\u3002\u306A\u304A\u3001\u304A\u554F\u3044\u5408\u308F\u305B\u3092\u3044\u305F\u3060\u304D\u307E\u3057\u3066\u3082\u8A73\u7D30\u3092\u500B\u5225\u306B\u3054\u6848\u5185\u3059\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u305B\u3093\u3002\u3042\u3089\u304B\u3058\u3081\u3054\u4E86\u627F\u304F\u3060\u3055\u3044\u3002"); },
        event: 'イベント',
        eventCalendar: 'イベントカレンダー',
        eventInProgress: '開催中',
        eventOverview: 'イベント概要',
        eventRankCalculating: '計算中',
        eventRemainingDays: function (days) { return "\u3042\u3068".concat(days, "\u65E5"); },
        eventRemainingHours: function (hours) { return "\u3042\u3068".concat(hours, "\u6642\u9593"); },
        eventRemainingMinutes: function (minutes) { return "\u3042\u3068".concat(minutes, "\u5206"); },
        eventRemainingSeconds: function (seconds) { return "\u3042\u3068".concat(seconds, "\u79D2"); },
        eventStartFromDateTime: function (datetime) { return "".concat(datetime, "\u304B\u3089\u958B\u59CB"); },
        exceededMaxTransactionAmountForKonbiniPayment: 'コンビニ決済では1度に30万円以上の決済はできません',
        exchangeableDiamond: '交換可能なダイヤ',
        exchangeableDiamondThisTime: '今交換可能なダイヤ',
        exchangeableNumberCoin: '交換できるコイン数',
        exchangeableNumberDiamond: 'コイン交換',
        exchangeableNumberDiamondWeek: function (exchangeableNumberDiamondWeek) { return "".concat(exchangeableNumberDiamondWeek); },
        exchangeCoin: 'コインに交換',
        ExchangeDiamondToMoneyDetailDescription1: '支払額について、支払明細書発行後30日経過した日までに異議がない場合は支払額を確認したものとみなします。',
        exchangeHistory: '交換/換金履歴',
        exchangeMoney: '換金',
        exchangePrizeItem: '景品交換',
        exchangeToCoinAmount: 'コイン交換',
        execute: '実行',
        executeLeaveLiverStaffName: '（強制解除の場合）強制解除実行した人の名前',
        executePaymentConfirm: '支払いを実行します。よろしいですか？',
        expirationHasPassed: function (expires) { return "\u6709\u52B9\u671F\u9650\u304C\u904E\u304E\u3066\u3044\u307E\u3059\uFF08".concat(expires, "\u307E\u3067\u6709\u52B9\uFF09"); },
        explainHowLogin: 'アプリ版でログインしている方法かLINEログインしたアカウントでログインしてください。他のログイン方法を使用した場合はログインできません。',
        explainOrganizationlogin: 'BANされていない事務所オーナーもしくは事務所スタッフのみログインすることができます。',
        explainYoutubeViewing: '同時視聴ボタンを押すと、リスナー各自の端末がYouTubeに接続し（当社を経由せず）動画を再生します。ColorSingは対象動画の複製や公衆送信を行いません。',
        faildToastPlanDeleted: '[文言]プランの削除に失敗しました。',
        failureLogin: 'ログインに失敗しました。',
        fansInformation: '枠内情報',
        fansInformationAndFaveBadge: '枠内情報（バッジ・歌推し）',
        faq: 'よくある質問',
        faqOfGospel: '[FAQ] 福音について',
        faqOfMembership: '[FAQ] メンバーシップについて',
        faveBadge: '推しバッジ',
        faveBadge01K: '0.1K推しバッジ',
        faveBadge05K: '0.5K推しバッジ',
        faveBadge10K: '10K推しバッジ',
        faveBadge15K: '15K推しバッジ',
        faveBadge1K: '1K推しバッジ',
        faveBadge20K: '20K推しバッジ',
        faveBadge25K: '25K推しバッジ',
        faveBadge30K: '30K推しバッジ',
        faveBadge35K: '35K推しバッジ',
        faveBadge5K: '5K推しバッジ',
        faveBonus: '推しボーナス',
        faveBonusRate: '推しボーナスの倍率',
        faveBonusSpecific: function (faveBonus) { return "".concat(faveBonus, "%"); },
        favePoint: '推しPt',
        favePointSpecific: function (favePoint) { return "".concat(favePoint, "\u63A8\u3057Pt"); },
        favoriteArtists: '好きなアーティスト',
        favoriteSongAsListener: '推し曲',
        feedbackBox: 'ご意見ボックス (返信不要なもの)',
        fieldIsRequired: function (fieldName) { return "".concat(fieldName, "\u306F\u5FC5\u9808\u9805\u76EE\u3067\u3059"); },
        fieldMustBeGreaterOrLessThanOrEqualTo: function (fieldName, min, max) { return "".concat(fieldName, "\u306F").concat(min, "\u6587\u5B57\u4EE5\u4E0A").concat(max, "\u6587\u5B57\u4EE5\u5185\u306B\u3057\u3066\u304F\u3060\u3055\u3044"); },
        fieldMustBeLessThanOrEqualTo: function (fieldName, length) { return "".concat(fieldName, "\u306F").concat(length, "\u6587\u5B57\u4EE5\u5185\u306B\u3057\u3066\u304F\u3060\u3055\u3044"); },
        fieldMustBeValidValue: function (fieldName) { return "\u6B63\u3057\u3044".concat(fieldName, "\u3092\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044"); },
        filter: 'フィルター',
        finish: '終了',
        finishDay: '終了日',
        finishedStreaming: '配信終了',
        finishLive: '配信が終了しました',
        firstGettableAccumulatedDiamondDateDescription: function (date) { return "\u6761\u4EF6\u3092\u6E80\u305F\u3059\u3068\u7A4D\u307F\u7ACB\u3066\u30C0\u30A4\u30E4\u3092\u7372\u5F97\u3067\u304D\u308B\u65E5\u306F".concat(date, "\u4EE5\u964D\u3067\u3059"); },
        firstLook: '初見',
        firstTerm: '上旬',
        fix: '固定する',
        fixed: '固定中',
        flagWithFlagNames: function (flagNames) { return "\u30D5\u30E9\u30B0: ".concat(flagNames); },
        follow: 'フォロー',
        followers: 'フォロワー',
        following: 'フォロー中',
        followingBooster: '人気Lシンガーをフォロー',
        forAll: '全体',
        forceLeaveLiver: '強制解除',
        formathhm0: function (hour, minutes) { return "".concat(hour, "\u6642").concat(minutes, "\u5206\u53F0"); },
        formExchangePearlToAmazonGiftTickets: 'Amazonギフト券交換フォーム',
        formSelfApplyLSingerJudge: '審査申し込みフォーム',
        forYou: 'あなた',
        fundSettlementLaw: '資金決済法の表記',
        gender: '性別',
        genderAll: 'すべて',
        genderFemale: '女性',
        genderMale: '男性',
        genderSecret: '秘密・未選択',
        generation: '年代',
        getCoin: function (amount) { return "".concat(amount, "\u30B3\u30A4\u30F3\u7372\u5F97"); },
        getCoinByTap: 'タップでコインゲット',
        getCoinEn: function (amount) { return "".concat(amount, " COIN GET!"); },
        gettableAccumulatedDiamond: '今旬中に条件を満たすと獲得できる積み立てダイヤ',
        gettableContinuousRewardDiamond: '今旬中に条件を満たすと獲得できる継続報酬ダイヤ',
        gift: 'ギフト',
        giftAll: 'すべて',
        giftBoard: 'ギフトボード',
        giftCategory: 'カテゴリ',
        giftCategoryBow: '日常会話',
        giftCategoryEmotion: '感情表現',
        giftCategoryExcite: 'アゲ褒め',
        giftCategoryHappy: 'ハッピー',
        giftCategoryHeartful: 'ハートフル',
        giftCategoryPetit: 'プチ',
        giftCategoryReply: 'お返事',
        giftCategorySong: '歌',
        giftCategorySpecial: 'スペシャル',
        giftCategoryThxGratz: '祝&感謝',
        giftCommunication: 'コミュニケーション',
        giftDogAndCat: 'ワンテム・ニャンテム',
        giftEmotional: 'エモ歌喝采',
        giftHistory: '履歴',
        giftL: 'L',
        giftLatestHistory5: '最新履歴5',
        giftM: 'M',
        giftMonopatope: 'モノマトペ',
        giftNew: '新着',
        giftPopular: '人気',
        giftPopularML: '人気 (ML)',
        giftPopularS: '人気 (S)',
        giftS: 'S',
        giftSpecial: 'スペシャル',
        giftTabByKana: 'かな順',
        giftTabByPrice: '価格順',
        giftTabHome: 'HOME',
        giftTabNew: 'New',
        giftUseFrequentlyML: 'よく使う (ML)',
        giftUseFrequentlyS: 'よく使う (S)',
        goBackToTsunagu: 'つなぐに戻る',
        goodSingerDeviationBorder: function (value) { return "\u6B4C\u3046\u307E\u504F\u5DEE\u5024 ".concat(value, "~"); },
        googlePlay: 'Google Play',
        gospel: '福音',
        gotAccumulatedDiamondInThisTerm: '今旬獲得の積み立てダイヤ',
        gotContinuousRewardDiamondInThisTerm: '今旬獲得した継続報酬ダイヤ',
        goToSignInPage: 'ログイン画面へ',
        greatDemonGod: '大魔神',
        greatDemonLord: '大魔王',
        hashTagwword: function (name) { return "#ColorSing \u3067 ".concat(name, " \u304C #\u6B4C\u914D\u4FE1 \u4E2D!!"); },
        haveNumberCoin: '所持コイン',
        help: 'ヘルプ',
        here: 'こちら',
        hidden: '非公開',
        hideStaffList: '事務所詳細画面にスタッフ一覧を表示しない',
        hideThisPost: 'この投稿を非公開',
        historyApprovalOrRejectionPlanStatus: 'プラン加入承認拒否履歴',
        historyChangeStaffRole: 'スタッフロール変更履歴',
        historyOperation: '操作履歴',
        historyStaffOperation: 'スタッフ操作履歴',
        historyWithdrawPlan: 'プラン脱退履歴',
        homeTab: 'ホーム',
        honerOver10PerBonus: '名人',
        honerOver15PerBonus: '超人',
        honerOver20PerBonus: '魔人',
        honerOver25PerBonus: '魔王',
        honerOver5PerBonus: '玄人',
        honorByFaveBonus: '推しボーナス称号',
        hourly: '1時間',
        hourlyOfBanner: 'Hourly',
        hourUnitWithNumberForDiamond: function (number) { return "".concat(number, "\u6642\u9593\u5206"); },
        howManyCoinsLeft: function (coin) { return "\u3042\u3068".concat(coin, "\u30B3\u30A4\u30F3"); },
        howManyDaysLeft: function (day) { return "\u3042\u3068".concat(day, "\u65E5"); },
        howManyPeople: '人数',
        howManyPointLeftUpgrade: function (point) { return "\u3042\u3068".concat(point, "\u3067\u6607\u683C"); },
        howManyTimesLeft: function (h, m) { return "\u3042\u3068".concat(h, "\u6642\u9593").concat(m, "\u5206"); },
        ignore: '無視する',
        imformationOrganizationTab: '[文言]事務所情報',
        increaseAdjustmentYoutubeViewingDelay: '-',
        increaseMembershipQuantityThisTerm: '今期間のパスの買い増し',
        increaseMembershipQuantityThisTermOfLiver: function (liver) { return "".concat(liver, "\u306E\u4ECA\u671F\u9593\u306E\u30D1\u30B9\u306E\u8CB7\u3044\u5897\u3057"); },
        infoReactionGiftIsExpired: function (giftName) { return "\u300E".concat(giftName, "\u300F\u306F\u4F7F\u308F\u308C\u307E\u305B\u3093\u3067\u3057\u305F"); },
        initProfile: 'プロフィール登録',
        inputOrganizerName: '事務所名を入力',
        inSecondReview: '二次審査中',
        instagram: 'Instagram',
        interludeHowManySecondsLeft: function (seconds) { return "\u9593\u594F (\u3042\u3068".concat(seconds, "\u79D2)"); },
        introHowManySecondsLeft: function (seconds) { return "\u524D\u594F (\u3042\u3068".concat(seconds, "\u79D2)"); },
        invoiceBusinessNameShort: '請求者名',
        isHatsuPlusEnabled: '初＋が有効です',
        joinedPlan: '加入済みのプラン',
        joinMembershipOfLiver: function (name) { return "".concat(name, "\u306E\u30E1\u30F3\u30D0\u30FC\u30B7\u30C3\u30D7\u306B\u52A0\u5165"); },
        joinMembershipPaymentButtonText: function (listenerName, liverName) { return "\u26A0\u300E".concat(listenerName, "\u300F\u304C\u300E").concat(liverName, "\u300F\u306E\u30E1\u30F3\u30D0\u30FC\u30B7\u30C3\u30D7\u306B\u52A0\u5165\u3057\u307E\u3059\u3002\u4ED6\u306E\u65B9\u3078\u3053\u306EURL\u306E\u5171\u6709\u306F\u304A\u63A7\u3048\u304F\u3060\u3055\u3044\u3002\u89E3\u7D04\u30FB\u30AD\u30E3\u30F3\u30BB\u30EB\u306B\u95A2\u3057\u3066\u3001\u6B21\u56DE\u66F4\u65B0\u306F\u505C\u6B62(\u30AD\u30E3\u30F3\u30BB\u30EB)\u304C\u3067\u304D\u307E\u3059\u3002Web\u30ED\u30B0\u30A4\u30F3 \u2192 \u30E1\u30F3\u30D0\u30FC\u30B7\u30C3\u30D7\u30D1\u30B9\u306E\u7BA1\u7406 \u2192 \u30D7\u30E9\u30F3\u5909\u66F4\u304B\u3089\u3001\u6B21\u56DE\u66F4\u65B0\u306E\u505C\u6B62 (\u89E3\u7D04)\u3092\u5B9F\u884C\u3057\u3001\u6B21\u56DE\u66F4\u65B0\u6642\u671F\u3092\u3080\u304B\u3048\u305F\u3068\u304D\u306B\u3001\u6B21\u56DE\u66F4\u65B0\u306E\u505C\u6B62\u304C\u5B8C\u4E86\u3068\u306A\u308A\u307E\u3059\u3002\u307E\u305F\u3001\u6B21\u56DE\u66F4\u65B0\u65E5\u306E\u30BF\u30A4\u30DF\u30F3\u30B0\u3067\u3001\u9000\u4F1A\u7533\u8ACB\u4E2D\u307E\u305F\u306F\u9000\u4F1A\u6E08\u306E\u5834\u5408\u306F\u81EA\u52D5\u66F4\u65B0\u306F\u505C\u6B62\u3055\u308C\u307E\u3059\u3002\u306A\u304A\u3001\u6709\u52B9\u671F\u9593\u4E2D\u306B\u5F53\u8A72\u671F\u9593\u306E\u30E1\u30F3\u30D0\u30FC\u30B7\u30C3\u30D7\u3092\u9014\u4E2D\u3067\u30AD\u30E3\u30F3\u30BB\u30EB\u3059\u308B\u3053\u3068\u306F\u3067\u304D\u307E\u305B\u3093\u3002\u89E3\u7D04\u306B\u95A2\u3059\u308B\u304A\u554F\u3044\u5408\u308F\u305B\u306F [FAQ] \u304A\u554F\u3044\u5408\u308F\u305B -> \u304A\u554F\u3044\u5408\u308F\u305B\u30E1\u30FC\u30EB\u304B\u3089\u304A\u9858\u3044\u3044\u305F\u3057\u307E\u3059\u3002"); },
        joinNewMembership: '新しくメンバーシップに加入',
        joysound: 'JOYSOUND',
        jst12: '12時',
        jst15: '15時',
        jst18: '18時',
        jst20: '20時',
        jst21: '21時',
        jst22: '22時',
        jst23: '23時',
        jst24: '24時',
        jst9: '9時',
        karaoke: 'カラオケ',
        karaokeBgmVolume: 'BGM音量',
        karaokeBoostLocalMusicVolume: '自分だけBGM音量をかさ増しする',
        karaokeEarMonitor: '自分の声の返し',
        karaokeEcho: 'エコー',
        karaokeFavoriteSongByKana: '★ (かな順)',
        karaokeFavoriteSongByTimes: '★ (回数順)',
        karaokeGuideVolume: 'ガイド音量',
        karaokeHistory: '履歴',
        karaokeOwnListenerFavoriteSong: '自分の推し',
        karaokePopularSong: '人気',
        karaokePopularSongInApp: 'ここだけ',
        karaokeProvider: '音源提供',
        karaokeRequestedSong: '候補/リク',
        karaokeSetting: 'カラオケ設定',
        karaokeSingFromFirst: '最初から歌い直す',
        karaokeSoundKey: 'キー',
        karaokeSoundKeyWithOriginal: function (originalKey) { return "\u30AD\u30FC (\u539F\u66F2\u30AD\u30FC: ".concat(originalKey, ")"); },
        karaokeStopToSing: 'カラオケを終了',
        karaokeVocalVolume: 'ボーカル音量',
        kick: '外す',
        kickLiverFromPlan: 'Lシンガーのプランを強制解除する',
        kickStaff: 'スタッフから外す',
        largestEver: '過去最大',
        lastDay: '最終日',
        lastMonth: '前月',
        lastTerm: '前旬',
        league: 'リーグ',
        leagueUpHelpTextReasonCatchUpPreviousLeague: function (nowExp, requiredExp) { return "\u524D\u65EC\u30EA\u30FC\u30B0\u306B\u8FFD\u3044\u3064\u304F\u305F\u3081\u306B\u306F\u6607\u683C\u306B+2\u5FC5\u8981\u3067\u3001\u304B\u3064\u73FE\u5728\u7D4C\u9A13\u5024\u304C".concat(nowExp, "\u306E\u305F\u3081\u3001\u3042\u3068+").concat(requiredExp, "\u3067\u6607\u683C\u3068\u306A\u308A\u307E\u3059 (\u203B\u30EA\u30FC\u30B065\u672A\u6E80\u306E\u5834\u5408)"); },
        leagueUpHelpTextReasonOvertakePreviousLeague: function (nowExp, requiredExp) { return "\u524D\u65EC\u30EA\u30FC\u30B0\u3092\u8FFD\u3044\u8D8A\u3059\u305F\u3081\u306B\u306F\u6607\u683C\u306B+3\u5FC5\u8981\u3067\u3001\u304B\u3064\u73FE\u5728\u7D4C\u9A13\u5024\u304C".concat(nowExp, "\u306E\u305F\u3081\u3001\u3042\u3068+").concat(requiredExp, "\u3067\u6607\u683C\u3068\u306A\u308A\u307E\u3059 (\u203B\u30EA\u30FC\u30B065\u672A\u6E80\u306E\u5834\u5408)"); },
        leagueUpHelpTextReasonPlusCountInTermMoreThan8Times: function (nowExp, requiredExp) { return "\u4ECA\u65EC\u306B\u304A\u3044\u3066+1\u30928\u65E5\u4EE5\u4E0A\u7372\u5F97\u3057\u3066\u3044\u308B\u5834\u5408\u306B\u306F\u6607\u683C\u306B+3\u304C\u5FC5\u8981\u3067\u3001\u304B\u3064\u73FE\u5728\u7D4C\u9A13\u5024\u304C".concat(nowExp, "\u306E\u305F\u3081\u3001\u3042\u3068+").concat(requiredExp, "\u3067\u6607\u683C\u3068\u306A\u308A\u307E\u3059\u3002"); },
        leagueZoneCardinalDirection: '東西日本代表リーグ',
        leagueZoneCountry: '全日本代表リーグ',
        leagueZoneEnjoy: 'エンジョイリーグ',
        leagueZoneOni: '鬼リーグ',
        leagueZonePrefecture: '県代表リーグ',
        leagueZoneRegion: '地方代表リーグ',
        leagueZoneTown: '地区代表リーグ',
        letsPurchaseCoin: 'コインを購入してください',
        liftDeleteAccount: '退会を解除する',
        line: 'LINE',
        link: 'リンク',
        linkDeleteAccount: '失効するデータおよび削除 (退会) 時の注意事項について',
        linkDeleteAccountApple: '「Apple でサインイン」を使っている App を管理する',
        linkFAQCancelMenbershipt: '解約・内容変更の詳細ついてはこちら',
        linkFaveBonusTable: '推しボーナス獲得条件表はこちら',
        linkHome: 'HOME',
        linkLogout: 'ログアウト',
        linkUrl: 'URLリンク',
        linkWebLogin: 'LINE連携の方法が分からない方はこちら',
        listener: 'リスナー',
        listenerCountNow: 'オンライン視聴者数',
        listenerMembershipDescriptionOfDate: function (date) { return "\u6709\u52B9\u671F\u9650: ~".concat(date); },
        listenerMembershipDescriptionOfDiamonds: function (amount) { return "\u30C0\u30A4\u30E4: \u65EC\u306B".concat(amount); },
        listenerMembershipDescriptionOfFavePoints: function (favePoints) { return "\u63A8\u3057Pt: ".concat(favePoints, "\u306B\u98DB\u3073\u7D1A"); },
        listenerMembershipDescriptionOfGospelCoin: function (amount) { return "\u798F\u97F3: 1\u65E5\u3042\u305F\u308A".concat(amount, "\u30B3\u30A4\u30F3"); },
        listenerRequestedToSingTimeBefore: function (listener, time) { return "".concat(listener, "\u304C").concat(time, "\u524D\u306B\u63D0\u6848"); },
        live: 'ライブ',
        liveAlarmAdult: '【警告】わいせつな内容の配信は強制停止や機能制限をする場合がございます。ご注意ください。',
        liveAlarmAgeFalsification: '【警告】お客様が登録している年齢と実際の年齢が相違している可能性がございます。ライブストリーミング配信ガイドラインを再度ご確認ください。',
        liveAlarmBGM: '【お知らせ】配信内にてBGMの使用が確認されました。この通知は著作権者および著作隣接権者の許諾のない音源を使用されている可能性がある方へお送りしています。著作権者および著作隣接権者の許諾のない限り、市販のCD、DVD、ストリーミングサービス等の音源はご使用いただけません。',
        liveAlarmCommercialPurpose: '【警告】営利目的での配信は強制停止や機能制限をする場合がございます。ご注意ください。',
        liveAlarmCopyRight: '【警告】他者の権利を侵害している可能性がある配信は、強制停止や機能制限をする場合がございます。ご注意ください。',
        liveAlarmDangerousAction: '【警告】心身に影響が出る危険な行為や、けがをする可能性がある行為・危険な印象を与える配信は強制停止や機能制限をする場合がございます。ご注意ください。',
        liveAlarmDating: '【警告】個人情報の提示、出会い目的での利用は禁止しており、配信の強制停止や機能制限をする場合がございます。ご注意ください。',
        liveAlarmDefamation: '【警告】他人を誹謗･中傷・侮辱する内容、他人の名誉・信用を傷つける内容または荒らし行為等は禁止しており、配信の強制停止や機能制限をする場合がございます。ご注意ください。',
        liveAlarmDriving: '【警告】車両内からの配信を確認しました。配信者本人が運転者でなくとも、安全のため強制停止やご連絡をする場合がございます。ご注意ください。',
        liveAlarmIllegalKaraoke: '【警告】カラオケ音源をBGMとして使用することは推奨しておりません。今後繰り返し確認されると機能制限等の対象となる場合もございます。ぜひ歌ってください！',
        liveAlarmLiveStreamingGuideline: '【警告】ライブストリーミング配信ガイドラインに違反する行為は強制停止や機能制限をする場合がございます。ご注意ください。',
        liveAlarmMoral: '【警告】公序良俗に反する配信は強制停止や機能制限をする場合がございます。ご注意ください。',
        liveAlarmOther: '【警告】配信を停止いたしました。',
        liveAlarmSameTime: '【警告】同一内容・同時配信は禁止しており、強制停止や機能制限をする場合がございます。ご注意ください。',
        liveAlarmSilence: '【警告】コミュニケーションが成立していないと判断された配信 (放置・寝落ちなど) は強制停止や機能制限をする場合がございます。ご注意ください',
        liveAlarmSleep: '【警告】お疲れのようだったので配信停止いたしました。今後繰り返し確認されると機能制限等の対象となる場合もございます。ご注意ください。',
        liveAlarmTermsOfUse: '【警告】会員規約等に違反する行為は強制停止や機能制限をする場合がございます。ご注意ください。',
        liveAlarmUnder20: '【警告】20歳未満の飲酒および喫煙は法律で禁止されており、配信の強制停止や機能制限をする場合がございます。ご注意ください。',
        liveAuthorization: '配信認可',
        liveBanAdult: function (span) { return "\u3010\u8B66\u544A\u3011\u914D\u4FE1\u3092\u505C\u6B62\u3057\u307E\u3057\u305F\u3002".concat(span, "\u306E\u9593\u3001\u914D\u4FE1\u3092\u5236\u9650\u3057\u307E\u3059\u3002\u308F\u3044\u305B\u3064\u306A\u5185\u5BB9\u306F\u5F37\u5236\u505C\u6B62\u306E\u5BFE\u8C61\u306B\u306A\u308A\u307E\u3059\u3002"); },
        liveBanAgeFalsification: function (span) { return "\u3010\u8B66\u544A\u3011\u914D\u4FE1\u3092\u505C\u6B62\u3057\u307E\u3057\u305F\u3002".concat(span, "\u306E\u9593\u3001\u914D\u4FE1\u3092\u5236\u9650\u3057\u307E\u3059\u3002\u304A\u5BA2\u69D8\u304C\u767B\u9332\u3057\u3066\u3044\u308B\u5E74\u9F62\u3068\u5B9F\u969B\u306E\u5E74\u9F62\u304C\u76F8\u9055\u3057\u3066\u3044\u308B\u53EF\u80FD\u6027\u304C\u3042\u308B\u5834\u5408\u3001\u914D\u4FE1\u6A5F\u80FD\u3092\u5236\u9650\u3057\u307E\u3059\u3002\u30E9\u30A4\u30D6\u30B9\u30C8\u30EA\u30FC\u30DF\u30F3\u30B0\u914D\u4FE1\u30AC\u30A4\u30C9\u30E9\u30A4\u30F3\u3092\u518D\u5EA6\u3054\u78BA\u8A8D\u304F\u3060\u3055\u3044\u3002"); },
        liveBanCommercialPurpose: function (span) { return "\u3010\u8B66\u544A\u3011\u914D\u4FE1\u3092\u505C\u6B62\u3057\u307E\u3057\u305F\u3002".concat(span, "\u306E\u9593\u3001\u914D\u4FE1\u3092\u5236\u9650\u3057\u307E\u3059\u3002\u55B6\u5229\u76EE\u7684\u3067\u306E\u914D\u4FE1\u306F\u5F37\u5236\u505C\u6B62\u306E\u5BFE\u8C61\u306B\u306A\u308A\u307E\u3059\u3002"); },
        liveBanCopyRight: function (span) { return "\u3010\u8B66\u544A\u3011\u914D\u4FE1\u3092\u505C\u6B62\u3057\u307E\u3057\u305F\u3002".concat(span, "\u306E\u9593\u3001\u914D\u4FE1\u3092\u5236\u9650\u3057\u307E\u3059\u3002\u4ED6\u8005\u306E\u6A29\u5229\u3092\u4FB5\u5BB3\u3059\u308B\u914D\u4FE1\u306F\u5F37\u5236\u505C\u6B62\u306E\u5BFE\u8C61\u306B\u306A\u308A\u307E\u3059\u3002"); },
        liveBanDangerousAction: function (span) { return "\u3010\u8B66\u544A\u3011\u914D\u4FE1\u3092\u505C\u6B62\u3057\u307E\u3057\u305F\u3002".concat(span, "\u306E\u9593\u3001\u914D\u4FE1\u3092\u5236\u9650\u3057\u307E\u3059\u3002\u5371\u967A\u884C\u70BA\u306E\u53EF\u80FD\u6027\u306E\u3042\u308B\u914D\u4FE1\u306F\u5F37\u5236\u505C\u6B62\u306E\u5BFE\u8C61\u3068\u306A\u308A\u307E\u3059\u3002"); },
        liveBanDating: function (span) { return "\u3010\u8B66\u544A\u3011\u914D\u4FE1\u3092\u505C\u6B62\u3057\u307E\u3057\u305F\u3002".concat(span, "\u306E\u9593\u3001\u914D\u4FE1\u3092\u5236\u9650\u3057\u307E\u3059\u3002\u500B\u4EBA\u60C5\u5831\u306E\u63D0\u793A\u3001\u51FA\u4F1A\u3044\u76EE\u7684\u3067\u306E\u5229\u7528\u3001\u307E\u305F\u306F\u305D\u308C\u3089\u306E\u53EF\u80FD\u6027\u306E\u3042\u308B\u914D\u4FE1\u3092\u7981\u6B62\u3057\u3066\u3044\u307E\u3059\u3002\u5229\u7528\u898F\u7D04\u3092\u518D\u5EA6\u3054\u78BA\u8A8D\u304F\u3060\u3055\u3044\u3002"); },
        liveBanDefamation: function (span) { return "\u3010\u8B66\u544A\u3011\u914D\u4FE1\u3092\u505C\u6B62\u3057\u307E\u3057\u305F\u3002".concat(span, "\u306E\u9593\u3001\u914D\u4FE1\u3092\u5236\u9650\u3057\u307E\u3059\u3002\u4ED6\u4EBA\u3092\u8AB9\u8B17\uFF65\u4E2D\u50B7\u30FB\u4FAE\u8FB1\u3059\u308B\u5185\u5BB9\u3001\u4ED6\u4EBA\u306E\u540D\u8A89\u30FB\u4FE1\u7528\u3092\u50B7\u3064\u3051\u308B\u5185\u5BB9\u307E\u305F\u306F\u8352\u3089\u3057\u884C\u70BA\u7B49\u306B\u5F53\u305F\u308B\u53EF\u80FD\u6027\u306E\u3042\u308B\u914D\u4FE1\u306F\u5F37\u5236\u505C\u6B62\u306E\u5BFE\u8C61\u306B\u306A\u308A\u307E\u3059\u3002"); },
        liveBanDriving: function (span) { return "\u3010\u8B66\u544A\u3011\u914D\u4FE1\u3092\u505C\u6B62\u3057\u307E\u3057\u305F\u3002".concat(span, "\u306E\u9593\u3001\u914D\u4FE1\u3092\u5236\u9650\u3057\u307E\u3059\u3002\u8ECA\u4E21\u5185\u304B\u3089\u306E\u914D\u4FE1\u3092\u78BA\u8A8D\u3057\u307E\u3057\u305F\u3002\u914D\u4FE1\u8005\u672C\u4EBA\u304C\u904B\u8EE2\u8005\u3067\u306A\u304F\u3068\u3082\u3001\u5B89\u5168\u306E\u305F\u3081\u914D\u4FE1\u505C\u6B62\u3084\u3054\u9023\u7D61\u3092\u3059\u308B\u5834\u5408\u304C\u3054\u3056\u3044\u307E\u3059\u3002"); },
        liveBanLiveStreamingGuideline: function (span) { return "\u3010\u8B66\u544A\u3011\u914D\u4FE1\u3092\u505C\u6B62\u3057\u307E\u3057\u305F\u3002".concat(span, "\u306E\u9593\u3001\u914D\u4FE1\u3092\u5236\u9650\u3057\u307E\u3059\u3002\u30E9\u30A4\u30D6\u30B9\u30C8\u30EA\u30FC\u30DF\u30F3\u30B0\u914D\u4FE1\u30AC\u30A4\u30C9\u30E9\u30A4\u30F3\u306B\u9055\u53CD\u3059\u308B\u884C\u70BA\u306F\u5F37\u5236\u505C\u6B62\u306E\u5BFE\u8C61\u306B\u306A\u308A\u307E\u3059\u3002"); },
        liveBanMoral: function (span) { return "\u3010\u8B66\u544A\u3011\u914D\u4FE1\u3092\u505C\u6B62\u3057\u307E\u3057\u305F\u3002".concat(span, "\u306E\u9593\u3001\u914D\u4FE1\u3092\u5236\u9650\u3057\u307E\u3059\u3002\u516C\u5E8F\u826F\u4FD7\u306B\u53CD\u3059\u308B\u914D\u4FE1\u306F\u5F37\u5236\u505C\u6B62\u306E\u5BFE\u8C61\u306B\u306A\u308A\u307E\u3059\u3002"); },
        liveBanSameTime: function (span) { return "\u3010\u8B66\u544A\u3011\u914D\u4FE1\u3092\u505C\u6B62\u3057\u307E\u3057\u305F\u3002".concat(span, "\u306E\u9593\u3001\u914D\u4FE1\u3092\u5236\u9650\u3057\u307E\u3059\u3002\u540C\u4E00\u5185\u5BB9\u30FB\u540C\u6642\u914D\u4FE1\u306F\u5F37\u5236\u505C\u6B62\u306E\u5BFE\u8C61\u306B\u306A\u308A\u307E\u3059\u3002"); },
        liveBanSilence: function (span) { return "\u3010\u8B66\u544A\u3011\u914D\u4FE1\u3092\u505C\u6B62\u3057\u307E\u3057\u305F\u3002".concat(span, "\u306E\u9593\u3001\u914D\u4FE1\u3092\u5236\u9650\u3057\u307E\u3059\u3002\u30B3\u30DF\u30E5\u30CB\u30B1\u30FC\u30B7\u30E7\u30F3\u304C\u6210\u7ACB\u3057\u3066\u3044\u306A\u3044\u3068\u5224\u65AD\u3055\u308C\u305F\u914D\u4FE1 (\u653E\u7F6E\u30FB\u5BDD\u843D\u3061\u306A\u3069) \u306F\u5F37\u5236\u505C\u6B62\u306E\u5BFE\u8C61\u306B\u306A\u308A\u307E\u3059\u3002"); },
        liveBanTermsOfUse: function (span) { return "\u3010\u8B66\u544A\u3011\u914D\u4FE1\u3092\u505C\u6B62\u3057\u307E\u3057\u305F\u3002".concat(span, "\u306E\u9593\u3001\u914D\u4FE1\u3092\u5236\u9650\u3057\u307E\u3059\u3002\u4F1A\u54E1\u898F\u7D04\u7B49\u306B\u9055\u53CD\u3059\u308B\u884C\u70BA\u306F\u5F37\u5236\u505C\u6B62\u306E\u5BFE\u8C61\u306B\u306A\u308A\u307E\u3059\u3002"); },
        liveBanUnder20: function (span) { return "\u3010\u8B66\u544A\u3011\u914D\u4FE1\u3092\u505C\u6B62\u3057\u307E\u3057\u305F\u3002".concat(span, "\u306E\u9593\u3001\u914D\u4FE1\u3092\u5236\u9650\u3057\u307E\u3059\u300220\u6B73\u672A\u6E80\u306E\u98F2\u9152\u304A\u3088\u3073\u55AB\u7159\u306F\u6CD5\u5F8B\u3067\u7981\u6B62\u3055\u308C\u3066\u3044\u307E\u3059\u3002"); },
        liveDuration: function (hour, minute) { return "".concat(hour, "\u6642\u9593").concat(minute, "\u5206"); },
        liveForceStopAdult: '【警告】わいせつな内容を確認しましたので、配信を停止いたしました。今後繰り返し確認されると機能制限の対象となる場合もございます。ご注意ください。',
        liveForceStopCommercialPurpose: '【警告】営利目的の内容を確認しましたので、配信を停止いたしました。今後繰り返し確認されると機能制限の対象となる場合もございます。ご注意ください。',
        liveForceStopCopyRight: '【警告】他者の権利を侵害する内容を確認しましたので、配信を停止いたしました。今後繰り返し確認されると機能制限の対象となる場合もございます。ご注意ください。',
        liveForceStopDangerousAction: '【警告】危険行為を確認しましたので、配信を停止いたしました。今後繰り返し確認されると機能制限の対象となる場合もございます。ご注意ください。',
        liveForceStopDating: '【警告】個人情報の提示または出会い目的での利用を確認しましたので、配信を停止いたしました。今後繰り返し確認されると機能制限の対象となる場合もございます。ご注意ください。',
        liveForceStopDefamation: '【警告】他人を誹謗・中傷・侮辱する内容、他人の名誉・信用を傷つける内容または嵐行為、またはその可能性のあるものをを確認しましたので、配信を停止いたしました。今後繰り返し確認されると機能制限の対象となる場合もございます。ご注意ください。',
        liveForceStopDriving: '【警告】車内からの配信を確認しましたので、配信を停止いたしました。配信者本人が運転者でなくとも、安全のため、強制停止や機能制限の対象となる場合もございます。ご注意ください。',
        liveForceStopLiveStreamingGuideline: '【警告】ライブストリーミング配信ガイドラインに違反する行為を確認しましたので、配信を停止いたしました。今後繰り返し確認されると機能制限の対象となる場合もございます。ご注意ください。',
        liveForceStopMoral: '【警告】公序良俗に反する内容を確認しましたので、配信を停止いたしました。今後繰り返し確認されると機能制限の対象となる場合もございます。ご注意ください。',
        liveForceStopSameTime: '【警告】同一内容・同時配信を確認しましたので、配信を停止いたしました。今後繰り返し確認されると機能制限の対象となる場合もございます。ご注意ください。',
        liveForceStopTermsOfUse: '【警告】会員規約に違反する行為を確認しましたので、配信を停止いたしました。今後繰り返し確認されると機能制限の対象となる場合もございます。ご注意ください。',
        liveForceStopUnder20: '【警告】20歳未満の飲酒および喫煙を確認しましたので、配信を停止いたしました。今後繰り返し確認されると機能制限の対象となる場合もございます。ご注意ください。',
        liveGiftHistory: '配信でギフトを使ってもらった履歴',
        liveGuideline: '配信ガイドライン',
        liveInRealJoysound: '店舗で顔出し配信して分析採点AIの結果を映す',
        liveIsLoading: '配信を読み込み中です',
        liveMonitoring: 'アサイン制ライブ監視',
        liveNewsBeforeLiveEnd: function (min) { return "\u914D\u4FE1\u7D42\u4E86\u306E".concat(min, "\u5206\u524D\u306B\u306A\u308A\u307E\u3057\u305F\u3002"); },
        liveNewsForAll: 'ライブニュース一括送信',
        liveOnlyVoice: '声だけ配信中',
        liveOrView: '配信・視聴',
        livePoint: '応援スコア',
        liver: 'Lシンガー',
        liverLeaveCategory: '退会カテゴリー',
        liverLeaveHistory: 'Lシンガー退会履歴',
        liverReport: 'Lシンガー統計情報',
        liverSelfLeave: '自ら退会',
        liveStartTime: '配信開始時刻',
        liveStatus: '配信状態',
        liveStreaming: '配信',
        liveTerms: 'Lシンガー規約',
        liveTime: '配信時間',
        liveTitle: '配信タイトル',
        liveUnsupportedOnAndroidDescription: 'Androidの配信機能は現在提供しておりません。今後のリリースをお待ちください。',
        liveViewing: '視聴',
        liveVisibilitySetting: '表示項目の設定',
        loadingMusic: '楽曲の読み込み中です',
        logDetailLiveBan: function (violationCategory) { return "\u30AB\u30C6\u30B4\u30EA: ".concat(violationCategory, " "); },
        logDetailUserBan: function (lv, target, violationCategory) { return "LV: ".concat(lv, "\n\u5BFE\u8C61: ").concat(target, "\n\u30AB\u30C6\u30B4\u30EA: ").concat(violationCategory); },
        logDetailUserFlag: function (key, onOrOff) { return "\u30D5\u30E9\u30B0: ".concat(key, " \n\u72B6\u614B: ").concat(onOrOff); },
        logDetailUserMask: function (target, violationCategory) { return "\u5BFE\u8C61: ".concat(target, " \n\u30AB\u30C6\u30B4\u30EA: ").concat(violationCategory, " "); },
        login: 'ログイン',
        loginBonus: 'ログインボーナス',
        loginBonusGettableDailySpan: '本日分',
        loginBonusGettableEveningSpan: '夕方: 16時~21時',
        loginBonusGettableMorningSpan: '朝: 5時~11時',
        loginBonusGettableNightSpan: '夜: 21時~24時',
        loginBonusGettableNoonSpan: '昼: 11時~16時',
        loginBonusWithCoin: function (coin) { return "\u30ED\u30B0\u30A4\u30F3\u30DC\u30FC\u30CA\u30B9".concat(coin, "\u30B3\u30A4\u30F3"); },
        logout: 'ログアウト',
        lsingerGroupEdit: function (name) { return "[\u6587\u8A00]".concat(name, "\u306E\u7DE8\u96C6"); },
        lsingerGroupManagements: 'Lシンガーグループ管理',
        lsingerWithdraw: function (LsingerName, organizerName) { return "".concat(LsingerName, "\u304C").concat(organizerName, " \u306E\u30B5\u30DD\u30FC\u30C8\u30D7\u30E9\u30F3\u3092\u9000\u4F1A\u3057\u307E\u3057\u305F"); },
        magnification: '倍率',
        maintenance: 'メンテナンス',
        makeAllChecked: 'すべてチェック済にする',
        makeChecked: 'チェック済にする',
        makeEditOrganizer: '[文言]事務所を開設・編集',
        makeLyricInvisible: '歌詞を非表示',
        makeLyricVisible: '歌詞を表示',
        makeNotificationOff: '通知をオフ',
        makeNotificationOn: '通知をオン',
        makePeriodicPickingSongUpRandomlyOff: '定期的な選曲PickUpをオフ',
        makePeriodicPickingSongUpRandomlyOn: '定期的な選曲PickUpをオン',
        manageGroupePage: '[文言]グループ管理',
        manual: '手動',
        maskAdult: function (target) { return "\u3010\u8B66\u544A\u3011".concat(target, "\u306B\u308F\u3044\u305B\u3064\u306A\u5185\u5BB9\u306E\u53EF\u80FD\u6027\u304C\u3042\u308B\u6295\u7A3F\u304C\u78BA\u8A8D\u3055\u308C\u305F\u305F\u3081\u3001\u975E\u8868\u793A\u3068\u3057\u3066\u304A\u308A\u307E\u3059\u3002\u6A5F\u80FD\u5236\u9650\u7B49\u306E\u5BFE\u8C61\u3068\u306A\u308B\u5834\u5408\u3082\u3054\u3056\u3044\u307E\u3059\u306E\u3067\u3054\u6CE8\u610F\u304F\u3060\u3055\u3044\u3002"); },
        maskAgeFalsification: function (target) { return "\u3010\u8B66\u544A\u3011".concat(target, "\u306B\u304A\u5BA2\u69D8\u304C\u767B\u9332\u3057\u3066\u3044\u308B\u5E74\u9F62\u3068\u5B9F\u969B\u306E\u5E74\u9F62\u304C\u76F8\u9055\u3057\u3066\u3044\u308B\u6295\u7A3F\u304C\u78BA\u8A8D\u3055\u308C\u305F\u305F\u3081\u3001\u975E\u8868\u793A\u3068\u3057\u3066\u304A\u308A\u307E\u3059\u3002\u6A5F\u80FD\u5236\u9650\u7B49\u306E\u5BFE\u8C61\u3068\u306A\u308B\u5834\u5408\u3082\u3054\u3056\u3044\u307E\u3059\u306E\u3067\u3054\u6CE8\u610F\u304F\u3060\u3055\u3044\u3002"); },
        maskCategory: 'マスク理由',
        maskCommercialPurpose: function (target) { return "\u3010\u8B66\u544A\u3011".concat(target, "\u306B\u55B6\u5229\u76EE\u7684\u306E\u53EF\u80FD\u6027\u304C\u3042\u308B\u6295\u7A3F\u304C\u78BA\u8A8D\u3055\u308C\u305F\u305F\u3081\u3001\u975E\u8868\u793A\u3068\u3057\u3066\u304A\u308A\u307E\u3059\u3002\u6A5F\u80FD\u5236\u9650\u7B49\u306E\u5BFE\u8C61\u3068\u306A\u308B\u5834\u5408\u3082\u3054\u3056\u3044\u307E\u3059\u306E\u3067\u3054\u6CE8\u610F\u304F\u3060\u3055\u3044\u3002"); },
        maskCopyRight: function (target) { return "\u3010\u8B66\u544A\u3011".concat(target, "\u306B\u4ED6\u8005\u306E\u6A29\u5229\u3092\u4FB5\u5BB3\u3059\u308B\u53EF\u80FD\u6027\u304C\u3042\u308B\u6295\u7A3F\u304C\u78BA\u8A8D\u3055\u308C\u305F\u305F\u3081\u3001\u975E\u8868\u793A\u3068\u3057\u3066\u304A\u308A\u307E\u3059\u3002\u6A5F\u80FD\u5236\u9650\u7B49\u306E\u5BFE\u8C61\u3068\u306A\u308B\u5834\u5408\u3082\u3054\u3056\u3044\u307E\u3059\u306E\u3067\u3054\u6CE8\u610F\u304F\u3060\u3055\u3044\u3002"); },
        maskDangerousAction: function (target) { return "\u3010\u8B66\u544A\u3011".concat(target, "\u306B\u5371\u967A\u306A\u884C\u70BA\u306E\u53EF\u80FD\u6027\u304C\u3042\u308B\u6295\u7A3F\u304C\u78BA\u8A8D\u3055\u308C\u305F\u305F\u3081\u3001\u975E\u8868\u793A\u3068\u3057\u3066\u304A\u308A\u307E\u3059\u3002\u6A5F\u80FD\u5236\u9650\u7B49\u306E\u5BFE\u8C61\u3068\u306A\u308B\u5834\u5408\u3082\u3054\u3056\u3044\u307E\u3059\u306E\u3067\u3054\u6CE8\u610F\u304F\u3060\u3055\u3044\u3002"); },
        maskDating: function (target) { return "\u3010\u8B66\u544A\u3011".concat(target, "\u306B\u500B\u4EBA\u60C5\u5831\u307E\u305F\u306F\u51FA\u4F1A\u3044\u76EE\u7684\u306E\u53EF\u80FD\u6027\u304C\u3042\u308B\u6295\u7A3F\u304C\u78BA\u8A8D\u3055\u308C\u305F\u305F\u3081\u3001\u975E\u8868\u793A\u3068\u3057\u3066\u304A\u308A\u307E\u3059\u3002\u30A2\u30D7\u30EA\u5916\u306E\u30C8\u30E9\u30D6\u30EB\u306B\u3064\u306A\u304C\u308B\u3088\u3046\u306A\u6295\u7A3F\u306F\u7981\u6B62\u3055\u308C\u3066\u304A\u308A\u307E\u3059\u3002\u6A5F\u80FD\u5236\u9650\u7B49\u306E\u5BFE\u8C61\u3068\u306A\u308B\u5834\u5408\u3082\u3054\u3056\u3044\u307E\u3059\u306E\u3067\u3054\u6CE8\u610F\u304F\u3060\u3055\u3044\u3002"); },
        maskDefamation: function (target) { return "\u3010\u8B66\u544A\u3011".concat(target, "\u306B\u4ED6\u4EBA\u3092\u8AB9\u8B17\uFF65\u4E2D\u50B7\u30FB\u4FAE\u8FB1\u3059\u308B\u5185\u5BB9\u3001\u4ED6\u4EBA\u306E\u540D\u8A89\u30FB\u4FE1\u7528\u3092\u50B7\u3064\u3051\u308B\u5185\u5BB9\u307E\u305F\u306F\u8352\u3089\u3057\u884C\u70BA\u7B49\u306B\u5F53\u305F\u308B\u53EF\u80FD\u6027\u304C\u3042\u308B\u6295\u7A3F\u304C\u78BA\u8A8D\u3055\u308C\u305F\u305F\u3081\u3001\u975E\u8868\u793A\u3068\u3057\u3066\u304A\u308A\u307E\u3059\u3002\u6A5F\u80FD\u5236\u9650\u7B49\u306E\u5BFE\u8C61\u3068\u306A\u308B\u5834\u5408\u3082\u3054\u3056\u3044\u307E\u3059\u306E\u3067\u3054\u6CE8\u610F\u304F\u3060\u3055\u3044\u3002"); },
        maskMenu: 'マスクメニュー',
        maskMoral: function (target) { return "\u3010\u8B66\u544A\u3011".concat(target, "\u306B\u516C\u5E8F\u826F\u4FD7\u306B\u53CD\u3059\u308B\u53EF\u80FD\u6027\u304C\u3042\u308B\u6295\u7A3F\u304C\u78BA\u8A8D\u3055\u308C\u305F\u305F\u3081\u3001\u975E\u8868\u793A\u3068\u3057\u3066\u304A\u308A\u307E\u3059\u3002\u6A5F\u80FD\u5236\u9650\u7B49\u306E\u5BFE\u8C61\u3068\u306A\u308B\u5834\u5408\u3082\u3054\u3056\u3044\u307E\u3059\u306E\u3067\u3054\u6CE8\u610F\u304F\u3060\u3055\u3044\u3002"); },
        maskTarget: 'マスク対象',
        maskTermsOfUse: function (target) { return "\u3010\u8B66\u544A\u3011".concat(target, "\u306B\u898F\u7D04\u9055\u53CD\u304C\u78BA\u8A8D\u3055\u308C\u305F\u305F\u305F\u3081\u3001\u975E\u8868\u793A\u3068\u3057\u3066\u304A\u308A\u307E\u3059\u3002\u6A5F\u80FD\u5236\u9650\u7B49\u306E\u5BFE\u8C61\u3068\u306A\u308B\u5834\u5408\u3082\u3054\u3056\u3044\u307E\u3059\u306E\u3067\u3054\u6CE8\u610F\u304F\u3060\u3055\u3044\u3002"); },
        maskUnder20: function (target) { return "\u3010\u8B66\u544A\u3011".concat(target, "\u306B20\u6B73\u672A\u6E80\u306E\u98F2\u9152\u304A\u3088\u3073\u55AB\u7159\u306E\u53EF\u80FD\u6027\u304C\u3042\u308B\u6295\u7A3F\u304C\u78BA\u8A8D\u3055\u308C\u305F\u305F\u3081\u3001\u975E\u8868\u793A\u3068\u3057\u3066\u304A\u308A\u307E\u3059\u3002\u6A5F\u80FD\u5236\u9650\u7B49\u306E\u5BFE\u8C61\u3068\u306A\u308B\u5834\u5408\u3082\u3054\u3056\u3044\u307E\u3059\u306E\u3067\u3054\u6CE8\u610F\u304F\u3060\u3055\u3044\u3002"); },
        masterDataList: '各種マスタ',
        max: 'MAX',
        membership: 'メンバーシップ',
        membershipAgreement: '会員共通規約',
        membershipDiamond: 'メンバーシップダイヤ',
        membershipJoinedPfCommentForListener: function (favePoint, gospelCoin) { return "\u30E1\u30F3\u30D0\u30FC\u30B7\u30C3\u30D7\u306B\u52A0\u5165\uFF01\u7279\u5178\u3092\u7372\u5F97\uD83C\uDF89\n\u63A8\u3057Pt: ".concat(favePoint, " / \uD83E\uDEBD\u798F\u97F3: \u6BCE\u65E5").concat(gospelCoin, "\u30B3\u30A4\u30F3\u5206"); },
        membershipJoinedPfCommentForLiver: function (favePoint, gospelCoin, diamond) { return "\u30E1\u30F3\u30D0\u30FC\u30B7\u30C3\u30D7\u306B\u52A0\u5165\uFF01\u7279\u5178\u3092\u7372\u5F97\uD83C\uDF89\n\u63A8\u3057Pt: ".concat(favePoint, " / \uD83E\uDEBD\u798F\u97F3: \u6BCE\u65E5").concat(gospelCoin, "\u30B3\u30A4\u30F3\u5206 / \uD83D\uDC8E\u30C0\u30A4\u30E4: ").concat(diamond, " (\u67A0\u4E3B\u306E\u307F\u30FB\u65EC\u306F\u3058\u3081)"); },
        membershipListenersInfoWithCount: function (count) { return "\u30E1\u30F3\u30D0\u30FC\u30B7\u30C3\u30D7\u60C5\u5831\uFF08".concat(count, "\u4EBA\uFF09"); },
        membershipPaymentDescription: '※ 決済方法はクレジットカードです。決済額は当日含む日割計算となります。効果 (コイン数等)に関しても日割相当の値となります。次回以降の更新は毎月26日に行われます。解約に関して：次回更新日までにキャンセルする必要があります。',
        membershipPlanName: function (months) { return "".concat(months, "\u30F6\u6708\u30D1\u30B9"); },
        membershipQuantityWillBeChangedToQuantityFromDate: function (date, quantity) { return "".concat(date, "\u304B\u3089\u306F").concat(quantity, "\u679A\u306B\u306A\u308A\u307E\u3059"); },
        membershipRenewedPfCommentForListener: function (favePoint, gospelCoin) { return "\u30E1\u30F3\u30D0\u30FC\u30B7\u30C3\u30D7\u3092\u7D99\u7D9A\uFF01\u7279\u5178\u3092\u7372\u5F97\uD83C\uDF89\n\u63A8\u3057Pt: ".concat(favePoint, " / \uD83E\uDEBD\u798F\u97F3: \u6BCE\u65E5").concat(gospelCoin, "\u30B3\u30A4\u30F3\u5206"); },
        membershipRenewedPfCommentForLiver: function (favePoint, gospelCoin, diamond) { return "\u30E1\u30F3\u30D0\u30FC\u30B7\u30C3\u30D7\u3092\u7D99\u7D9A\uFF01\u7279\u5178\u3092\u7372\u5F97\uD83C\uDF89\n\u63A8\u3057Pt: ".concat(favePoint, " / \uD83E\uDEBD\u798F\u97F3: \u6BCE\u65E5").concat(gospelCoin, "\u30B3\u30A4\u30F3\u5206 / \uD83D\uDC8E\u30C0\u30A4\u30E4: ").concat(diamond, " (\u67A0\u4E3B\u30FB\u65EC\u306F\u3058\u3081)"); },
        membershipStripeProductName: function (liverName, month) { return "".concat(liverName, "\u306E").concat(month, "\u30F6\u6708\u30E1\u30F3\u30D0\u30FC\u30B7\u30C3\u30D7\u30D1\u30B9"); },
        membershipTypeWithQuantity: function (month, quantity) { return "".concat(month, "\u30F6\u6708\u578B").concat(quantity, "\u53E3\u5206"); },
        mentionToAdministrator: '@運営 ',
        menu: 'メニュー',
        messageFromSinger: '枠主からのメッセージ',
        moderatorBanListenerReqCapture: function (moderator, listener) { return "\u30E2\u30C7\u30EC\u30FC\u30BF\u30FC\u306E".concat(moderator, "\u304C").concat(listener, "\u306E\u30EA\u30AF\u30A8\u30B9\u30C8\u3092\u7981\u6B62\u3057\u307E\u3057\u305F"); },
        moderatorBannedListenerComment: function (moderator, listener) { return "\u30E2\u30C7\u30EC\u30FC\u30BF\u30FC\u306E".concat(moderator, "\u304C").concat(listener, "\u306E\u30B3\u30E1\u30F3\u30C8\u3092\u7981\u6B62\u3057\u307E\u3057\u305F"); },
        moderatorDoBlockListener: function (moderator, listener) { return "\u30E2\u30C7\u30EC\u30FC\u30BF\u30FC\u306E".concat(moderator, "\u304C").concat(listener, "\u3092\u67A0\u4E3B\u306E\u304B\u308F\u308A\u306B\u4EE3\u7406\u30D6\u30ED\u30C3\u30AF\u3057\u307E\u3057\u305F"); },
        moderatorList: 'モデレーターの一覧',
        moderatorsTermOfOfficeUntilDate: function (yearMonthDay) { return "\u4EFB\u671F\uFF1A".concat(yearMonthDay, "\u307E\u3067"); },
        moderatorUnBanListenerComment: function (moderator, listener) { return "\u30E2\u30C7\u30EC\u30FC\u30BF\u30FC\u306E".concat(moderator, "\u304C").concat(listener, "\u306E\u30B3\u30E1\u30F3\u30C8\u7981\u6B62\u3092\u89E3\u9664\u3057\u307E\u3057\u305F"); },
        moderatorUnBanListenerReqCapture: function (moderator, listener) { return "\u30E2\u30C7\u30EC\u30FC\u30BF\u30FC\u306E".concat(moderator, "\u304C").concat(listener, "\u306E\u30EA\u30AF\u30A8\u30B9\u30C8\u7981\u6B62\u3092\u89E3\u9664\u3057\u307E\u3057\u305F"); },
        moderatorUnBlockListener: function (moderator, listener) { return "\u30E2\u30C7\u30EC\u30FC\u30BF\u30FC\u306E".concat(moderator, "\u304C").concat(listener, "\u3092\u67A0\u4E3B\u306E\u304B\u308F\u308A\u306B\u4EE3\u7406\u30D6\u30ED\u30C3\u30AF\u89E3\u9664\u3057\u307E\u3057\u305F"); },
        month: '月',
        monthAndTerm: function (month, term) { return "".concat(month, "\u6708").concat(term); },
        monthAndTermAndDayOfTerm: function (month, term, dayOfTerm) { return "".concat(month, "\u6708").concat(term, "DAY").concat(dayOfTerm); },
        monthDuration: function (month) { return "".concat(month, "\u30F6\u6708"); },
        monthlyReceivedFavePoints: '枠内月間推しPt',
        monthSpecific: function (month) { return "".concat(month, "\u6708"); },
        moreThanOrEqualToLiveScore: function (liveScore) { return "\uD83D\uDD25".concat(liveScore, "\u4EE5\u4E0A"); },
        myMembershipListeners: '自枠のメンバーシップ加入者一覧',
        myMembershipListenersInfo: '自枠のメンバーシップ情報',
        myPearl: '所持パール',
        myPearlInfo: '所持パール情報',
        myTown: 'マイタウン',
        myTownSecret: '秘密・未選択',
        name: '名前',
        nComments: function (number) { return "".concat(number, "\u4EF6"); },
        neverShowStoreReviewAgain: function (here) { return "\u65E2\u306B\u2605\u3092\u3064\u3051\u305F\u5834\u5408\u3001\u3053\u308C\u4EE5\u4E0A\u3053\u306E\u30E1\u30C3\u30BB\u30FC\u30B8\u3092\u8868\u793A\u3057\u305F\u304F\u306A\u3044\u5834\u5408\u306F".concat(here, "\u3092\u30BF\u30C3\u30D7\u3057\u3066\u304F\u3060\u3055\u3044"); },
        next: '次へ',
        nextDayWithDayOfTerm: function (month, term, dayOfTerm) { return "NEXT DAY: ".concat(month, "\u6708").concat(term, "\u306EDAY").concat(dayOfTerm); },
        nextFavePoint: 'NEXT',
        nextFavePointSpecific: function (favePoint) { return "Next ".concat(favePoint); },
        nextGettableContinuousRewardDiamondDateDescription: function (date) { return "\u7D99\u7D9A\u5831\u916C\u30C0\u30A4\u30E4\u3092\u7372\u5F97\u3067\u304D\u308B\u65E5\u306F".concat(date, "\u4EE5\u964D\u3067\u3059"); },
        nextPlan: '次回プラン',
        nextRenewalDate: '次回更新日',
        nextRenewalPrice: '次回更新料',
        nextSongFaveSpecific: function (songFave) { return "Next ".concat(songFave, "\u4EBA"); },
        nickname: 'ニックネーム',
        no: 'いいえ',
        noAvailablePlanForJoinMembership: '新たに加入できるメンバーシップはありません',
        none: 'なし',
        noRenewal: 'なし（解約）',
        normalDiamond: '通常ダイヤ',
        notAutoDelete: '自動削除なし',
        notConfigured: '設定されていません',
        noteDiamondReport: '※ 現在のダイヤについてはユーザ統計、事務所ダイヤについては事務所管理画面からご覧ください。',
        notEnoughCoin: 'コインが足りません',
        noteSupportPlanNecessity: '※ 事務所を開設するにはサポートプランも同時に登録する必要があります。サポートプランはあとで追加・編集することが出来ます。',
        noteWebLogin: '個人のSNSに許可なく投稿されることはありません　',
        noteYouCanAdd10Artists: '※ 10件まで追加できます。',
        noticeAgeFalsification: 'お客様が登録している年齢と実際の年齢が相違している可能性があるため、機能を制限中です。',
        notification: '通知',
        notificationAddSongToFavorite: 'レパートリーに追加しました',
        notificationApplicationBecomeStaff: function (name) { return "".concat(name, " \u304B\u3089\u30B9\u30BF\u30C3\u30D5\u5316\u7533\u8ACB\u304C\u3042\u308A\u307E\u3057\u305F"); },
        notificationApplicationChangePlan: function (name, beforePlanName, afterPlanName) { return "".concat(name, " \u304B\u3089 ").concat(beforePlanName, " \u2192 ").concat(afterPlanName, "  \u306B\u30D7\u30E9\u30F3\u5909\u66F4\u7533\u8ACB\u304C\u3042\u308A\u307E\u3057\u305F"); },
        notificationApplicationJoinPlan: function (name, planName) { return "".concat(name, " \u304B\u3089 ").concat(planName, " \u306B\u30D7\u30E9\u30F3\u52A0\u5165\u7533\u8ACB\u304C\u3042\u308A\u307E\u3057\u305F"); },
        notificationApprovalStaff: function (organizerName) { return "".concat(organizerName, " \u306E\u30B9\u30BF\u30C3\u30D5\u306B\u306A\u308A\u307E\u3057\u305F"); },
        notificationApproveApplication: '申請を承認しました',
        notificationApproveBankAccountReview: '審査が完了し、ダイヤの換金が可能になりました',
        notificationApproveJoinPlan: function (organizerName, planName) { return "".concat(organizerName, ": ").concat(planName, " \u306E\u30D7\u30E9\u30F3\u52A0\u5165\u304C\u627F\u8A8D\u3055\u308C\u307E\u3057\u305F"); },
        notificationBlock: function (name) { return "".concat(name, " \u3092\u30D6\u30ED\u30C3\u30AF\u3057\u307E\u3057\u305F"); },
        notificationCancelApplicationChangePlan: '変更申請を取り消しました',
        notificationCancelLiverApplication: '加入申請を取り消しました',
        notificationCannotRecordScreenBeforeEnterLive: '入室前は画面録画はできません',
        notificationCannotRecordScreenLackOfFavePoints: '画面録画できる条件を満たしていません（前月・今月のどちらかで5K以上が必要）',
        notificationCannotRecordScreenProhibitedByLiver: 'リク・画面録画禁止中です',
        notificationChangeLiverApplication: 'プランの変更を申請しました',
        notificationChangeLiveTitle: '配信のタイトルを変更しました',
        notificationChangeMoney: '[文言]換金申請は、10000ダイヤ以上から申請可能です。',
        notificationChangeOrganizerInformation: '事務所情報を変更しました ',
        notificationChangeRole: function (beforeOrganizerRole, afterOrganizerRole) { return "\u4E8B\u52D9\u6240\u30ED\u30FC\u30EB\u304C ".concat(beforeOrganizerRole, " \u304B\u3089 ").concat(afterOrganizerRole, " \u306B\u306A\u308A\u307E\u3057\u305F"); },
        notificationChangeStaffRole: 'ロールを変更しました',
        notificationChangeSupportPlan: 'サポートプランの内容が変更されました',
        notificationCoinGotWithMethodName: function (method, coinAmount) { return "".concat(method, "\u306B\u3088\u3063\u3066").concat(coinAmount, "\u30B3\u30A4\u30F3\u3092\u7372\u5F97\u3057\u307E\u3057\u305F"); },
        notificationCompleteTransfer: '振り込みが完了しました',
        notificationCreateLiverApplication: 'プランへの加入を申請しました',
        notificationCreateStaffApplication: 'スタッフ化申請をしました',
        notificationCreateSupportPlan: 'サポートプランが新規作成されました',
        notificationCutdecimalpoint: '[文言]※小数点が発生した場合は切り捨てられます。',
        notificationDoBlockByModerator: function (listener) { return "".concat(listener, "\u3092\u4EE3\u7406\u30D6\u30ED\u30C3\u30AF\u3057\u307E\u3057\u305F"); },
        notificationDoneCancelMembershipSubscription: '次回更新を停止（解約）しました',
        notificationDoneCancelUnsubscribingMembership: '次回更新の停止（解約）を取り消しました',
        notificationEditProfile: 'プロフィールを編集しました',
        notificationEntryDone: function (name) { return "".concat(name, "\u3067\u30A8\u30F3\u30C8\u30EA\u30FC\u3057\u307E\u3057\u305F"); },
        notificationExchange: 'ダイヤはコインに交換、もしくは換金できます。',
        notificationExchangeCoin: function (notificationExchangeCoin) { return "".concat(notificationExchangeCoin, "\u30B3\u30A4\u30F3\u3068\u4EA4\u63DB\u3057\u307E\u3057\u305F"); },
        notificationExpiry: 'ダイヤの有効期限はダイヤの使用、もしくは取得した日から180日間です。有効期限を経過した場合、未使用のダイヤは消滅しますので、ご注意ください。',
        notificationFailedCancelMembershipSubscription: '次回更新の停止（解約）に失敗しました',
        notificationFailedCancelUnsubscribingMembership: '再加入に失敗しました',
        notificationFileSaved: function (fileName) { return "".concat(fileName, "\u3092\u4FDD\u5B58\u3057\u307E\u3057\u305F"); },
        notificationFinishRegisterOrganizer: '事務所を開設しました',
        notificationFollow: function (name) { return "".concat(name, " \u3092\u30D5\u30A9\u30ED\u30FC\u3057\u307E\u3057\u305F"); },
        notificationFollowed: function (name) { return "".concat(name, " \u306B\u30D5\u30A9\u30ED\u30FC\u3055\u308C\u307E\u3057\u305F"); },
        notificationFollowingUserOff: function (name) { return "".concat(name, " \u306E\u901A\u77E5\u3092\u30AA\u30D5\u306B\u3057\u307E\u3057\u305F"); },
        notificationFollowingUserOn: function (name) { return "".concat(name, " \u306E\u901A\u77E5\u3092\u30AA\u30F3\u306B\u3057\u307E\u3057\u305F"); },
        notificationFromAdministrator: '運営からの通知',
        notificationHelpLink: 'アプリにてAppleやGoogleアカウントでアカウント登録した方で、LINE連携の方法がわからない方は、こちら',
        notificationHidePost: '投稿を非公開にしました',
        notificationIncreaseMembershipQuantity: '決済が完了しました。まもなく反映されます。',
        notificationJoinMembership: '決済が完了しました。まもなく反映されます。',
        notificationKickFromPlan: function (organizerName) { return "".concat(organizerName, " \u306E\u30B5\u30DD\u30FC\u30C8\u30D7\u30E9\u30F3\u304B\u3089\u5F37\u5236\u89E3\u9664\u3055\u308C\u307E\u3057\u305F"); },
        notificationKickLiverFromPlan: 'プランを強制解除しました',
        notificationKickStaff: 'スタッフから外しました',
        notificationLaw: '法令に基づく表記',
        notificationLiveStart: function (liverName) { return "".concat(liverName, " \u304C\u914D\u4FE1\u3092\u59CB\u3081\u307E\u3057\u305F"); },
        notificationLogout: 'ログアウトしました',
        notificationMoneyLaw: '資金決済法の表記',
        notificationNotPassedLSinger: '未審査もしくは審査結果待ち: まだ配信はできません',
        notificationOnePhraseEventEntryVoted: function (userName) { return "\u6295\u7A3F\u3057\u305FTHE ONE PHRASE\u304C".concat(userName, "\u304B\u3089\u9078\u3070\u308C\u307E\u3057\u305F"); },
        notificationPassedLSinger: '審査通過済み: 配信することができます',
        notificationPassFirstLivePermissionForLiver: '一次審査に通過しました',
        notificationPassFirstLivePermissionForOrganizationStaff: function (displayName) { return "\u30B5\u30DD\u30FC\u30C8\u30D7\u30E9\u30F3\u306B\u52A0\u5165\u3057\u3066\u3044\u308B".concat(displayName, "\u304C\u4E00\u6B21\u5BE9\u67FB\u306B\u901A\u904E\u3057\u307E\u3057\u305F"); },
        notificationPassSecondLivePermissionForLiver: '二次審査に通過しました',
        notificationPassSecondLivePermissionForOrganizationStaff: function (displayName) { return "\u30B5\u30DD\u30FC\u30C8\u30D7\u30E9\u30F3\u306B\u52A0\u5165\u3057\u3066\u3044\u308B".concat(displayName, "\u304C\u4E8C\u6B21\u5BE9\u67FB\u306B\u901A\u904E\u3057\u307E\u3057\u305F"); },
        notificationProhibitComments: function (name) { return "".concat(name, " \u306E\u30B3\u30E1\u30F3\u30C8\u3092\u7981\u6B62\u3057\u307E\u3057\u305F"); },
        notificationProhibitReqCapture: 'リク・画録・スクショを禁止しました',
        notificationPublishPost: '投稿を公開しました',
        notificationQuitStaff: '事務所のスタッフを辞めました',
        notificationRejectApplication: '申請を拒否しました',
        notificationRejectBankAccountReview: '審査の結果、大変申し訳ありませんが、お客様はダイヤの換金機能をご利用いただけません',
        notificationRejectBecomeStaff: function (organizerName) { return "".concat(organizerName, " \u306E\u30B9\u30BF\u30C3\u30D5\u7533\u8ACB\u304C\u627F\u8A8D\u3055\u308C\u307E\u305B\u3093\u3067\u3057\u305F"); },
        notificationRejectJoinPlan: function (organizerName, planName) { return "".concat(organizerName, ": ").concat(planName, " \u306E\u30D7\u30E9\u30F3\u52A0\u5165\u304C\u627F\u8A8D\u3055\u308C\u307E\u305B\u3093\u3067\u3057\u305F"); },
        notificationRemoveSongFromFavorite: 'レパートリーから削除しました',
        notificationRetuen: '19:00~24:00がピークタイムです🎧歌を楽しんでくれる方をLシンガーが待っています✨',
        notificationSelectedListenerAsModerator: function (listener) { return "".concat(listener, "\u3092\u30E2\u30C7\u30EC\u30FC\u30BF\u30FC\u306B\u9078\u3073\u307E\u3057\u305F"); },
        notificationSetting: '通知設定',
        notificationSettingOfStartingLive: '配信開始通知の受け取り設定',
        notificationShareOnTwitter: 'Twitterにシェアしました',
        notificationStartLive: function (liverName, liveTitle) { return "".concat(liverName, " \u304C\u3042\u306A\u305F\u3092\u914D\u4FE1\u306B\u62DB\u5F85\u4E2D - ").concat(liveTitle); },
        notificationTerminatedModerator: function (listener) { return "".concat(listener, "\u3092\u30E2\u30C7\u30EC\u30FC\u30BF\u30FC\u304B\u3089\u89E3\u9664\u3057\u307E\u3057\u305F"); },
        notificationUnblock: function (name) { return "".concat(name, " \u306E\u30D6\u30ED\u30C3\u30AF\u3092\u89E3\u9664\u3057\u307E\u3057\u305F"); },
        notificationUnBlockByModerator: function (listener) { return "".concat(listener, "\u306E\u4EE3\u7406\u30D6\u30ED\u30C3\u30AF\u3092\u89E3\u9664\u3057\u307E\u3057\u305F"); },
        notificationUnfollow: function (name) { return "".concat(name, " \u306E\u30D5\u30A9\u30ED\u30FC\u3092\u89E3\u9664\u3057\u307E\u3057\u305F"); },
        notificationUnprohibitComments: function (name) { return "".concat(name, " \u306E\u30B3\u30E1\u30F3\u30C8\u7981\u6B62\u3092\u89E3\u9664\u3057\u307E\u3057\u305F"); },
        notificationUnprohibitReqCapture: 'リク・画録・スクショの禁止を解除しました',
        notificationUpdateMembershipPlan: '変更予約が完了しました',
        notificationUpdatePinnedComment: '固定コメントを設定しました',
        notSinging: 'カラオケ中でない',
        nowSinging: 'カラオケ中',
        nowSingingSong: function (artistName) { return "".concat(artistName, " \u306E\u66F2\u3092\u6B4C\u3063\u3066\u3044\u307E\u3059"); },
        nowStreaming: '配信中',
        nthTags: function (number) { return "".concat(number, "\u3064\u76EE\u306E\u30BF\u30B0"); },
        numberOfComments: 'コメント数',
        numberOfDiamondsToAcquireInTerm: function (diamonds) { return "\u65EC\u306B\u7372\u5F97\u3067\u304D\u308B\u30C0\u30A4\u30E4\u6570: ".concat(diamonds); },
        numberOfOtherPeople: function (count) { return "\u4ED6".concat(count, "\u4EBA"); },
        numberOfPearlsAndDiamondsUsed: '利用するパール・ダイヤの数',
        numberOfPeople: function (number) { return "".concat(number, "\u4EBA"); },
        numberOfPeopleAlreadyRequestedToAddThisSong: function (count) { return "".concat(count, "\u4EBA\u304C\u3053\u306E\u697D\u66F2\u306E\u8FFD\u52A0\u30EA\u30AF\u30A8\u30B9\u30C8\u6E08\u307F\u3067\u3059"); },
        numberOfPeopleAlreadyRequestedToAddThisSongAlsoMe: function (count) { return "\u81EA\u5206\u3092\u542B\u3081\u3001".concat(count, "\u4EBA\u304C\u3053\u306E\u697D\u66F2\u3092\u8FFD\u52A0\u30EA\u30AF\u30A8\u30B9\u30C8\u6E08\u307F\u3067\u3059"); },
        numberOfPeopleAndChange: function (number, change) { return "".concat(number, "\u4EBA (").concat(change, "\u4EBA)"); },
        numberOfPeopleWithTotal: function (number, total) { return "".concat(number, "\u4EBA (\u5408\u8A08").concat(total, "\u4EBA)"); },
        numberOfSheets: '枚数',
        numberOfSongs: function (numberOfSongs) { return "".concat(numberOfSongs, "\u66F2"); },
        numberOfSongsSung: '歌った曲数',
        numOfCoinAcquired: function (coin) { return "<strong>".concat(coin, "</strong>\u30B3\u30A4\u30F3\u3092\u7372\u5F97\u3057\u307E\u3057\u305F\uFF01"); },
        numOfSpecificLiverCoin: function (coin) { return "+\u67A0\u5185\u9650\u5B9A: ".concat(coin); },
        obsServerUrl: 'サーバーURL',
        obsSettingsButtonText: 'OBSの事前設定',
        obsStatusDisabled: '無効',
        obsStatusEnabled: '有効',
        obsStatusText: function (status) { return "OBS\u914D\u4FE1\u30B9\u30C6\u30FC\u30BF\u30B9: ".concat(status); },
        obsStreamKey: 'ストリームキー',
        off: 'オフ',
        officialEmojiComment: '公式キラコメ',
        officialTwitter: '公式Twitter',
        ok: 'OK',
        oldPearlCup: '旧パール杯',
        oldPearlInfo: '旧パール杯（24年2月以前）',
        on: 'オン',
        onDeleteAfter24Hours: 'オン (24時間後に削除)',
        onDeleteAtNextLive: 'オン (次回配信開始時に削除)',
        onePhraseDummyDescription: 'ダミーに投票すると投票は無効となり、\n本日はこれ以上投票できません',
        onePhraseDummyTitle: 'ダミーに投票してしまいました',
        onePhrasePromotionEntryDescription: '15秒程の1フレーズの歌声だけ (名前・映像・サムネが伏せられた状態) で競うイベントのエントリー期間中です。\niPhoneユーザであればリスナーでも録音・参加できます。是非腕試ししてみてください！',
        onePhrasePromotionEntryTitle: 'THE ONE PHRASE\nエントリー期間中！',
        onePhrasePromotionVoteDescription: function (title) { return "9\u4EBA\u306E1\u30D5\u30EC\u30FC\u30BA\u3092\u805E\u304D\u3001\u826F\u3044\u3068\u601D\u3063\u305F\u3082\u306E\u306B\u6295\u7968\u3059\u308B\u3068LINE\u9023\u643A\u30E6\u30FC\u30B6\u3067\u3042\u308C\u3070\u6BCE\u65E510\u30B3\u30A4\u30F3\u3092\u7372\u5F97\u3067\u304D\u307E\u3059\u3002ColorSing\u5185\u3067\u306E\u300C".concat(title, "\u300D\u306E\u8987\u8005\u3092\u7686\u3055\u3093\u306E\u80A5\u3048\u305F\u8033\u3067\u6C7A\u3081\u307E\u3057\u3087\u3046\uFF01"); },
        onePhrasePromotionVoteTitle: 'THE ONE PHRASE\n投票期間中！',
        onePhraseRecording: 'THE ONE PHRASE 録音',
        onePhraseSpanBeforeEntry: 'エントリー期間前',
        onePhraseSpanEntry: 'エントリー期間中',
        onePhraseSpanRankingPublished: '終了',
        onePhraseSpanVote: '投票期間中',
        onePhraseVoteCoinPopUpDescription: '100コイン, 500コイン, 1万コインが当選した場合、後日お知らせいたします。',
        onePhraseVotingByComparing: 'THE ONE PHRASE 投票',
        onePhraseVotingDescription: function (seconds) { return "\u3059\u3079\u3066\u306E\u9332\u97F3\u3092\u8074\u304D\u7D42\u308F\u308B\u3068\u6295\u7968\u3067\u304D\u308B\u3088\u3046\u306B\u306A\u308A\u307E\u3059\n\u203B\u6700\u4F4E".concat(seconds, "\u79D2\u306F\u8074\u304F\u5FC5\u8981\u304C\u3042\u308A\u307E\u3059"); },
        onlyVoice: '声だけ',
        ope: 'OPE',
        openApp: 'ColorSingで開く',
        openAppStore: 'アプリストアを開く',
        openSongDetail: '楽曲詳細を開く',
        operationHistorySupportPlanCreateUserName: '作成者名',
        operationHistorySupportPlanUpdateAfterContent: '変更後内容',
        operationHistorySupportPlanUpdateBeforeContent: '変更前内容',
        operationHistorySupportPlanUpdateContentCategory: '変更項目',
        operationHistorySupportPlanUpdateUserName: '変更者名',
        operationHistoryTableHeaderApplication: '承認 /拒否',
        operationHistoryTableHeaderApplicationStaffId: '承認/拒否した人のid',
        operationHistoryTableHeaderApplicationStaffName: '承認/拒否した人の名前',
        operationHistoryTableHeaderApplicationTargetId: '承認 /拒否された人 id',
        operationHistoryTableHeaderApplicationTargetName: '承認 /拒否された人名',
        operationHistoryTableHeaderDate: '日時',
        operationHistoryTableHeaderLeaveLiverOrganizationName: '自ら退会 or 強制解除する事務所名',
        operationHistoryTableHeaderLeaveLiverSupportPlanName: '自ら退会 or 強制解除するサポートプラン名',
        operationHistoryTableHeaderLeaveLiverTargetUserName: '自ら退会 or 強制解除された人の名前',
        operationHistoryTableHeaderSupportPlanDescription: 'サポートプラン詳細文',
        operationHistoryTableHeaderUpdateOrganizationEditParams: '編集した項目',
        operationHistoryTableHeaderUpdateOrganizationStaffId: '編集した人のuid',
        operationHistoryTableHeaderUpdateOrganizationStaffName: '編集した人の名前',
        oraganizationTerms: '[文言]事務所規約',
        organizarionDiamondPaymentrecord: '事務所ダイヤ付与履歴',
        organizationContactButtomName: '問い合わせボタン内の文言を入力することができます。「Lシンガー加入申請」、「加入に関するお問い合わせはこちら」などご入力ください。',
        organizationContactLink: '事務所への問い合わせリンク',
        organizationContactLinkDescription: '事務所への加入申請等を受付しているLINEビジネスアカウントや、Lステップ等のツール及び、新規登録のやり方等を開設しているページのURLをご入力ください。',
        organizationDetailPageStaffList: '事務所詳細画面スタッフ一覧',
        organizationDiamond: '事務所ダイヤ',
        organizationDiamondNumber: function (diamondNumber) { return "".concat(diamondNumber); },
        organizationDiamondOfPossession: '所持事務所ダイヤ',
        organizationEconomyConditions: '事務所経済条件の設定',
        organizationHpUrl: '事務所HPのURL',
        organizationLeaveLiverHistory: 'Lシンガーの退会履歴',
        organizationLogin: '事務所管理機能ログイン',
        organizationRewardSet: '特別報酬の料率設定',
        organizationRewardSetTerm: '特別報酬の適用期間設定',
        organizationThumbnail: '事務所のサムネイル',
        organizationTwitterAccount: '事務所Twitter',
        organizer: '事務所',
        organizerAnalyticsReportList: '事務所分析レポート一覧',
        organizerAndSupportPlan: '事務所・サポートプラン',
        organizerBio: '事務所について',
        organizerCommissionRate: 'サポートプラン手数料',
        organizerId: '事務所ID',
        organizerIdRequired: '事務所ID (必須)',
        organizerInformation: '事務所情報',
        organizerName: '事務所名',
        organizerNameRequired: '事務所名 (必須)',
        organizerReward: '事務所報酬',
        organizerRoleApproveStaff: '承認権限つきスタッフ',
        organizerRoleNotStaff: '非スタッフ (拒否 / 追放)',
        organizerRoleOwner: 'オーナー',
        organizerRoleType: 'ロールタイプ',
        organizerSaaS: '事務所管理画面',
        organizerSaaSHOME: '事務所管理画面HOME',
        organizerTwitterAccount: '事務所お知らせTwitter',
        organiztionContactButtonTitle: '事務所への問い合わせボタンの名称',
        other: 'その他',
        ownLatestSungDateAndTime: 'あなたが最後に歌った日時',
        paidByPearlAndDiamond: 'パール・ダイヤで支払う',
        paidToTsunagu: 'つなぐへの支払い',
        passOwner: 'オーナーを渡す',
        patternSpecific: function (number) { return "\u30D1\u30BF\u30FC\u30F3".concat(number); },
        pausedTrue: '一時停止中',
        pay: '支払う',
        paymentHasBeenReceived: '支払いが完了しました',
        paymentHasBeenReceivedByDiamondAndPearl: 'ダイヤ・パールでの支払いをしました',
        paymentMethodIsNotSelected: '決済方法が未選択です',
        pearlBefore202402: '24年2月以前のパール',
        pearlCup: 'パール杯',
        pearlCupAndPrize: 'パール杯・プライズ',
        pearlCupDivisionReward: 'パール杯入賞報酬',
        pearlCupOverallDivisionLiveScoreReward: 'パール杯ライブスコア報酬',
        pearlDetail: 'パール明細',
        pearlExpiringAtEndOfThisMonth: '今月末で有効期限が切れるパール',
        pearlPrizeBronze: 'ブロンズ賞',
        pearlPrizeDetailDescription: function (liveScore, pearl) { return "\uD83D\uDD25".concat(liveScore, "\u4EE5\u4E0A\u3067+").concat(pearl); },
        pearlPrizeGold: 'ゴールド賞',
        pearlPrizeOthers: '敢闘賞圏外',
        pearlPrizePlatinum: 'プラチナ賞',
        pearlPrizeRange: function (prizeName) { return "".concat(prizeName, "\u570F\u5185"); },
        pearlPrizeSilver: 'シルバー賞',
        pearlPrizeSpirit: '敢闘賞',
        pearlsPossessionPayAvailable: '利用できる所持パール',
        period: '。',
        permissionNotification: 'フォローしているユーザーが配信を始めた時',
        petitReactionEnterFirstLook: '[初見さん歓迎]\nゆっくりしていってね',
        petitReactionFollow: 'ナイスフォロー👏',
        petitReactionGetFaveBadge: 'バジおめ🥳',
        petitReactionGetFavePoint: '推しおめ✨',
        petitReactionGetFirstSongFave1K: '初歌推しナイス👍',
        petitReactionGetSongFave: function (songFaveWithFavePoint) { return "\u6B4C\u63A8\u3057".concat(songFaveWithFavePoint, "\u7D1A\u30CA\u30A4\u30B9\u3045\u2934\uFE0F"); },
        petitReactionGetSongFave1K: '今月も歌推しナイスです♫',
        petitReactionGetSongFaveWithCount: 'バッジと歌推しWおめ🎉',
        pfCommentBonusByReachFavePoint: function (favePoint, faveBonusRate) { return "\u67A0\u5185\u6708\u9593\u63A8\u3057Pt\u304C".concat(favePoint, "\u306B\u9054\u3057\u305F\u305F\u3081\u3001\u63A8\u3057\u30DC\u30FC\u30CA\u30B9\u3092\u7372\u5F97\u3057\u307E\u3057\u305F\uD83C\uDF89\u00A0(\u500D\u7387+").concat(faveBonusRate, "%)"); },
        pfCommentBonusBySongFaveUU: function (songFaveUU, faveBonusRate) { return "\u6B4C\u63A8\u3057\u304C".concat(songFaveUU, "\u4EBA\u306B\u9054\u3057\u305F\u305F\u3081\u3001\u63A8\u3057\u30DC\u30FC\u30CA\u30B9\u3092\u7372\u5F97\uD83C\uDF89 (\u500D\u7387+").concat(faveBonusRate, "%)"); },
        pfCommentDailyBanner: function (timeline, month, day, rank) { return "".concat(timeline, "\u90E8\u9580\u306B\u3066").concat(month, "\u6708").concat(day, "\u65E5\u306B").concat(rank, "\u4F4D\u304C\u78BA\u5B9A\u3057\u307E\u3057\u305F\uD83C\uDFC6 {timeline}\u30BF\u30A4\u30E0\u30E9\u30A4\u30F3\u306EDaily\u306EPickUp\u30D0\u30CA\u30FC\u306B\u63B2\u8F09\u3055\u308C\u307E\u3057\u305F\uD83C\uDF89"); },
        pfCommentEnter: function (listenerName) { return "".concat(listenerName, " \u304C\u3042\u305D\u3073\u306B\u6765\u305F\u3088"); },
        pfCommentEnterDaysComer: function (day, listenerName) { return "".concat(day, "\u65E5\u524D\u306B\u767B\u9332\u3057\u305F\u3070\u304B\u308A\u306E ").concat(listenerName, " \u304C\u3042\u305D\u3073\u306B\u6765\u305F\u3088"); },
        pfCommentEnterFirstLook: function (listenerName) { return "".concat(listenerName, " \u304C\u3042\u305D\u3073\u306B\u6765\u305F\u3088 (\u521D\u898B)"); },
        pfCommentEnterForLiverPop: 'があそびに来たよ',
        pfCommentEnterTodayComer: function (listenerName) { return "\u4ECA\u65E5\u767B\u9332\u3057\u305F\u3070\u304B\u308A\u306E ".concat(listenerName, " \u304C\u3042\u305D\u3073\u306B\u6765\u305F\u3088"); },
        pfCommentFinishSong: '歌唱終了♫',
        pfCommentFollow: function (listenerName, liverName) { return "".concat(listenerName, " \u304C ").concat(liverName, " \u3092\u30D5\u30A9\u30ED\u30FC\u3057\u307E\u3057\u305F\uD83C\uDF89"); },
        pfCommentGetFaveBadge: function (listenerName, faveBadgeName) { return "".concat(listenerName, " \u304C").concat(faveBadgeName, "\u3092\u7372\u5F97\uD83C\uDF89"); },
        pfCommentGetFaveBadgeTitle: function (faveBadgeName) { return "".concat(faveBadgeName, " \u63A8\u3057\u30D0\u30C3\u30B8"); },
        pfCommentGetFaveBadgeV2: 'を獲得🎉',
        pfCommentGetFavePoint: function (listenerName, favePoint) { return "".concat(listenerName, " \u306E\u63A8\u3057Pt\u304C").concat(favePoint, "\u306B\u306A\u308A\u307E\u3057\u305F\uD83C\uDF89"); },
        pfCommentGetFirstFaveBadge: function (listenerName, faveBadgeName) { return "".concat(listenerName, " \u304C\u521D\u3081\u3066").concat(faveBadgeName, "\u3092\u7372\u5F97\uD83C\uDF89"); },
        pfCommentGetFirstFaveBadgeV2: 'を獲得🎉(初めて)',
        pfCommentGetFirstSongFave: function (listenerName, songFaveWithFavePoint) { return "".concat(listenerName, " \u304C\u521D\u3081\u3066\u6B4C\u63A8\u3057: \u6708\u9593").concat(songFaveWithFavePoint, "\u7D1A\u306B\u306A\u308A\u307E\u3057\u305F\uD83C\uDF89"); },
        pfCommentGetFirstSongFaveV2: 'を達成🎉(初めて)',
        pfCommentGetHonorByFaveBonus: function (liverName, honerByFaveBonus) { return "".concat(liverName, " \u304C\u63A8\u3057\u30DC\u30FC\u30CA\u30B9\u79F0\u53F7\u300E").concat(honerByFaveBonus, "\u300F\u3092\u7372\u5F97\uD83C\uDF89"); },
        pfCommentGetSongFave: function (listenerName, songFaveWithFavePoint) { return "".concat(listenerName, " \u304C\u6B4C\u63A8\u3057: \u6708\u9593").concat(songFaveWithFavePoint, "\u7D1A \u306B\u306A\u308A\u307E\u3057\u305F\uD83C\uDF89"); },
        pfCommentGetSongFaveTitle: function (songFaveWithFavePoint) { return "\u6708\u9593: \u6B4C\u63A8\u3057 ".concat(songFaveWithFavePoint, "\u7D1A"); },
        pfCommentGetSongFaveV2: 'を達成🎉',
        pfCommentGetSongFaveWithContinueCount: function (listenerName, songFaveWithFavePoint, continueCount, count) { return "".concat(listenerName, " \u304C\u6B4C\u63A8\u3057: \u6708\u9593").concat(songFaveWithFavePoint, "\u7D1A \u306B\u306A\u308A\u307E\u3057\u305F\uD83C\uDF89 (").concat(continueCount, "\u30F6\u6708\u9023\u7D9A").concat(count, "\u56DE\u76EE)"); },
        pfCommentGetSongFaveWithContinueCountV2: function (continueCount, count) { return "\u3092\u9054\u6210\uD83C\uDF89 (".concat(continueCount, "\u30F6\u6708\u9023\u7D9A").concat(count, "\u56DE\u76EE)"); },
        pfCommentGetSongFaveWithCount: function (listenerName, songFaveWithFavePoint, count) { return "".concat(listenerName, " \u304C\u6B4C\u63A8\u3057: \u6708\u9593").concat(songFaveWithFavePoint, "\u7D1A\u306B\u306A\u308A\u307E\u3057\u305F\uD83C\uDF89 (").concat(count, "\u56DE\u76EE)"); },
        pfCommentGetSongFaveWithCountV2: function (count) { return "\u3092\u9054\u6210\uD83C\uDF89 (".concat(count, "\u56DE\u76EE)"); },
        pfCommentGetSongRandomly: function (songName, singerName) { return "\u30E9\u30F3\u30C0\u30E0\u9078\u66F2\u3067 ".concat(songName, " / ").concat(singerName, " \u304C\u9078\u3070\u308C\u307E\u3057\u305F"); },
        pfCommentGetSongRandomlyAgain: function (songTitle, singerName) { return "\u518D\u5EA6\u30E9\u30F3\u30C0\u30E0\u9078\u66F2\u3057\u3001".concat(songTitle, " / ").concat(singerName, " \u304C\u9078\u3070\u308C\u307E\u3057\u305F"); },
        pfCommentGifting: function (giftName) { return "\u300E".concat(giftName, "\u300F"); },
        pfCommentGiftingWithSmallGiftType: function (giftName, smallGiftTypeName) { return "".concat(giftName, " (").concat(smallGiftTypeName, ")"); },
        pfCommentHourlyBanner: function (timeline, hour, rank) { return "".concat(timeline, "\u90E8\u9580\u306B\u3066").concat(hour, "\u6642\u306B").concat(rank, "\u4F4D\u304C\u78BA\u5B9A\u3057\u307E\u3057\u305F\uD83C\uDFC6 {timeline}\u30BF\u30A4\u30E0\u30E9\u30A4\u30F3\u306EHourly\u306EPickUp\u30D0\u30CA\u30FC\u306B\u63B2\u8F09\u3055\u308C\u307E\u3057\u305F\uD83C\uDF89"); },
        pfCommentLike: function (listenerName) { return "".concat(listenerName, ": \u3044\u3044\u306D\u2661\uFE0F"); },
        pfCommentRequestSing: function (songName, singerName) { return "\u3053\u306E\u66F2\u3069\u3046\uFF1F: ".concat(songName, " / ").concat(singerName); },
        pfCommentStartSinging: function (songName, artistName) { return "".concat(songName, " / ").concat(artistName, " \u3092\u6B4C\u3044\u59CB\u3081\u307E\u3057\u305F (\u97F3\u6E90\u63D0\u4F9B: JOYSOUND)"); },
        pfCommentSuperLike: function (listenerName) { return "".concat(listenerName, ": \u30B9\u30FC\u30D1\u30FC\u3044\u3044\u306D\u2661"); },
        pfCommentSuperLikeWithNumber: function (listenerName, number) { return "".concat(listenerName, ": \u30B9\u30FC\u30D1\u30FC\u3044\u3044\u306D\u2661 (").concat(number, ")"); },
        pFCommentSurgeRankTop: function (hour, min) { return "\u6025\u4E0A\u6607\u30E9\u30F3\u30AD\u30F3\u30B0\u30671\u4F4D\uFF06\u30B9\u30B3\u30A21K\u4EE5\u4E0A\u3092\u9054\u6210\uFF01\uFF08".concat(hour, "\u6642").concat(min, "\u5206\u53F0\uFF09"); },
        pFCommentSurgeRankTopWithDiamond: function (hour, min) { return "\u6025\u4E0A\u6607\u30E9\u30F3\u30AD\u30F3\u30B0\u30671\u4F4D\uFF06\u30B9\u30B3\u30A21K\u4EE5\u4E0A\u3092\u9054\u6210\uFF01100\u30C0\u30A4\u30E4\u7372\u5F97\uFF01\uFF08".concat(hour, "\u6642").concat(min, "\u5206\u53F0\uFF09"); },
        pfCommentTermlyBanner: function (timeline, month, term, rank) { return "".concat(timeline, "\u90E8\u9580\u306B\u3066").concat(month, "\u6708").concat(term, "\u306B").concat(rank, "\u4F4D\u304C\u78BA\u5B9A\u3057\u307E\u3057\u305F\uD83C\uDFC6 {timeline}\u30BF\u30A4\u30E0\u30E9\u30A4\u30F3\u306E\u65EC\u306EPickUp\u30D0\u30CA\u30FC\u306B\u63B2\u8F09\u3055\u308C\u307E\u3057\u305F\uD83C\uDF89"); },
        pfFavePoint: '全体推しPt',
        pickUp: 'PickUp',
        pickUpNumber: function (no) { return "PickUp ".concat(no); },
        pinned: '固定',
        placeDebugStressOn: '負荷をかける (DEBUG)',
        placeholderAccountIdInstagram: 'InstagramのアカウントID',
        placeholderAccountIdTikTok: 'TikTokのアカウントID',
        placeholderAccountIdTwitter: 'TwitterのアカウントID',
        placeholderBio: '自己紹介',
        placeholderEnterLiveComment: 'コメントを入力…',
        placeholderHandleYouTube: 'YouTubeのハンドル',
        placeholderKaraokeSearchForm: '歌手名・曲名で探す',
        placeholderLiveTitle: '配信のタイトル (20文字以内)',
        placeholderNickname: 'ニックネーム',
        placeholderPleaseSelect: '選択してください',
        placeholderSearchArtistsByName: 'アーティスト名で検索',
        placeholderSimple: '入力してください',
        placeholderSupportPlan: 'スタンダードプラン',
        placeholderUrlWebsite: 'WebサイトのURL',
        playFromFirst: '最初から再生',
        pleaseChooseBestOfBestOnePhraseFromNames: function (names) { return "\u5148\u307B\u3069\u9078\u3093\u3060".concat(names, "\u304B\u3089\n\u4E00\u756A\u826F\u3044\u3068\u601D\u3046\u3082\u306E\u9078\u3093\u3067\u304F\u3060\u3055\u3044"); },
        pleaseChooseBestOnePhraseFromNames: function (names) { return "".concat(names, "\u304B\u3089\n\u4E00\u756A\u826F\u3044\u3068\u601D\u3046\u3082\u306E\u9078\u3093\u3067\u304F\u3060\u3055\u3044"); },
        plusPointFixed: function (point) { return "".concat(point, "\u78BA\u5B9A"); },
        popular: '人気',
        portionRateOfLiver: 'Lシンガーの取り分',
        possession: '所持',
        post: '投稿',
        postUserMemoForCS: 'ユーザーメモを残す (forCS)',
        prefecture: '都道府県',
        prefectureSecret: '秘密・未選択',
        prefixCanNotSing: '[音源無し]',
        prepareMusic: '楽曲をダウンロードしています',
        price: '価格',
        priceDifferenceDescription: function (coinCount, number, discountYen) { return "\u30A2\u30D7\u30EA\u5185\u306E".concat(coinCount, "\u30B3\u30A4\u30F3 x ").concat(number, " \u306E\u8CFC\u5165\u4FA1\u683C\u3088\u308A").concat(discountYen, "\u5186\u5B89\u3044\uFF01"); },
        priceDifferenceSideNote: function (coinCount) { return "\u203B \u30A2\u30D7\u30EA\u5185\u3067\u7D2F\u8A081\u4E07\u30B3\u30A4\u30F3\u4EE5\u4E0A\u306E\u8CFC\u5165\u8005\u304C\u30A2\u30D7\u30EA\u5185\u8CFC\u5165\u3067".concat(coinCount, "\u30B3\u30A4\u30F3\u76F8\u5F53\u5206\u3092\u4E00\u62EC\u3067\u8CB7\u3063\u305F\u5834\u5408\u3068\u306E\u6BD4\u8F03"); },
        priceJpyMembershipForThisTime: function (jpy, month) { return "\u4ECA\u56DE\u306E\u6C7A\u6E08\u984D: ".concat(jpy, "\u5186\uFF08").concat(month, "\u30F6\u6708\u5206\uFF09"); },
        priceJpyOfMembership: function (jpy, month) { return "\u00A5".concat(jpy, " / ").concat(month, "\u30F6\u6708"); },
        privacyPolicy: 'プライバシーポリシー',
        prizesToExchangeWithPearl: 'パールと交換できるプライズの一覧',
        profile: 'プロフィール',
        profileIconGuideDescription: '配信しタイムラインに掲載される場合は、画像の上15%のエリア、下25%のエリアのエリアにマークや名前等の情報が表示されるため、その部分は見えづらくなります。',
        progressEarningFavePoints: '推しPtの獲得進捗',
        progressFavePoints: '推しPt進捗',
        prohibit: '禁止',
        prohibitComments: 'コメントを禁止',
        prohibitReqCaptureScreenshot: 'リク・画録・スクショを禁止',
        promotionMeter: '昇格戦メーター',
        promotionMeterCantBeDisplayed: 'メーターは締め時間の24時間前から表示されます ',
        publishAfterFinished: '結果発表後公開',
        publishThisPost: 'この投稿を公開',
        purchase: '購入',
        purchaseCoin: 'コインを購入',
        purchaseCoinWithLowerPrice: 'コインを安く購入',
        purchasedCoin: '決済が完了しました。間もなくコインが反映されます。',
        purchaseOverPricedCoin: 'このまま購入',
        purchaseWebCoinCheap: 'コインを安く購入',
        purchaseWebCoinCheapDescription: 'スターとセットで購入できます。ただし、LINE連携をしていない場合、スターは無効になります。スターが有効の場合、コイン獲得効率が高くなります。',
        purchaseWebCoinCheapDescriptionLineNotConnected: '現在このアカウントではLINE連携していないので、スターを購入・保持していても無効になります。',
        quitStaff: 'スタッフをやめる',
        quitStaffConfirmation: '事務所のスタッフをやめると、この事務所管理画面は実行したタイミングから閲覧できなくなり、この画面は閉じます。それでもこの事務所スタッフをやめますか？',
        ranking: 'ランキング',
        rankingPlanMember: 'プラン加入者ランキング',
        rankingType: 'ランキング種類',
        rankThreshold: function (rank) { return "".concat(rank, "\u4F4D\u307E\u3067"); },
        rantCoin: 'コインを付与する',
        rantDiamond: 'ダイヤを付与する',
        rateDiamond: function (rateDiamond) { return "".concat(rateDiamond, "%"); },
        rateUnit: '倍',
        reasonSelectBirthday: 'なぜ誕生日を選択する必要があるかについてはこちら',
        reasonSelectGeneration: 'なぜ年代を選択する必要があるかについてはこちら',
        recommendation: 'おすすめ',
        recommendedArtists: 'おすすめのアーティスト',
        recommendedLSingers: 'おすすめLシンガー',
        recordAlertSentence1: '本画面では、様々な履歴データを閲覧することができます。',
        recordAlertSentence2: '閲覧したいデータを選択すると、該当するデータの各画面へ遷移します。',
        recordAudio: '録音',
        recordChangeSupportPlan: '[文言]サポートプラン内容変更履歴',
        recordCreateSupportplan: 'サポートプラン作成履歴',
        recordEditOrganizationInformation: '事務所情報編集履歴',
        recordingTheFile: function (name) { return "".concat(name, "\u3092\u9332\u97F3\u4E2D"); },
        recordOnlyInLyricRange: '歌詞の範囲内のみ録音されます',
        recordSapprtplanApprovalRejection: 'サポートプラン承認/拒否履歴',
        recordSapprtplanWithdrawal: 'サポートプラン退会履歴',
        recordStuffApprovalRejection: 'スタッフ承認/拒否履歴',
        recordStuffroleChange: 'スタッフロール変更履歴',
        recordUpdateSupportplan: 'サポートプラン変更履歴',
        refundMoney: '換金の払い戻し',
        register: '登録',
        registerOrganizer: '事務所開設・登録',
        reject: '拒否',
        rejoining: '再加入',
        remarks: '備考',
        removeApplicationForStaff: '事務所スタッフ化申請を取り消しました',
        removeSongFromFavorite: 'レパートリーから削除',
        removeSongFromFavoriteAsListener: '推し曲から削除',
        removeSongFromOwnFavorite: '自分のレパートリーから削除',
        repertoire: 'レパートリー',
        report: '通報',
        reportComplete: '通報の受付が完了しました今後ともColorSingをよろしくお願いいたします。',
        reportCompleted: 'チェック済み',
        reportContent: '内容',
        reportDate: '通報日',
        reportDescription1: '※ 同じ配信・配信者に対する同内容の複数回の通報は一通報と処理させて頂きます。\n',
        reportDescription2: '※ 差し迫った危険に直面する人がいる場合は、ColorSingへの通報後、地域の警察または消防機関に緊急通報してください。',
        reportDescription3: '※ 通報者本人がルールに違反している場合、通報者の利用も制限されることがあります。\n',
        reportDescription4: '※ ColorSingのルールに違反していると思われる場合は通報できます。',
        reportDescription5: '※ 個人情報は記載しないでください。',
        reportDescriptionAttachmentFile: 'スクリーンショットなどがあれば添付してください。',
        reportDescriptionDetail: function (minCount, maxCount) { return "".concat(minCount, "\u5B57\u4EE5\u4E0A").concat(maxCount, "\u5B57\u4EE5\u5185"); },
        reportDescriptionReason: '下記から該当する通報理由を選択してください。',
        reportFile: 'ファイル',
        reportId: '通報ID',
        reportID: '通報ID',
        reportItemAttachmentFile: '添付ファイル',
        reportItemDetail: '詳細',
        reportItemReason: '通報理由',
        reportOptionReasonAdult: 'アダルト\n',
        reportOptionReasonAgeRestriction: '本来は利用できない年齢',
        reportOptionReasonCheat: '不正行為 (複数アカウント利用、bot など)',
        reportOptionReasonCopyrightInfringement: '著作権侵害',
        reportOptionReasonDangerousActivity: '危険行為',
        reportOptionReasonDatingPurpose: '出会い目的',
        reportOptionReasonDefamation: '誹謗中傷／迷惑行為',
        reportOptionReasonDiscrimination: '差別行為',
        reportOptionReasonFraud: '詐欺／営利目的の利用',
        reportOptionReasonIllegalActivity: '法令違反／公序良俗違反\n',
        reportOptionReasonNoActivity: 'コミュニケーションやパフォーマンスのない配信',
        reportOptionReasonOther: 'その他の違反行為',
        reportPlace: '通報箇所',
        reportPlaceholderDetail: '例: 運転しながら配信している',
        reportPriority: '優先度',
        reportPriorityHigh: '高',
        reportPriorityLow: '低',
        reportPriorityMedium: '中',
        reportReason: '通報理由',
        reportTargetID: '通報対象',
        reportTargetUser: '被通報者',
        reportTimeFromLiveStart: '配信開始からの経過時刻',
        reportUncompleted: '未チェック',
        reportUsedSong: '使用楽曲の報告',
        reportUser: '通報者',
        reportUsername: function (username) { return "".concat(username, " \u3055\u3093\u3092\u901A\u5831\u3057\u307E\u3059\u3002"); },
        reportUsers: '通報の動き',
        reqCaptureScreenshotAreProhibited: 'リク・画録・スクショ禁止中',
        requestForStoreReview: 'ストアレビューのお願い',
        RequestSingImpossibleByBanned: 'リクエストは現在禁止されています。',
        requestSingImpossibleByOneDayOneLiverOneTimeIfLessThan100FavePoint: '推しPtが0.1K未満の場合は1日1枠1回までしかリクエストを送れません、また0.1Kの他枠でまたリクエストするには一定のお時間が必要です',
        RequestSingImpossibleByShortOfFavePoint: '推しPtが旬で0.1K以上、または、その日初めてのリクエストの場合は配信にて2コメント以上の場合リクエストが可能です。※ ただし、既にほか枠で0.1K未満でリクエストしている場合はいたずら防止のため、一定時間が必要です。また、0.1K未満でリクエストできるのは一日一回だけです。',
        requestToAddNewSong: '楽曲追加リクエスト',
        requestToAddNewSongJoysoundDescription: 'WEBサイトの右上のフォームから検索してください\n結果一覧 -> 曲詳細ページで追加リクエストができます',
        requestToAdminAboutNewSong: '運営への要望 (楽曲追加)',
        requestToAdminAboutOthers: '運営への要望（その他）',
        requestToAdministrator: '運営への要望',
        requestToJoinToPlan: '加入申請',
        required: '必須',
        resetNotification: '通知設定をONに！',
        reviewOrganizerProfile: '事務所情報審査',
        reviewProfile: 'プロフィール審査',
        rewardByOverallDivisionLiveScoreDescription: '※達成報酬では、🔥5万毎に 🌈を3個、🔥100万、🔥200万、🔥300万でそれぞれ 🌈を20個獲得できます',
        ruleBook: '共通ガイドライン',
        salaryFromOrganizer: '事務所から支払い',
        save: '保存',
        scheduledToJoinFromNextMonth: '翌月から加入予定',
        scheduleToUpdateMembershipQuantityFromNextTerm: '次回以降のパス枚数変更予約',
        scheduleToUpdateMembershipQuantityOfLiver: function (liver) { return "".concat(liver, "\u306E\u6B21\u56DE\u4EE5\u964D\u306E\u30D1\u30B9\u679A\u6570\u5909\u66F4\u4E88\u7D04"); },
        scoreFiveStar: '★5をつける',
        screenuserid: 'ユーザーID',
        search: '検索',
        searchArtists: 'アーティストを検索',
        searchCreatorsForTsunagu: '依頼できるクリエイターを探す (つなぐ)',
        searchCreatorsForTsunaguShortTitle: 'クリエイター検索(つなぐ)',
        searchKeywordForOffVocal: function (songTitle, artistName) { return "".concat(songTitle, " ").concat(artistName, " \u30AB\u30E9\u30AA\u30B1\u7DF4\u7FD2\u7528 offvocal"); },
        searchOnYouTube: 'YouTubeで探す',
        searchOrganizer: '事務所を探す',
        searchRequestSong: '歌って欲しい曲を探す',
        searchResult: '検索結果',
        searchResultSpecific: function (name) { return "".concat(name, " \u306E\u691C\u7D22\u7D50\u679C"); },
        secondTerm: '中旬',
        seeAll: 'すべて見る',
        seeDetail: '詳しく見る',
        seeMore: 'もっと見る',
        seeNoCheck: '未確認',
        seeResult: '結果を見る',
        selectedEntryName: function (name) { return "".concat(name, " (\u9078\u629E\u6E08)"); },
        selectFavoriteArtists: '好きなアーティスト選択',
        selectHowToSongLvJudge: '審査方法を選択',
        selectListenerAsModerator: 'モデレーターに選ぶ',
        selectMyTown: 'マイタウン選択',
        selectSongLv: '歌力を選択',
        selectWallet: '[文言]振込口座の選択',
        sent3TermsFavePointAllLiversShort: '全体',
        sentOfficialEmojiComment: '公式キラコメを送った',
        setList: 'セットリスト',
        setOf31320CoinAndStarLv3: '31,320コインと3.5倍スターのセット',
        setOf6120CoinAndStarLv2: '6,120コインと2.5倍スターのセット',
        setOf990CoinAndStarLv1: '990コインと1.5倍スターのセット',
        setting: '設定',
        setupDream: '夢の設定',
        share: 'シェア',
        shareOnTwitter: 'Twitterにシェアしますか？',
        shareOrganizationUrl: '事務所URLを共有する',
        shortfallForTsunaguPayment: function (shortfall) { return "\u00A5".concat(shortfall, "\u4E0D\u8DB3\u3057\u3066\u3044\u307E\u3059"); },
        signInWithApple: 'Appleでサインイン',
        signInWithGoogle: 'Googleでサインイン',
        simple: 'シンプル',
        singAgain: '歌い直す',
        singAndLive: '歌・配信',
        singColorHome: 'ColorSing Home',
        singer: '歌手',
        singerPinnedCommentAfterEnterDescription: 'リスナーさんが入室 (あそびに来たよ) 後に表示される固定コメントです。\n・入室感謝のごあいさつ\n・今月の目標\n・枠のみんなに伝えたいこと\nなどを書いてみましょう！',
        singerPinnedCommentAfterEnterDescriptionV2: 'リスナーさんが入室 (あそびに来たよ) 後に表示される固定コメントです。上のプレビューのように折りたたんで表示されます。',
        singerPinnedCommentAfterEnterInputPlaceholder: '・入室感謝のごあいさつ\n・今月の目標\n・枠のみんなに伝えたいこと\nなどを分かりやすく書いてみましょう！',
        singerPinnedCommentPreviewLiveDescription: 'リスナーさんが試聴画面を開いた瞬間 (あそびに来たよが表示される前) に表示される固定コメントです。\n・自己紹介やリンク\n・枠内のルール\nなどを分かりやすく書いてみましょう！',
        singerPinnedCommentPreviewLiveDescriptionV2: 'リスナーさんが試聴画面を開いた瞬間 (あそびに来たよが表示される前) にその人にだけ表示される固定コメントです。上のプレビューのように折りたたんで表示されます。',
        singerPinnedCommentPreviewLiveInputPlaceholder: '・自己紹介やリンク\n・枠内のルール\nなどを分かりやすく書いてみましょう！',
        singerPinnedCommentSettingAfterEnter: '固定コメント設定（入室完了後）',
        singerPinnedCommentSettingInputHelpText: '下のエリアに入力するとコメントが反映されます',
        singerPinnedCommentSettingPreviewLive: '固定コメント設定（この枠について）',
        singSongRandomly: 'ランダムで歌う',
        singStatus: '歌唱状態',
        skip: 'スキップ',
        smallGiftTypeBig: 'ビッグ',
        smallGiftTypeBigHeart: 'ビッグハート',
        sns: 'SNS',
        songComposerName: function (composerName) { return "\u66F2: ".concat(composerName); },
        songFave: '歌推し',
        songFaveMonthly100K: '歌推し: 月間100K級',
        songFaveMonthly10K: '歌推し: 月間10K級',
        songFaveMonthly1K: '歌推し: 月間1K級',
        songFaveMonthly20K: '歌推し: 月間20K級',
        songFaveMonthly30K: '歌推し: 月間30K級',
        songFaveMonthly40K: '歌推し: 月間40K級',
        songFaveMonthly50K: '歌推し: 月間50K級',
        songFaveMonthly5K: '歌推し: 月間5K級',
        songFaveMonthly60K: '歌推し: 月間60K級',
        songFaveMonthly70K: '歌推し: 月間70K級',
        songFaveMonthly80K: '歌推し: 月間80K級',
        songFaveMonthly90K: '歌推し: 月間90K級',
        songFaveUnder: '歌推し未満',
        songLv0: '歌力認定なし',
        songLv1: '歌力+1',
        songLv2: '歌力+2',
        songLv3: '歌力+3',
        songLvJudgeForm: '歌力認定審査フォーム',
        songName: '曲名',
        songUnitWithNumberForDiamond: function (number) { return "".concat(number, "\u66F2\u5206"); },
        sortByFavoriteCreatedAt: '登録順',
        sortByFavoriteCreatedAtDesc: '最近登録した順',
        sortByJoinedAtDesc: '新規加入順',
        sortByKanaAsc: 'かな順',
        sortByKanaDesc: 'かな逆順',
        sortByLastSungAtAsc: '歌ってない順',
        sortByLeagueIDDesc: 'リーグ順',
        sortByNewReleasedAtAsc: '古い順',
        sortByNewReleasedAtDesc: '新曲順',
        sortByPopularityDesc: '人気順',
        sortByRandom: 'ランダム',
        sortByReleaseDateDesc: 'リリース順',
        sortBySingerKanaAsc: 'アーティストかな順',
        sortBySingerKanaDesc: 'アーティストかな逆順',
        sortBySingingTimesDesc: 'よく歌う順',
        sortByTotalCoinDesc: '累計獲得ダイヤ順',
        span: '期間',
        specifiedCommercialTransactionAct: '特定商取引法に基づく表記',
        specifiedCommercialTransactionActShort: '法令に基づく表示',
        specifyPeriod: '期間を指定',
        specifyTime: '時間指定投稿',
        staff: 'スタッフ',
        staffs: 'スタッフ陣',
        staffsList: 'スタッフ陣一覧',
        starInfomation: 'スター情報',
        starInvalidInformation: function (rate) { return "\u73FE\u5728\u7121\u52B9\u306B\u306A\u3063\u3066\u3044\u307E\u3059 (\u203BWeb\u8CFC\u5165\u6BD4\u7387\u304C".concat(rate, "%\u672A\u6E80\u306E\u305F\u3081)"); },
        starInvalidInformationBecauseLine: '現在無効になっています (※LINE連携していないため)',
        starInvalidInformationBecauseRateAndLine: function (rate) { return "\u73FE\u5728\u7121\u52B9\u306B\u306A\u3063\u3066\u3044\u307E\u3059 (\u203BWeb\u8CFC\u5165\u6BD4\u7387\u304C".concat(rate, "%\u672A\u6E80\u306E\u305F\u3081\u30FBLINE\u9023\u643A\u3057\u3066\u3044\u306A\u3044\u305F\u3081)"); },
        startDay: '開始日',
        startLive: '配信スタート',
        startToSingThisSong: 'この曲を歌う',
        startYoutubeViewing: '同時視聴',
        statisticsByTimeSeries: '時系列別の統計',
        status: 'ステータス',
        stop: 'やめる',
        stopDebugStress: '負荷とめる (DEBUG)',
        stopLive: '配信停止',
        stopYoutubeViewing: '再生を終了',
        subheadingDeleteAccount: '現在ログイン中のアカウントに対して、削除 (退会) の手続きが行われます。',
        subheadingDeleteAccountApple: '「Sign in with Apple」で会員登録された方',
        submit: '送信',
        subscribedMembership: '加入中のメンバーシップ',
        subscribeMembership: 'メンバーシップに加入',
        successToastPlanDeleted: '[文言]プランを削除しました。',
        suggestSinging: 'この曲どう？と提案する',
        superLike: 'スーパーいいね',
        support: 'サポート',
        supportedLiverRanking: 'Lシンガーランキング',
        supportedLivers: 'Supported Lシンガーの一覧',
        supportForFaveWithFiveStar: '★5をつけて推しを応援する',
        supportPlan: 'サポートプラン',
        supportPlanApplicationStatus: '新規受付ステータス',
        supportPlanList: 'サポートプラン一覧',
        supportPlanName: 'サポートプラン名',
        supportPlanNameRequired: 'サポートプラン名 (必須)',
        supportPlanStatusRecruitingApplication: '新規受付中',
        supportPlanStatusSuspendingApplication: '新規受付停止中',
        surge: '急上昇',
        surgeIn: '○○ x △△',
        surgeRankTopReward: '急上昇1位報酬',
        suspendedNewApplication: '新規受付停止中',
        systemOpe: 'システム',
        tabHomeAndPlans: 'サポートプラン',
        tabStaffs: 'スタッフ陣',
        tabSupportedLiver: 'Supported Lシンガー',
        tag: 'タグ',
        tagExclusiveManager: '専属マネージャー',
        tagFanGoodsProductionSupport: 'ファングッズ制作支援',
        tagListenerRewardSendingAgency: 'リスナー特典送付代行',
        tagMonthlyCoaching: '月次コーチング',
        tagNegotiableByDM: 'DM相談可',
        tagNetworkingEvent: 'Lシンガー交流会',
        tagOrganizerEvent: '事務所イベント',
        tagPresentReceivingAgency: 'プレゼント受付代行',
        tagSupportOffMeeting: 'オフ会支援',
        tagWeeklyCoaching: '週次コーチング',
        targetAll: '全体',
        targetBio: '自己紹介',
        targetComment: 'コメント',
        targetInAppAction: 'アプリ内活動',
        targetLiveContents: '配信内容',
        targetProfileImage: 'プロフィール画像',
        targetTag1: 'タグ1',
        targetTag2: 'タグ2',
        targetTag3: 'タグ3',
        targetTikTok: 'TikTok',
        targetTwitter: 'Twitter',
        targetURL: 'URL',
        targetUserId: '対象のUserIDs (,区切り)',
        targetUserName: 'ユーザ名',
        targetYouTube: 'YouTube',
        term: '旬',
        terminateModerator: 'モデレーターから解除',
        termlyOfBanner: '旬',
        termlyPickUpBanner: '旬PickUpバナー',
        terms: '利用規約',
        theOnePhrase: 'THE ONE PHRASE',
        thereAreNewNotifications: '新しいお知らせがあります',
        theXthDay: function (day) { return "".concat(day, "\u65E5\u76EE"); },
        thirdTerm: '下旬',
        thisDayWithDayOfTerm: function (month, term, dayOfTerm) { return "THIS DAY: ".concat(month, "\u6708").concat(term, "\u306EDAY").concat(dayOfTerm); },
        thisLiveIsFinished: 'この配信は終了しました',
        thisMonth: '今月',
        thisSongExistsInThisApp: 'この楽曲はColorSingに存在します',
        thisTerm: '今旬',
        tiktok: 'TikTok',
        timeFormathh: function (hour) { return "".concat(hour, "\u6642\u53F0"); },
        timeFormatM: function (month) { return "".concat(month, "\u6708"); },
        timeFormatMd: function (month, day) { return "".concat(month, "\u6708").concat(day, "\u65E5"); },
        timelineForYou: 'ForYou',
        titleNameChange: '配信タイトルを変更する',
        titlePurchaseCoin: 'コインを購入する',
        titleUpdateApp: 'アップデートのお願い',
        today: '今日',
        toEndTime: function (endTime) { return "".concat(endTime, "\u307E\u3067"); },
        topNumber: function (number) { return "TOP".concat(number); },
        totalApplicationAmount: '合計換金額',
        TotalDiamondPer2Month20Percent: '2ヶ月以内のダイヤ獲得数の20%',
        totalExchangeableDiamond: '換金できるダイヤ',
        totalling: '集計中',
        totalTransferAmount: '合計振込額',
        toUseBluePearlAmount: function (amount) { return "\u5229\u7528\u3059\u308B\uD83D\uDD35\u30D1\u30FC\u30EB\u6570 (~".concat(amount, ")"); },
        toUseDiamondAmount: function (amount) { return "\u5229\u7528\u3059\u308B\uD83D\uDC8E\u30C0\u30A4\u30E4\u6570 (~".concat(amount, ")"); },
        toUseRainbowPearlAmount: function (amount) { return "\u5229\u7528\u3059\u308B\uD83C\uDF08\u30D1\u30FC\u30EB\u6570 (~".concat(amount, ")"); },
        toUseRedPearlAmount: function (amount) { return "\u5229\u7528\u3059\u308B\uD83D\uDD34\u30D1\u30FC\u30EB\u6570 (~".concat(amount, ")"); },
        trialStreaming: 'おためし配信',
        trimCustomBackground: '背景画像をトリミング',
        trimImage: '画像をトリミング',
        tsunaguExchangeRateDescription: '1購入あたり、2🌈パールまで500円相当ですが、それ以降とそれ以外のパールは450円になります。また、1購入あたり500ダイヤまでは1円ですが、それ以降は0.7円相当になります',
        tsunaguPaymentCancelled: 'つなぐへの決済失敗による払い戻し',
        tsunaguPaymentPolicyDescription: '※ パールとダイヤの円換算額が価格を上回る場合、おひねりは発生せず、円換算額ではなく価格分だけが販売者に支払われます。追加支払いやおひねりをしたい場合は、この決済とは別に追加支払いができる機能がございますのでそちらをご利用ください。',
        tsunaguPayWithPearlsAndDiamonds: 'パール・ダイヤで支払う',
        tsunaguTransactionCancelled: 'つなぐで取引がキャンセルになったため払い戻し',
        tweet: 'ツイート',
        tweetAtStartLive: '配信開始ツイート',
        tweetSetting: 'ツイート設定',
        tweetSettingsAtStartLive: '配信開始時のツイート設定',
        tweetVideoWithHashtag: '#シンカラ で動画を投稿',
        twitter: 'Twitter',
        twitterHashTag: 'Twitterの歌ってみた動画いいね数',
        twitterIntegration: 'Twitter連携',
        type: 'タイプ',
        typeAll: 'すべて',
        typeCardinalDirection: '東西日本代表',
        typeEnjoy: 'エンジョイ',
        typeEnjoyOver30Day: 'まったり',
        typeHatsuplus: '初＋',
        typeNew10Day: '新旬',
        typeNew1Day: '初配信',
        typeNew30Day: '初月',
        typeOnePhraseEvent: '歌うま',
        typePrefecture: '県代表',
        typePrefectureOrOver: '県代表~',
        typeRegion: '地方代表',
        typeRegionOrOver: '地方代表~',
        typeRegionOrOverVoiceOnly: '声だけ代表',
        typeRegionOrOverWithFace: '顔出し代表',
        typeSecondReview: '二次審査',
        typeTown: '地区代表',
        typeTownAndPrefecture: '地区･県代表',
        unachievedRequirement1toGetAccumulatedDiamond: function (number) { return "\u6761\u4EF61: \u5F53\u8A72\u65EC\u542B\u3080\u76F4\u8FD16\u65EC\u3067100\u66F2\u5206\u4EE5\u4E0A\u30C0\u30A4\u30E4\u7372\u5F97\u6A29\u3092\u6D88\u8CBB\u3057\u3066\u3044\u308B\u3053\u3068 (\u672A\u9054 / \u3042\u3068".concat(number, "\u66F2\u5206)"); },
        unachievedRequirement2toGetAccumulatedDiamond: function (number) { return "\u6761\u4EF62: \u4ECA\u65EC25\u66F2\u5206\u30C0\u30A4\u30E4\u7372\u5F97\u6A29\u3092\u6D88\u8CBB\u3057\u3066\u3044\u308B\u3053\u3068 (\u672A\u9054 / \u3042\u3068".concat(number, "\u66F2\u5206)"); },
        unachievedRequirement3toGetAccumulatedDiamond: function (number) { return "\u6761\u4EF63: \u8A72\u5F53\u65EC\u306E\u9593\u306B1K\u63A8\u3057\u30D0\u30C3\u30B8\u30923\u4EBA\u4EE5\u4E0A\u304C\u7372\u5F97\u3057\u3066\u3044\u308B\u3053\u3068 (\u672A\u9054 / \u3042\u3068".concat(number, "\u4EBA)"); },
        unavailableLiveTimeDescription: 'ただいま配信可能な時間は18:00~24:00です。',
        unblock: 'ブロック解除',
        underMaintenance: 'メンテナンス中です',
        unexchangeableToCoinDiamond: 'コイン交換できないダイヤ',
        unfollow: 'フォロー解除',
        unitPrice: '単価',
        unknownErrorOccurred: '不明なエラーが発生しました。もう一度お試しいただくか、お問い合わせください。',
        unprohibitComments: '解除',
        unselected: '未選択',
        unset: '未設定',
        unsetOrSkip: 'スキップ (設定しない)',
        unsetTweetWhenLiveStarted: '設定しない',
        unstableNetworkErrorYoutubeViewing: 'ネットワークが不安定なため、Youtube同時視聴はできません。配信の音声のみ再生しています。',
        updatedAtProfileWithTime: function (updatedAt) { return "".concat(updatedAt, "\u306B\u30D7\u30ED\u30D5\u66F4\u65B0"); },
        updateMembershipQuantityPaymentButtonText: function (listenerName, liverName) { return "\u26A0\u300E".concat(listenerName, "\u300F\u304C\u300E").concat(liverName, "\u300F\u306E\u30E1\u30F3\u30D0\u30FC\u30B7\u30C3\u30D7\u30D1\u30B9\u306E\u679A\u6570\u3092\u5909\u66F4\u3057\u307E\u3059\u3002\u4ED6\u306E\u65B9\u3078\u3053\u306EURL\u306E\u5171\u6709\u306F\u304A\u63A7\u3048\u304F\u3060\u3055\u3044\u3002\u89E3\u7D04\u30FB\u30AD\u30E3\u30F3\u30BB\u30EB\u306B\u95A2\u3057\u3066\u3001\u6B21\u56DE\u66F4\u65B0\u306F\u505C\u6B62(\u30AD\u30E3\u30F3\u30BB\u30EB)\u304C\u3067\u304D\u307E\u3059\u3002Web\u30ED\u30B0\u30A4\u30F3 \u2192 \u30E1\u30F3\u30D0\u30FC\u30B7\u30C3\u30D7\u30D1\u30B9\u306E\u7BA1\u7406 \u2192 \u30D7\u30E9\u30F3\u5909\u66F4\u304B\u3089\u3001\u6B21\u56DE\u66F4\u65B0\u306E\u505C\u6B62 (\u89E3\u7D04)\u3092\u5B9F\u884C\u3057\u3001\u6B21\u56DE\u66F4\u65B0\u6642\u671F\u3092\u3080\u304B\u3048\u305F\u3068\u304D\u306B\u3001\u6B21\u56DE\u66F4\u65B0\u306E\u505C\u6B62\u304C\u5B8C\u4E86\u3068\u306A\u308A\u307E\u3059\u3002\u307E\u305F\u3001\u6B21\u56DE\u66F4\u65B0\u65E5\u306E\u30BF\u30A4\u30DF\u30F3\u30B0\u3067\u3001\u9000\u4F1A\u7533\u8ACB\u4E2D\u307E\u305F\u306F\u9000\u4F1A\u6E08\u306E\u5834\u5408\u306F\u81EA\u52D5\u66F4\u65B0\u306F\u505C\u6B62\u3055\u308C\u307E\u3059\u3002\u306A\u304A\u3001\u6709\u52B9\u671F\u9593\u4E2D\u306B\u5F53\u8A72\u671F\u9593\u306E\u30E1\u30F3\u30D0\u30FC\u30B7\u30C3\u30D7\u3092\u9014\u4E2D\u3067\u30AD\u30E3\u30F3\u30BB\u30EB\u3059\u308B\u3053\u3068\u306F\u3067\u304D\u307E\u305B\u3093\u3002\u89E3\u7D04\u306B\u95A2\u3059\u308B\u304A\u554F\u3044\u5408\u308F\u305B\u306F [FAQ] \u304A\u554F\u3044\u5408\u308F\u305B -> \u304A\u554F\u3044\u5408\u308F\u305B\u30E1\u30FC\u30EB\u304B\u3089\u304A\u9858\u3044\u3044\u305F\u3057\u307E\u3059\u3002"); },
        updatePearlPrizeItemDream: '夢 (パールで交換したいプライズ) の設定',
        url: 'URL',
        user: 'ユーザー',
        userAlreadyWithdrawn: 'すでに退会済みのユーザーです',
        userDisplayNameForMe: 'あなた',
        userFavoriteSongCategoryCanSing: '歌える曲',
        userFavoriteSongCategoryCanSingSeventyPercent: '7割歌える曲',
        userFavoriteSongCategoryConfident: '自信曲',
        userFavoriteSongCategoryCurrentlyFavorite: '今ハマっている曲',
        userFavoriteSongCategoryVeryConfident: '超自信曲',
        userFlagAntisocialForces: '反社確定',
        userFlagCautionNeeded: '要注意ユーザ',
        userFlagLiveJudgeLv4: '審査優先度LV4固定',
        userFlagLiveJudgeLv5: '審査優先度LV5固定',
        userFlagOrganizerOpen: '[文言]事務所開設許可',
        userFlagPassedLSinger: '配信認可Lシンガー',
        userFlagPretendProduction: '本番さながら',
        userFlagSubAccountHolder: 'サブ垢許容',
        userFlagUnder17: '17際未満扱い',
        userHasLeft: 'このユーザーは退会済みです',
        userList: 'ユーザーの一覧',
        userName: 'ユーザー名',
        usersYouVotedThisTime: '今回あなたが投票したユーザー',
        validityPeriod: '有効期間',
        variousHistory: '各種履歴',
        video: '映像',
        videoLiveOnlyThisTime: '今回だけ顔出し配信にする',
        viewFilterCustom: 'カスタム',
        viewFilterMeAsListener: '自分・リスナー',
        viewFilterMeAsLiver: '自分',
        viewingTime: '視聴時間',
        viewOnInstagram: 'Instagramを見る',
        viewOnTikTok: 'TikTokを見る',
        viewOnTwitter: 'Twitterを見る',
        viewOnYouTube: 'YouTubeを見る',
        viewStanceActive: '話しかけ歓迎です',
        viewStanceDescriptionActive: 'すぐに会話に混ざりたい',
        viewStanceDescriptionDontTouch: 'そっとしてほしい',
        viewStanceDescriptionHalfActive: '自分から話すまで待ってほしい',
        viewStanceDescriptionOnlyView: 'あまり反応はできないけれど、聞いて楽しみたい人',
        viewStanceDontTouch: '空気と思ってほしい',
        viewStanceHalfActive: '自分のペースで楽しみたい',
        viewStanceOnlyView: '潜り気味になります',
        viewStancePFCommentActive: '[🤗話しかけ歓迎]スタンスなので、声掛けしてあげてね',
        viewStancePFCommentDontTouch: '[🫥空気と思って]スタンスなので、そっとしてあげてね',
        viewStancePFCommentHalfActive: '[🐢自分のペースで]スタンスなので、寄り添ってあげてね',
        viewStancePFCommentOnlyView: '[🐳 潜り気味]スタンスなので、いないときは察してあげてね',
        viewStanceShortActive: '話しかけ歓迎',
        viewStanceShortDontTouch: '空気と思って',
        viewStanceShortHalfActive: '自分のペースで',
        viewStanceShortOnlyView: '潜り気味',
        violationAdult: 'アダルト',
        violationAgeFalsification: '年齢詐称疑惑',
        violationBGM: 'BGM警告',
        violationCategory: '違反カテゴリ',
        violationCommercialPurpose: '営利目的',
        violationCopyRight: '著作権',
        violationDangerousAction: '危険行為',
        violationDating: '出会い',
        violationDefamation: '誹謗中傷',
        violationDriving: '車内配信',
        violationHandlingMemo: '違反対応メモ',
        violationIllegalKaraoke: 'カラオケをBGM利用',
        violationLiveOther: '配信・その他',
        violationLiveStreamingGuideline: '配信ガイドライン違反',
        violationMenu: '警告メニュー',
        violationMoral: '公序良俗',
        violationSameTime: '同一内容・同時配信',
        violationSilence: '無言放置',
        violationSleep: '寝落ち',
        violationTarget: '違反対象',
        violationTermsOfUse: '会員規約違反',
        violationUnder20: '20歳未満の飲酒喫煙',
        visibilityOfGiftEffect: 'ギフトのエフェクト',
        visibilityOfGiftSenderName: 'ギフトの使用者名',
        visibilityOfLiverNameAndAvatar: 'Lシンガー名とサムネイル',
        voiceOnlyLiver: '声だけLシンガー',
        voiceOnlyLiverSetting: '声だけLシンガー設定',
        volumeYoutubeViewing: '映像の音量',
        vote: '投票する',
        voteAgain: 'さらに投票',
        voteCompletedDescription: '1日3回まで投票可能です。投票回数が多いほど、100コイン, 500コイン, 1万コインが当たる確率が上がります',
        voting: '投票',
        votingCompleted: '投票完了',
        vtuberFaqButtonText: 'OBSの事前設定のFAQ',
        vtuberLivePreparePageTitle: 'OBS/VTuber配信 (BETA)',
        vtuberLivePrepareStartDescription: '設定が完了したら、OBS上でそのまま配信を開始してください。配信が正しく開始されると、画面下部のスタートボタン押せるようになります。',
        vtuberLivePrepareTitle: 'ColorSingのOBS用配信設定',
        vtuberModeMenuText: 'OBS/VTuber配信モードを試す',
        vtuberPrepareDescription: 'PCのOBSソフトの設定をColorSingに合わせる事前設定が必要です。下のFAQを元に設定し、サーバーURLとストリームキーを設定してください。',
        vtuberStreamingAboutButtonText: 'その他・OBSについて',
        vtuberStreamingKaraokeDescription: 'カラオケのBGMはスマホから流れます',
        vtuberStreamingLostConnection: 'OBSとの接続が失われました。サーバーURLとストリームキーを元にOBSソフトで再接続してください',
        vtuberStreamingMicDescription: 'PCのマイクから音声を拾います',
        vtuberStreamingMirroringDescription: 'OBSで映している画面を配信画面にミラーリングする機能はありません (視聴画面のみに映ります)',
        vtuberStreamKeyDescription: '※ サーバーURLは毎回同じ値ですが、ストリームキーは毎回異なる値なので毎回設定が必要です',
        walletLink: '[文言]口座一覽へ',
        warningOnChangeEarphone: '歌唱中のイヤホン・マイク等の接続状態の変化 (抜き差し等) には対応していないため、歌唱を終了しました。ご了承ください。',
        warningYoutubePlayOnNotEarphone: 'イヤホンやマイクに接続していない状態ではYoutube同時視聴機能はご利用になれません。',
        webCoinRateInfomation: function (webCoinRate) { return "\u6700\u5F8C\u306EWeb\u8AB2\u91D1\u304B\u308960\u65E5\u306EWeb\u8CFC\u5165\u6BD4\u7387: ".concat(webCoinRate, "%"); },
        willUsedPaymentTsunagu: function (price) { return "\u00A5".concat(price, "\u5206\u304C\u652F\u6255\u3044\u306B\u4F7F\u7528\u3055\u308C\u307E\u3059"); },
        withdrawApplicationAmount: '換金申請額',
        withdrawHistoryDetailPageTitle: 'ColorSingダイヤ換金として(支払い明細書)',
        withdrawPlan: '加入しているプランをやめる',
        withinRange: function (range) { return "".concat(range, "\u570F\u5185"); },
        world: '世界',
        worldAll: 'すべて',
        worldOnlyVoice: '声だけ',
        yes: 'はい',
        yesterday: '前日',
        youAddedToSingItLaterTimeBefore: function (time) { return "\u3042\u306A\u305F\u304C".concat(time, "\u524D\u306B\u3042\u3068\u3067\u6B4C\u3046\u306B\u8FFD\u52A0"); },
        youAreBlocked: 'このユーザーからブロックされています',
        youAreBlocking: 'このユーザーをブロックしています',
        youAreInPenalty: 'ペナルティ発動 (機能制限) 中',
        youAreInThisPlan: 'このプランに加入しています',
        youtube: 'YouTube',
    },
};
/* eslint-enable */
